import React, { useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  getCookieApi,
  getEpgDate,
  getFrameConfigs,
  getUserOnlineQueryApi,
  getWebSocketInfo,
  setCookieApiCall
} from "../../api/config/configApi";
import {
  getProfileInitialData,
  getProfilesApi,
  getUserInitialInfo,
  setPlayInitialInfo,
  setUserLanguage,
  setUserMediaServices
} from "../../api/user/user";
import { SessionContext } from "../../context/SessionContext";

import moment from "moment";
import localizationStrings from "../../statics/localization";
import { v4 as uuidv4 } from "uuid";
import useQuery from "../../utils/useQuery";
import { getPackageList } from "../../api/settings/Settings";
import { getCookieData, setCookieData, setWs } from "../../statics/config";
import { initPakoScript } from "../../utils/initCollectScript";
import { sendHeartbeatApiCall } from "../../api/common/commonApi";
import mainLoadingGif from "../../assets/loading.gif";

const LandingPage = () => {
  const {
    setUserFrameConfig,
    setProfile,
    setUserInfo,
    setProfileInfo,
    setAdminProfile,
    setIsChildProfile,
    setUserPackage,
    setMesMessage,
    setIsCollectScriptInitialized,
    setIsLogout,
    userFrameConfig,
    setAllProfiles,
    setMesFingerPrintParam
  } = useContext(SessionContext);
  const userInfo = useRef(null);
  const mesReportUrl = useRef(null);
  const adminProfileId = useRef(null);
  const childProfileId = useRef(null);
  const dataCollectionUrl = useRef(null);
  const frameConfig = useRef(null);
  const profile = useRef(null);
  let ws;
  let defaultLanguage = "tur";
  const history = useHistory();
  let directionPath = useQuery().get("redirectUrl");

  const getUserFrameConfig = async () => {
    const data = await getFrameConfigs(
      localStorage.getItem("interfaceurl"),
      localStorage.getItem("sessionid")
    );
    mesReportUrl.current = data.data?.mesReportAddress;
    adminProfileId.current = data.data?.Admin_Profile_Id;
    childProfileId.current = data.data?.Kids_Profile_Id;
    dataCollectionUrl.current = data.data?.Collect_Agent_Url;
    frameConfig.current = data.data;

    localStorage.setItem(
      "forMeColumn",
      frameConfig.current.Special_For_Me_Column
    );
    localStorage.setItem("vodColumn", frameConfig.current.Video_Column);
    localStorage.setItem("seriesColumn", frameConfig.current.Series_Column);
    localStorage.setItem(
      "rentColumn",
      frameConfig.current.Rent_Buy_Purchase_Column
    );
    localStorage.setItem("kidColumn", frameConfig.current.Child_Column);

    localStorage.setItem(
      "loginurl",
      `${window.location.origin}/iptvepg/${frameConfig.current?.Login_URL}`
    );
    setUserFrameConfig(data.data);
    setMediaService();
    if (userInfo.current.usertype === "1") {
      getWebSocketPort();
      getProfiles();
    }
    // getUserOnlineQuery();
  };

  const setPlayInit = async () => {
    const response = await setPlayInitialInfo();
    getCookieFromApi();
  };

  const getCookieFromApi = async () => {
    const { data } = await getCookieApi();
    setCookieData(data);
    let deviceId;
    if (process.env.NODE_ENV === "production") {
      // localStorage.setItem("videoProfile", data?.videoProfile);
      let vol;
      if (
        data?.volume &&
        data?.volume != undefined &&
        data?.volume != "undefined"
      ) {
        localStorage.setItem("volume", data?.volume);
      } else {
        localStorage.setItem("volume", "100");
      }
      deviceId = data?.deviceid;
      localStorage.setItem("deviceid", deviceId);
    } else {
      deviceId = localStorage.getItem("deviceid");
    }
    if (!deviceId) {
      let newDeviceId = uuidv4();
      // setCookie("deviceid",newDeviceId , { domain: config.cookieDomain });
      localStorage.setItem("deviceid", newDeviceId);

      setCookieApiCall("deviceid", newDeviceId);
    }
    // setCookieData({
    //   JSESSIONID: "29E74E9314D7E5EA9226A664D7753725",
    //   utp: "3",
    //   "1915-searchHistory": ["trailer", "test", "annem"],
    //   __gads:
    //     "ID=7c8e7571104c233e:T=1632395669:S=ALNI_MakAgLXJIDFejf_ofEN_m7LlT2eDQ",
    //   uid: "MWFGCVPNnGlh/9lgvjtOpg",
    //   upd: "/xBbCK+davkqzgXxwDzUBA",
    //   profile: {
    //     profilecode: "6958468",
    //     profileroleid: "32",
    //     photo: "1",
    //     nickname: "Normal",
    //     terminalflags: "-1",
    //     havepwd: "0",
    //     posterpath: "https://itv224130.tmp.tivibu.com.tr:6430/images/poster/",
    //     posterimage: "./profilePosters/1.png"
    //   },
    //   audioLang: "ori",
    //   subtitleLang: "ori",
    //   deviceid: "217730d0-d59a-4a75-8681-89cefe673b19",
    //   language: "eng"
    // });
    getUserInfo();
  };

  const getUserInfo = async () => {
    const data = await getUserInitialInfo();
    if (data.data.returncode === "0") {
      userInfo.current = data.data;
      localStorage.setItem("sessionid", userInfo.current.sessionid);
      localStorage.setItem("usertoken", userInfo.current.usertoken);
      localStorage.setItem("usertype", userInfo.current.usertype);
      setUserInfo(userInfo.current);
      getServerDate();
      if (userInfo.current.usertype === "1") {
        // getLanguagePreferences();
        let browserAgent = window.BrowserAgentExtension;
        if (browserAgent) {
          browserAgent.setCustomerId({
            customerId: window.EncryptUtils.dencryptStr(
              decodeURIComponent(userInfo.current.usercode)
            )
          });
        }
        if (process.env.NODE_ENV === "production") {
          setUserLang(getCookieData()?.language);
          localStorage.setItem(
            "subtitleLang",
            getCookieData()?.subtitleLang
              ? getCookieData()?.subtitleLang
              : "off"
          );
          localStorage.setItem(
            "audioLang",
            getCookieData()?.audioLang
              ? getCookieData()?.audioLang
              : defaultLanguage
          );
        } else {
          setUserLang(localStorage.getItem("language"));
        }
        getUserPackage();
      } else {
        localStorage.setItem("subtitleLang", "off");
        localStorage.setItem("audioLang", defaultLanguage);
        setUserLang(defaultLanguage);
        getUserPackage();
        // window.location.replace(
        //   `${localStorage.getItem("loginurl")}?lan=${localStorage.getItem(
        //     "language"
        //   )}&deviceid=${localStorage.getItem("deviceid")}`
        // );
      }
      getProfileInitInfo();
      sendHearbeat();
    }
  };

  const sendHearbeat = async () => {
    const { data } = await sendHeartbeatApiCall();
  };

  const setMediaService = async () => {
    const response = await setUserMediaServices(
      localStorage.getItem("interfaceurl"),
      2,
      localStorage.getItem("sessionid")
    );
  };

  const setUserLang = async (lang) => {
    if (!lang || lang == "undefined") {
      lang = defaultLanguage;
    }
    const response = await setUserLanguage(
      localStorage.getItem("interfaceurl"),
      localStorage.getItem("sessionid"),
      lang
    );
    if (response.data.returncode == 0) {
      setCookieApiCall("language", lang);
      localStorage.setItem("language", lang);
      localizationStrings.setLanguage(lang);
    }
    if (userInfo.current.usertype === "0") {
      getUserFrameConfig();
    }
  };

  const getProfileInitInfo = async () => {
    const data = await getProfileInitialData();
    if (data.data.returncode === "0") {
      setProfileInfo(data.data);
    }
  };

  const getUserPackage = async () => {
    const { data } = await getPackageList();
    if (data.returncode === "0") {
      let userProduct = data.data.find((item) => item.producttype === "0");
      setUserPackage(userProduct);
    }
    getUserFrameConfig();
  };

  const getWebSocketPort = async () => {
    let userCode = window.EncryptUtils.dencryptStr(
      decodeURIComponent(userInfo.current?.usercode)
    );

    const data = await getWebSocketInfo(
      userCode,
      mesReportUrl.current,
      localStorage.getItem("deviceid")
    );
    let response = data.data;

    if (response?.length) {
      let res = JSON.parse(response?.slice(1, response?.length - 2));
      localStorage.setItem("wsData", JSON.stringify(res));
      if (res.Result === "0") {
        initWebsocket(res);
      }
    }
  };

  const initWebsocket = (wsData) => {
    let wsUrl = `wss://${wsData.RegSvrIP}:${wsData.WsSvrPort}/meswebsocket`;
    openWebSocket(wsUrl);
  };

  const closeWebsocket = () => {
    ws.close();
  };

  const openWebSocket = (url) => {
    if (!!url) {
      ws = new WebSocket(url);
      setWs(ws);
      ws.onerror = function () {
        closeWebsocket();
      };
      let userCode = window.EncryptUtils.dencryptStr(
        decodeURIComponent(userInfo.current?.usercode)
      );
      let deviceId = localStorage.getItem("deviceid");
      ws.onopen = function (e) {
        var prams = {
          TransactionID: JSON.stringify(new Date().getTime()),
          DeviceID: deviceId,
          UserID: userCode,
          TerminalFlag: 4,
          UserToken: userInfo.current?.usertoken,
          DKey: 0,
          Timestamp: JSON.stringify(new Date().getTime()),
          MsgType: "terminalregistreq"
        };

        ws.send(JSON.stringify(prams));
        if (process.env.NODE_ENV === "production") {
          getUserOnlineQuery();
        }
      };

      ws.onmessage = function (e) {
        var data = JSON.parse(JSON.stringify(JSON.parse(e.data)));

        switch (data.MsgType) {
          case "terminalregistrsp":
            setInterval(() => {
              var prams = {
                TransactionID: JSON.stringify(new Date().getTime()),
                DeviceID: deviceId,
                Timestamp: JSON.stringify(new Date().getTime()),
                MsgType: "terminalheartreq"
              };
              ws.send(JSON.stringify(prams));
            }, 60000);
            break;

          case "sendmessagereq":
            responseMsg(data);
            let prams = {
              TransactionID: data.TransactionID,
              Return: "0",
              MsgType: "sendmessagersp"
            };
            ws.send(JSON.stringify(prams));
            break;
        }
      };
    }
  };

  function responseMsg(data) {
    let prams = "";
    let mesNotification = {};
    if (data.MsgType == "sendmessagereq") {
      prams = {
        TransactionID: data.TransactionID,
        Return: "0",
        MsgType: "sendmessagersp"
      };
      ws.send(JSON.stringify(prams));

      if (data.MsgInfo) {
        if (data.MsgInfo.MsgServType == 2) {
          if (data.MsgInfo.MsgServAttr) {
            if (
              data.MsgInfo.MsgServAttr.ServiceType == 2 &&
              data.MsgInfo.MsgServAttr.OperType == 1
            ) {
              //User package downgrade or changed
              mesNotification.text = data.MsgInfo.MsgContent;
              mesNotification.isChangePackage = true;
            } else if (
              data.MsgInfo.MsgServAttr.ServiceType == 1 &&
              data.MsgInfo.MsgServAttr.OperType == 2
            ) {
              //User Package Suspended
              mesNotification.text = data.MsgInfo.MsgContent;
              mesNotification.isLogout = true;
            } else if (
              data.MsgInfo.MsgServAttr.ServiceType == 19 &&
              data.MsgInfo.MsgServAttr.OperType == 2 &&
              data.MsgInfo.MsgServAttr.ServParam ==
                localStorage.getItem("profileCode")
            ) {
              //profile related
              mesNotification.text = data.MsgInfo.MsgContent;
            } else if (
              data.MsgInfo.MsgServAttr.ServiceType == 26 &&
              data.MsgInfo.MsgServAttr.OperType == 2
            ) {
              //Fingerprint related
              setMesFingerPrintParam(null);
              console.log("mes data: ", data);
              let val = decodeURIComponent(data.MsgInfo.MsgContent);
              console.log("val: ", val);
              console.log("val.channelcode: ", val.channelcode);
              console.log("JSON.parse(val): ", JSON.parse(val));
              setMesFingerPrintParam(JSON.parse(val));
            }
            //  else if (
            //   data.MsgInfo.MsgServAttr.ServiceType == 20 &&
            //   data.MsgInfo.MsgServAttr.OperType == 3
            // ) {
            //   //Content related
            //   console.log("content changed: ", data);
            //   mesNotification.text = "content changed or content related mes message";

            //   alert("content changed or content related mes message");
            // }
          }
        } else if (
          data.MsgInfo.MsgServType == 1 &&
          data.MsgInfo.MsgShowType == 1
        ) {
          //User restores IPTV or Ott product messages
          mesNotification.text = data.MsgInfo.MsgContent;
          mesNotification.isDoNothing = true;
        }

        //Sing out user
        if (data.MsgInfo.msgbody && data.MsgInfo.msghead) {
          if (
            data.MsgInfo.msgbody.msgcode == 9000 &&
            data.MsgInfo.msghead.msgtype == 1
          ) {
            setIsLogout(true); //force logout user
          }
        }
        if (mesNotification?.text) {
          setMesMessage(mesNotification);
        }
      }
    }
  }

  const getUserOnlineQuery = async () => {
    const { data } = await getUserOnlineQueryApi(
      mesReportUrl.current,
      window.EncryptUtils.dencryptStr(
        decodeURIComponent(userInfo.current.usercode)
      )
    );
    console.log("user online query data: ", data);
    if (data) {
      let deviceinfo = data.deviceinfo;
      let deviceidArr = [];
      dataSort(deviceinfo);
      // console.log("deviceinfo: ", deviceinfo);
      for (let i = 0; i < deviceinfo.length; i++) {
        if (deviceinfo[i].terminaltype != "1") {
          deviceidArr.push(deviceinfo[i]);
        }
      }

      // if (userInfo.current.ottonlinedevice == 1 && deviceidArr.length == 2) {
      if (userInfo.current.ottonlinedevice) {
        if (deviceidArr.length > userInfo.current.ottonlinedevice) {
          let mesNotification = {};
          mesNotification.text = localizationStrings.mesMessage.userOnlineQuery;
          mesNotification.isUserOnlineQuery = true;
          mesNotification.deviceid = deviceidArr[0].deviceid;
          setMesMessage(mesNotification);
        }
      }
      //Deviceları listeleme ekran tasarımı geldikten sonra yapılacak
      // else if(deviceidArr.length > mesManager.ottOnlineNum){//展示设备列表
      //   deviceidArr.pop();
      //   jsDebug("mes", "deviceidArr------", JSON.stringify(deviceidArr), 2);
      //   jsSetControl("ismsg","1");
      //   window.remindWin.document.location = "deviceList.jsp";
      //   document.getElementById("remindWin").style.visibility = "visible";
      //   document.getElementById("remindWin").focus();
      // }
    }
  };

  function dataSort(arr) {
    arr.sort(function (a, b) {
      return a.logintime - b.logintime;
    });
  }

  // const initPakoScript = () => {
  //   const script = document.createElement("script");
  //   script.src = `${localStorage.getItem(
  //     "interfaceurl"
  //   )}/jssdk/pako_deflate.min.js`;
  //   script.setAttribute("type", "text/javascript");
  //   document.getElementsByTagName("head")[0].appendChild(script);
  //   script.onload = () => {
  //     initCollectAgentScript();
  //   };
  // };

  const getProfiles = async () => {
    const data = await getProfilesApi();
    if (data.data.returncode === "0") {
      let profileData = data.data.data;
      setAllProfiles(profileData);
      let _profile;
      if (process.env.NODE_ENV === "production") {
        _profile = getCookieData()?.profile;
      } else {
        _profile = localStorage.getItem("profile");
        if (!!_profile) {
          _profile = JSON.parse(_profile);
        }
      }
      let profileCode = null;
      if (!!_profile) {
        profileCode = _profile.profilecode;
      }
      if (process.env.NODE_ENV === "production") {
        if (getCookieData()[`${profileCode}-searchTermHistory`])
          localStorage.setItem(
            `${profileCode}-searchTermHistory`,
            getCookieData()[`${profileCode}-searchTermHistory`]
          );
      }

      profileData.forEach((el) => {
        if (el.profilecode === profileCode) {
          if (el.profileroleid === childProfileId.current) {
            setIsChildProfile(true);
          } else {
            setIsChildProfile(false);
          }
          setProfile(el);
          profile.current = el;
        }
        if (el.profileroleid == adminProfileId.current) {
          setAdminProfile(el);
        }
      });

      if (profile.current) {
        initPakoScript(
          frameConfig.current,
          userInfo.current,
          profile.current.profilecode,
          (val) => {
            if (val) {
              setIsCollectScriptInitialized(true);
              if (directionPath) {
                if (directionPath === "/landingpage") {
                  history.push("dashboard");
                } else {
                  history.push(`${directionPath}`);
                }
              } else {
                history.push("/selectprofile");
              }
            }
          }
        );
      } else {
        history.push("/selectprofile");
      }
    }
  };

  const getServerDate = async () => {
    const { data } = await getEpgDate();
    if (data.returncode === "0") {
      let now = moment().valueOf();
      let timeDiff = now - moment(data.nowdate, "YYYYMMDDHHmmss").valueOf();
      localStorage.setItem("timeDiff", timeDiff);
    }
  };

  const initSmpteScript = () => {
    const script = document.createElement("script");
    script.src = `${localStorage.getItem("interfaceurl")}/jssdk/smpte.min.js`;
    script.setAttribute("type", "text/javascript");
    document.getElementsByTagName("head")[0].appendChild(script);
    script.onload = () => {
      console.log("smpteScript loaded successfully");
    };
  };

  useEffect(() => {
    setPlayInit();
    initSmpteScript();
  }, []);

  useEffect(() => {
    if (userFrameConfig && userInfo.current) {
      if (userInfo.current.usertype === "0") {
        if (directionPath) {
          history.push(directionPath);
        } else {
          history.push("/dashboard");
        }
      }
    }
  }, [userFrameConfig, userInfo.current]);

  // useEffect(() => {
  //   console.log("kickout user device id: ", kickOutUser);
  //   if (kickOutUser) {
  //     mesSignOut(kickOutUser);
  //   }
  // }, [kickOutUser]);

  return (
    <div className="landing-container">
      <div className="main-loader-wrapper">
        <div className="main-loader">
          <img src={mainLoadingGif} />
        </div>
        <div className="loading-text">
          {localizationStrings.mainLoadingText}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
