import React from "react";

const RentHistory = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="37.707"
        viewBox="0 0 48 37.707"
      >
        <g
          id="Group_799"
          data-name="Group 799"
          transform="translate(-786 -406)"
        >
          <path
            id="Stroke_1"
            data-name="Stroke 1"
            d="M27,29H0V0H46V18"
            transform="translate(787 407)"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Stroke_3"
            data-name="Stroke 3"
            d="M0,2,2,0H21l2,2"
            transform="translate(799 441)"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Stroke_5"
            data-name="Stroke 5"
            d="M.5,0V3"
            transform="translate(806.5 438)"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Stroke_7"
            data-name="Stroke 7"
            d="M.5,0V3"
            transform="translate(813.5 438)"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Stroke_9"
            data-name="Stroke 9"
            d="M13.791,10.014,0,0V17c0,.514.451.693.862.293L4.727,13.56l3.725,6.563a1.177,1.177,0,0,0,1.646.249l1.556-.978a1.227,1.227,0,0,0,.522-1.59L8.6,11.593h4.681C13.874,11.593,14.241,10.313,13.791,10.014Z"
            transform="translate(817 418.406)"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Stroke_11"
            data-name="Stroke 11"
            d="M.5,3V0"
            transform="translate(816.5 411)"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Stroke_13"
            data-name="Stroke 13"
            d="M3,.5H0"
            transform="translate(810 417.5)"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Stroke_15"
            data-name="Stroke 15"
            d="M2,2,0,0"
            transform="translate(812.023 413)"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Stroke_17"
            data-name="Stroke 17"
            d="M0,2,2,0"
            transform="translate(820.023 413)"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </g>
      </svg>
    </div>
  );
};

export default RentHistory;
