import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { reportingEvent } from "../../statics/reportEventId";
import { SessionContext } from "../../context/SessionContext";
import { useHistory, useLocation } from "react-router-dom";
import Notification from "../notification";
import localizationStrings from "../../statics/localization";
import { LogLevel } from "dashjs";
import { getColectAgent } from "../../statics/config";

const ErrorFallBack = ({ error, resetErrorBoundary }) => {
  const [showError, setShowError] = useState(false);
  const [err, setErr] = useState(null);
  const { userInfo, userFrameConfig } = useContext(SessionContext);
  const history = useHistory();
  let location = useLocation();
  let reportObj = {};

  useEffect(() => {
    console.log("ERRORFALLBACK");
    if (error && userInfo?.usertype === "1") {
      let collectAgent = getColectAgent();

      let pathName = location.pathname;
      let err_ = {};
      let index = 0;
      if (pathName.includes("/voddetail")) {
        let vodId = pathName.slice(
          pathName.indexOf("voddetail") + 10,
          pathName.length
        );

        err_.code = "11006001";
      } else if (pathName.includes("profile")) {
        //profile related pages

        if (pathName.includes("/profile/")) {
          index = pathName.indexOf("profile") + 8;
          let profileId = pathName.slice(index, pathName.length);
          err_.code = "11010001";
        } else if (pathName.includes("/profiles")) {
          err_.code = "11010002";
        } else if (pathName.includes("/selectprofile")) {
          err_.code = "11010003";
        }
      } else if (
        pathName.includes("/livetv") &&
        !pathName.includes("/search")
      ) {
        //live tv related pages
        index = pathName.indexOf("livetv") + 7;
        let channelNo = pathName.slice(index, pathName.length);
        err_.code = "11001007";
      } else if (pathName.includes("package")) {
        err_.code = "11012001";
      } else if (pathName.includes("records")) {
        err_.code = "11020001";
      } else if (pathName.includes("parentalcontrol")) {
        err_.code = "11015001";
      } else if (pathName.includes("favlockchannel")) {
        err_.code = "11016001";
      } else if (pathName.includes("purchasehistory")) {
        err_.code = "11013001";
      } else if (pathName.includes("language")) {
        err_.code = "11014001";
      } else if (pathName.includes("version")) {
        err_.code = "11019001";
      } else if (pathName.includes("/search")) {
        err_.code = "11009001";
      } else if (pathName.includes("/dashboard/home")) {
        index = pathName.indexOf("home") + 5;
        let menuId = pathName.slice(index, index + 4);

        switch (menuId) {
          case userFrameConfig?.Special_For_Me_Column:
            err_.code = "11004001";
            break;
          case userFrameConfig?.Video_Column:
            err_.code = "11004002";
            break;
          case userFrameConfig?.Series_Column:
            err_.code = "11004003";
            break;
          case userFrameConfig?.Rent_Buy_Purchase_Column:
            err_.code = "11004004";
            break;
          case userFrameConfig?.Child_Column:
            err_.code = "11004005";
            break;
          default:
            err_.code = "11004006";
            break;
        }
      }

      err_.message = localizationStrings.invalidParamError;
      reportObj = {
        eventtype: reportingEvent.E_ERROR,
        eventid: reportingEvent.E_ERROR_CRASH,
        sessionid: localStorage.getItem("sessionid"),
        eventtime: moment(
          moment().valueOf() - parseInt(localStorage.getItem("timeDiff"))
        )
          .utc()
          .format("yyyyMMDDHHmmss"),
        errorcode: err_.code, //from error code lsit
        errordesc: err_.message
      };

      console.log("CRASH ERROR: ", err_);

      collectAgent.logError(reportObj);
      setErr(err_);
      setShowError(true);
    }
  }, [error]);
  return (
    <div>
      {showError ? (
        <Notification
          text={`Code: ${err.code} Message: ${err.message}`}
          confirmText={localizationStrings.login.confirm}
          onClickConfirm={() => {
            history.push("/dashboard");
            setShowError(false);
            resetErrorBoundary();
          }}
        />
      ) : null}
    </div>
  );
};

export default ErrorFallBack;
