import React, { useEffect, useRef, useState } from "react";
import PauseIcon from "../../../assets/icons/svg/PauseIcon";
import PlayerPlayIcon from "../../../assets/icons/svg/PlayerPlayIcon";
import { setPltvPaused } from "../../../statics/config";
import localizationStrings from "../../../statics/localization";
import { reportingEvent } from "../../../statics/reportEventId";

const PlayButton = ({
  player,
  setIsPaused,
  sendMediaReport,
  isPltv,
  setIsLiveEdge,
  cuTvProgram,
  myTvChannelProgram,
  liveui,
  isPaused,
  isTimeshiftEnable
}) => {
  const [paused, setPaused] = useState(null);
  const isPltvRef = useRef(null);
  const cuTvRef = useRef(null);
  const myTvRef = useRef(null);

  useEffect(() => {
    isPltvRef.current = isPltv;
  }, [isPltv]);

  useEffect(() => {
    cuTvRef.current = cuTvProgram;
  }, [cuTvProgram]);

  useEffect(() => {
    myTvRef.current = myTvChannelProgram;
  }, [myTvChannelProgram]);

  useEffect(() => {
    setPaused(isPaused);
  }, [isPaused]);

  return (
    <button
      className="control-button"
      style={{
        marginLeft: "0",
        cursor: isTimeshiftEnable ? "pointer" : "not-allowed"
      }}
      title={
        paused
          ? localizationStrings.playerOptions.play
          : localizationStrings.playerOptions.pause
      }
      onClick={() => {
        if (isTimeshiftEnable) {
          if (player.paused()) {
            sendMediaReport(reportingEvent.E_MEDIA_PLAY);
            setIsPaused(false);
            setPaused(false);
            player.play();
          } else {
            sendMediaReport(reportingEvent.E_MEDIA_PAUSE);
            setIsPaused(true);
            setPaused(true);
            player.pause();
            if (
              liveui &&
              !isPltvRef.current &&
              !cuTvRef.current?.prevuecode &&
              !myTvRef.current?.programcode
            ) {
              setIsLiveEdge("1");
              setPltvPaused(true);
            }
          }
        }
      }}
    >
      <div style={{ display: paused ? "flex" : "none" }}>
        <PlayerPlayIcon />
      </div>
      <div style={{ display: paused ? "none" : "flex" }}>
        <PauseIcon />
      </div>
    </button>
  );
};

export default PlayButton;
