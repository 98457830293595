import React, { useContext, useEffect, useState } from "react";
import TvLockIcon from "../../assets/icons/svg/TvLockIcon";
import { defaultProfileAvatar } from "../../statics/config";
import localizationStrings from "../../statics/localization";
import addProfileIcon from "../../assets/images/add-profile-button.png";
import { SessionContext } from "../../context/SessionContext";

const ProfileItem = ({
  item,
  onSelection,
  currentProfile,
  isFromProfileLogin,
  count,
  onEditClick
}) => {
  const [avatar, setAvatar] = useState("");
  const [showSettingsIcon, setShowSettingsIcon] = useState(false);

  useEffect(() => {
    if (item.kid) {
      item.photo = "kids" + item.photo;
    }
    item.posterimage = `${process.env.PUBLIC_URL}/profilePosters/${item?.posterName}.png`;
    setAvatar(item.posterimage);
    if (item.id == -1) {
      setShowSettingsIcon(false);
    } else {
      if (currentProfile?.main || item.id === currentProfile?.id) {
        setShowSettingsIcon(true);
      }
    }
  }, [item]);

  return (
    <div className="profile-item" style={{ width: `${100 / count}vw` }}>
      <div className="profile-avatar" onClick={() => onSelection(item)}>
        {item.id == -1 ? (
          <img src={addProfileIcon} />
        ) : (
          <img src={avatar} onError={() => setAvatar(defaultProfileAvatar)} />
        )}
        {item?.hasPin ? (
          <div className="settings-icon-wrapper">
            <div className="settings-icon">
              <TvLockIcon />
            </div>
          </div>
        ) : null}
      </div>
      <div
        className="profile-name"
        style={{ width: `${100 / count}vw` }}
        title={decodeURIComponent(item.name)}
      >
        {decodeURIComponent(item.name)}
      </div>
      {!isFromProfileLogin && showSettingsIcon ? (
        <div className="edit-text" onClick={() => onEditClick(item)}>
          {localizationStrings.editProfile}
        </div>
      ) : null}
    </div>
  );
};

export default ProfileItem;
