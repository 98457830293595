import axios from "axios";
import { axiosInstance } from "../config/configApi";
import { apiEndPoint, fastLoginSessionKillUrl } from "../../statics/config";

const getChannels = async () => {
  return await axios.get("/channel");
};

const getCurrentProgramById = async (channelId) => {
  return await axios.get("/channel/" + channelId + "/program/current");
};

const getProgramsById = async (channelId, selectedDate) => {
  return await axiosInstance.get("/channel/" + channelId + "/program", {
    params: { date: selectedDate }
  });
};

const toggleFavById = async (channelId) => {
  return await axios.post("/interact/fav/" + channelId);
};

const toggleLockById = async (channelId) => {
  return await axios.post("/interact/lock/" + channelId);
};

const getChannelPlayUrl = async (channelId, startTime) => {
  let url = "";
  if (startTime) {
    url = "/channel/" + channelId + "/url?startTime=" + startTime;
  } else {
    url = "/channel/" + channelId + "/url";
  }
  return await axios.get(url, {
    headers: {
      "Content-Type": "text/plain"
    }
  });
};

const getProgramPlayUrl = async (epgId) => {
  return await axios.get("/channel/program/" + epgId + "/watch");
};

const getLtvChannels = async (columnCode, page, count) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");

  return await axios.get(`${apiEndpoint}/sdk_getchannellist.jsp`, {
    params: {
      columncode: columnCode,
      pageno: page,
      numperpage: count,
      ordertype: 1, // order by channel no
      isqueryfavorite: 1,
      isqueryprevue: 1,
      ischecklock: 1
    }
  });
};

const getChannel = async (channelCode, productcode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");

  return await axios.get(`${apiEndpoint}/sdk_getchanneldetail.jsp`, {
    params: {
      channelcode: channelCode,
      ischecksubscribe: 1,
      productcode: productcode
    }
  });
};

const getLockedChannels = async (columnCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getchannellocklist.jsp`, {
    params: {
      columncode: columnCode,
      pageno: 1,
      numperpage: 500, // to fix
      ordertype: 1
    }
  });
};

const getFavoriteChannels = async (columnCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.post(
    `${apiEndpoint}/sdk_getfullfavoritelist.jsp`,
    `favoritetype=5&ordertype=2&isqueryprevue=1&pagesize=500&tvcolumncode=${columnCode}&isquerylock=1`
  );
};

const getUserMyTvChannel = async (beginTime, endTime, language) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getmytvchannel.jsp`, {
    params: {
      langtype: language,
      starttime: beginTime,
      endtime: endTime,
      pageno: 1,
      numberpage: 500
    }
  });
};

const getPrevueInfo = async (prevueCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getprevueinfo.jsp`, {
    params: {
      prevuecodes: prevueCode
    }
  });
};

const getLivePlayUrl = async (
  channelCode,
  columnCode,
  playtype,
  productCode
) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");

  return await axios.get(`${apiEndpoint}/sdk_getppvtvplayurl.jsp`, {
    params: {
      channelcode: channelCode,
      columncode: columnCode,
      urlredirect: 1,
      playtype: playtype,
      productcode: productCode
    }
  });
};

const checkPpvAuth = async (prevueCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getippvauth.jsp`, {
    params: {
      prevuecode: prevueCode
    }
  });
};

const getProgramListByChannel = async (channelId, beginTime, endTime) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getprevuellist.jsp`, {
    params: {
      channelcode: channelId,
      begintime: beginTime,
      endtime: endTime
    }
  });
};

const getCurrentPrevue = async (channelCode) => {
  let apiEndpoint = localStorage.getItem("interfaceurl");

  return await axios.get(`${apiEndpoint}/sdk_getcurrentprevue.jsp`, {
    params: {
      channelcodes: channelCode
    }
  });
};

const getAllChannelsAndPrograms = async (beginTime, endTime) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getinfobarprevue.jsp`, {
    params: {
      // channelcode: channelId,
      // begintime: beginTime,
      // endtime: endTime,
    }
  });
};

const getMultiProgramData = async (channelCodes, beginTime, endTime) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axiosInstance.get(`${apiEndPoint}/sdk_getmultiprevuedata.jsp`, {
    params: {
      begintime: beginTime,
      endtime: endTime,
      channelcodelist: channelCodes
    }
  });
};

const getTvSearchResult = async (term, userLevel, columnCodes) => {
  let apiEndpoint = localStorage.getItem("interfaceurl");
  return await axios.post(
    `${apiEndpoint}/getsearchdata.jsp`,
    `conditiontype=16|17|18|19|20|21|22&condition=${encodeURIComponent(
      encodeURIComponent(term)
    )}&contenttype=2&languagetype=tur|eng&ratingid=${userLevel}&pageno=1&numperpage=100&mediaservices=-1|2&columncode=${columnCodes}`
  );
};

const addLastWatchedChannel = async (dataKey, dataValue) => {
  let apiEndpoint = localStorage.getItem("interfaceurl");
  return await axios.post(
    `${apiEndpoint}/sdk_addrecentchannel.jsp`,
    `datakey=${dataKey}&datavalue=${encodeURIComponent(
      encodeURIComponent(dataValue)
    )}`
  );
};

const getUserLastWatchedChannels = async () => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  return await axios.get(`${apiEndpoint}/sdk_queryrecentchannel.jsp`);
};

const getLiveHotChannels = async (beginTime, endTime) => {
  // this is a "post" request in the document
  var apiEndpoint = localStorage.getItem("interfaceurl");
  return await axios.get(`${apiEndpoint}/sdk_gethotchannelprevue.jsp`, {
    params: {
      starttime: beginTime,
      endtime: endTime
    }
  });
};

export {
  getChannels,
  getCurrentProgramById,
  getProgramsById,
  toggleFavById,
  toggleLockById,
  getChannelPlayUrl,
  getProgramPlayUrl,
  getLtvChannels,
  getChannel,
  getProgramListByChannel,
  getLockedChannels,
  getLivePlayUrl,
  getFavoriteChannels,
  getAllChannelsAndPrograms,
  getMultiProgramData,
  getPrevueInfo,
  getUserMyTvChannel,
  checkPpvAuth,
  getTvSearchResult,
  getCurrentPrevue,
  addLastWatchedChannel,
  getUserLastWatchedChannels,
  getLiveHotChannels
};
