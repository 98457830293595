import React, { createContext, useState } from "react";

export const SessionContext = createContext();

const SessionProvider = ({ children, values }) => {
  const [path, setPath] = useState(null);
  const [profile, setProfile] = useState(null);
  const [userFrameConfig, setUserFrameConfig] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [myProgram, setMyProgram] = useState({});
  const [userInfo, setUserInfo] = useState(null);
  const [profileInfo, setProfileInfo] = useState({}); //to get teamid..
  const [ppvProduct, setPpvProduct] = useState({});
  const [ppvProgram, setPpvProgram] = useState({});
  const [isRestartProgram, setIsRestartProgram] = useState(null);
  const [selectedMenuId, setSelectedMenuId] = useState(null);
  const [isChangeFavList, setIsChangeFavList] = useState(false);
  const [recordStatus, setRecordStatus] = useState(null);
  const [isClickedRecord, setIsClickedRecord] = useState(null);
  const [selectedCutvProgram, setSelectedCutvProgram] = useState(null);
  const [isLiveSelected, setIsLiveSelected] = useState(null);
  const [adminProfile, setAdminProfile] = useState(null);
  const [isChangeLockChannel, setIsChangeLockChannel] = useState(null);
  const [isChildProfile, setIsChildProfile] = useState(null);
  const [currentProgramTimes, setCurrentProgramTimes] = useState(null);
  const [prevMytvChannelPrograms, setPrevMytvChannelPrograms] = useState([]);
  const [nextMytvChannelPrograms, setNextMytvChannelPrograms] = useState([]);
  const [mytvChannelPosterPath, setMytvChannelPosterPath] = useState("");
  const [mesMessage, setMesMessage] = useState(null);
  const [selectedMytvChannelProgram, setSelectedMytvChannelProgram] =
    useState(null);
  const [userPackage, setUserPackage] = useState(null);
  const [isCollectScriptInitialized, setIsCollectScriptInitialized] =
    useState(null);
  const [isLogout, setIsLogout] = useState(null);
  const [channelItems, setChannelItems] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [allProfiles, setAllProfiles] = useState([]);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [programInfo, setProgramInfo] = useState(null);
  const [mesFingerPrintParam, setMesFingerPrintParam] = useState(null); //store finger print param coming from MES
  const [pictureInPicture, setPictureInPicture] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  const [playUrl, setPlayUrl] = useState(null);

  const handleLogout = () => {
    setHasLoggedIn(false);
    setProfile(null);
    localStorage.removeItem("userToken");
    localStorage.removeItem("profileToken");
    localStorage.setItem("hasLoggedIn", false);
    localStorage.removeItem("profile");
  };

  const appData = {
    path,
    setPath,
    profile,
    setProfile,
    userFrameConfig,
    setUserFrameConfig,
    selectedChannel,
    setSelectedChannel,
    userInfo,
    setUserInfo,
    profileInfo,
    setProfileInfo,
    myProgram,
    setMyProgram,
    ppvProduct,
    setPpvProduct,
    isRestartProgram,
    setIsRestartProgram,
    ppvProgram,
    setPpvProgram,
    selectedMenuId,
    setSelectedMenuId,
    isChangeFavList,
    setIsChangeFavList,
    recordStatus,
    setRecordStatus,
    isClickedRecord,
    setIsClickedRecord,
    selectedCutvProgram,
    setSelectedCutvProgram,
    isLiveSelected,
    setIsLiveSelected,
    adminProfile,
    setAdminProfile,
    isChangeLockChannel,
    setIsChangeLockChannel,
    isChildProfile,
    setIsChildProfile,
    currentProgramTimes,
    setCurrentProgramTimes,
    nextMytvChannelPrograms,
    setNextMytvChannelPrograms,
    prevMytvChannelPrograms,
    setPrevMytvChannelPrograms,
    mytvChannelPosterPath,
    setMytvChannelPosterPath,
    selectedMytvChannelProgram,
    setSelectedMytvChannelProgram,
    userPackage,
    setUserPackage,
    mesMessage,
    setMesMessage,
    isCollectScriptInitialized,
    setIsCollectScriptInitialized,
    isLogout,
    setIsLogout,
    channelItems,
    setChannelItems,
    selectedGenre,
    setSelectedGenre,
    allProfiles,
    setAllProfiles,
    showLoginMessage,
    setShowLoginMessage,
    programInfo,
    setProgramInfo,
    mesFingerPrintParam,
    setMesFingerPrintParam,
    pictureInPicture,
    setPictureInPicture,
    hasLoggedIn,
    setHasLoggedIn,
    playUrl,
    setPlayUrl,
    handleLogout
  };

  return (
    <SessionContext.Provider value={values ? values : appData}>
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
