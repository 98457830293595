import React, { useEffect, useRef, useState } from "react";
import localizationStrings from "../../../statics/localization";
import ChannelListWidget from "../../channelListWidget/ChannelListWidget";
import PlayerDownArrowIcon from "../../../assets/icons/svg/PlayerDownArrowIcon";
import TvSearchIcon from "../../../assets/icons/svg/TvSearchIcon";
import FilterAllIcon from "../../../assets/icons/svg/FilterAllIcon";

const PlayerChannelList = ({
  selectedChannel,
  onSelectChannel,
  isFullscreen,
  videoRef,
  channels,
  genres,
  selectedGenre,
  setSelectedGenre,
  setSelectedCutvProgram,
  searchFilter,
  userFrameConfig,
  adminProfile,
  profile,
  setCanWatch,
  setLockChannelCode
}) => {
  const [showList, setShowList] = useState(false);
  const [height, setHeight] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [term, setTerm] = useState("");
  const filterTimeoutId = useRef(null);

  useEffect(() => {
    if (isFullscreen) {
      setHeight(videoRef?.clientHeight);
    } else {
      setShowList(false);
    }
  }, [isFullscreen]);

  return (
    <div className={`channels-wrapper ${showList ? "show" : ""}`}>
      <div className={`channels-box ${showList ? "show" : ""}`}>
        <div
          className={`text-row ${isFullscreen ? "show" : ""} ${
            showList ? "opened" : ""
          }`}
          onClick={() => setShowList(!showList)}
        >
          <label>{localizationStrings.channelList}</label>
          <span className="arrow">
            <PlayerDownArrowIcon />
          </span>
        </div>
        {showList ? (
          <div className="filter-box">
            <div className={`tv-search`}>
              <form
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <input
                  id="tv-search"
                  name="tv-search"
                  type="text"
                  value={term}
                  placeholder={localizationStrings.searchChannel}
                  onChange={(e) => {
                    if (filterTimeoutId.current) {
                      clearTimeout(filterTimeoutId.current);
                    }
                    filterTimeoutId.current = setTimeout(() => {
                      searchFilter(e.target.value);
                    }, 500);
                    setTerm(e.target.value);
                  }}
                />
              </form>
              <span>
                <TvSearchIcon />
              </span>
            </div>
            <div
              className={`tv-filter ${isFilterOpen ? "selected" : ""}`}
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <div className="label-text">{selectedGenre?.columnname}</div>
              <div className="filter-icon">
                <FilterAllIcon />
              </div>
            </div>
          </div>
        ) : null}
        <div className={`channel-list-box ${showList ? "show" : ""}`}>
          <ChannelListWidget
            key="player-channel-list"
            uKey="player-channel-list"
            channels={channels}
            genres={genres}
            selectedGenre={selectedGenre}
            selectedChannel={selectedChannel}
            onSelectChannel={onSelectChannel}
            height={height}
            setSelectedGenre={(val) => {
              setSelectedGenre(val);
              setTerm("");
              searchFilter("");
            }}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            setSelectedCutvProgram={setSelectedCutvProgram}
            isPlayer={true}
            userFrameConfig={userFrameConfig}
            adminProfile={adminProfile}
            profile={profile}
            setCanWatch={setCanWatch}
            setLockChannelCode={setLockChannelCode}
            showList={showList}
          />
        </div>
      </div>
    </div>
  );
};

export default PlayerChannelList;
