import React from "react";
import { Route } from "react-router-dom";

const ProtectedRoute = ({ children, ...rest }) => {
  let lang = localStorage.getItem("language");
  let deviceid = localStorage.getItem("deviceid");
  let loginUrl = `${localStorage.getItem(
    "loginurl"
  )}?lan=${lang}&deviceid=${deviceid}`;
  let userType = localStorage.getItem("usertype");

  return (
    <Route
      {...rest}
      render={({ location }) =>
        userType !== "0" ? children : window.location.replace(loginUrl)
      }
    />
  );
};

export default ProtectedRoute;
