import React from "react";

const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 22.637 22.637"
    >
      <g
        id="Group_145"
        data-name="Group 145"
        transform="translate(-700.682 -664.182)"
      >
        <path
          id="Path_16"
          data-name="Path 16"
          d="M18.551,10.04a.99.99,0,1,1-.99-.99A.99.99,0,0,1,18.551,10.04Z"
          transform="translate(694.419 660.119)"
          fill="currentColor"
        />
        <path
          id="Path_17"
          data-name="Path 17"
          d="M18.952,21.811H17.537V14.1H15.415a.707.707,0,0,0,0,1.415h.707v6.3H14.707a.707.707,0,1,0,0,1.415h4.244a.707.707,0,0,0,0-1.415Z"
          transform="translate(695.17 658.641)"
          fill="currentColor"
        />
        <path
          id="Path_18"
          data-name="Path 18"
          d="M13.318,24.637A11.318,11.318,0,1,1,24.637,13.318,11.318,11.318,0,0,1,13.318,24.637Zm0-21.222a9.9,9.9,0,1,0,9.9,9.9A9.9,9.9,0,0,0,13.318,3.415Z"
          transform="translate(698.682 662.182)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default InfoIcon;
