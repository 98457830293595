import React from "react";

const Genre = ({ item, selectedItem, onSelect, marginLeft, minWidth }) => {
  return (
    <div
      className="genre-box"
      style={{ marginLeft: marginLeft, minWidth: minWidth }}
      onClick={() => onSelect(item)}
    >
      <div
        className={
          selectedItem?.id === item?.id
            ? "genre-text selected-genre"
            : "genre-text"
        }
      >
        {item.name}
      </div>
    </div>
  );
};

export default Genre;
