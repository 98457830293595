import React, { useEffect, useState } from "react";
import PlayerDownArrowIcon from "../../../assets/icons/svg/PlayerDownArrowIcon";
import ProductPlacementItem from "../../productPlacementItem";
import { postProductSearchApi } from "../../../api/productPlacement/productPlacementApi";
import mainLoadingGif from "../../../assets/loading.gif";

const ProductsInContent = ({ player, isPaused, isFullscreen, liveui }) => {
  console.log("isFullscreen", isFullscreen);
  const [paused, setPaused] = useState(null);
  //const [url, setUrl] = useState("");
  const [file, setFile] = useState(null);
  const [showList, setShowList] = useState(true);
  const [products, setProducts] = useState(null);
  const [showLoading, setShowLoading] = useState(true);

  const getProductPlacementList = async (formData) => {
    setShowLoading(true);
    try {
      const { data } = await postProductSearchApi(formData);
      console.log("postProductSearchApi", data);
      if (data && data.length > 0) {
        setProducts(data); // Değişiklik burada
      } else {
        console.error("Unexpected API response format", data);
      }
    } catch (error) {
      console.error("Error fetching product list:", error);
    } finally {
      setShowLoading(false);
    }
  };

  const renderedProductItems = products?.map((product, index) => (
    <ProductPlacementItem product={product} key={index} />
  ));

  useEffect(() => {
    if (isPaused) {
      let video = player?.children_[0];
      if (!video) {
        console.error("Video element bulunamadı");
        return;
      }

      let canvas = document.createElement("canvas");
      canvas.setAttribute("id", "screenshot");

      console.log("Video boyutları:", video.videoWidth, video.videoHeight);

      if (video.videoWidth === 0 || video.videoHeight === 0) {
        console.error("Video boyutları sıfır");
        return;
      }

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.fillRect(0, 0, video.videoWidth, video.videoHeight);
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Blob oluşturulamadı");
          return;
        }
        let file = new File([blob], "test.jpg", { type: "image/jpeg" });
        let formData = new FormData();
        formData.append("File", file);
        console.log("FormData içeriği:", formData.get("File"));
        getProductPlacementList(formData);
      }, "image/jpeg");
    }
  }, [isPaused]);

  // useEffect(() => {
  //   if (file) {
  //     console.log("file", file);
  //     let url = URL.createObjectURL(file);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", `test.jpg`);

  //     // Append to html link element page
  //     document.body.appendChild(link);

  //     // Start download
  //     link.click();

  //     // Clean up and remove the link
  //     link.parentNode.removeChild(link);
  //   }
  // }, [file]);

  useEffect(() => {
    console.log("fullss", isFullscreen);
    if (!isFullscreen || isPaused) {
      setShowList(false);
    }
  }, [isFullscreen, isPaused]);
  return (
    <div
      className={`products-wrapper ${showList ? "show" : ""}`}
      onClick={() => {
        //setPaused(!paused);
      }}
    >
      {/* <a href={url} download="test.jpg">
        zort
      </a> */}
      <div className={`products-box ${showList ? "show" : ""}`}>
        <div
          className={`text-row ${
            liveui
              ? isFullscreen && isPaused
                ? "show"
                : ""
              : isPaused
              ? "show"
              : ""
          } ${showList ? "opened" : ""}`}
          onClick={() => setShowList(!showList)}
        >
          <label>Sahneden Al</label>
          <span className="arrow">
            <PlayerDownArrowIcon />
          </span>
        </div>
        {showList ? (
          <div className={`product-list ${isPaused ? "show" : ""}`}>
            {showLoading ? (
              <div className="loading-gif">
                <img src={mainLoadingGif} />
              </div>
            ) : (
              renderedProductItems
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProductsInContent;
