import React from "react";

const PictureInPictureIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="0.95em"
      viewBox="0 0 29 29"
    >
      <g
        id="Group_319"
        data-name="Group 319"
        transform="translate(-1303.679 -1006)"
      >
        <g
          id="Rectangle_295"
          data-name="Rectangle 295"
          transform="translate(1303.679 1006)"
          fill="none"
          stroke="#fff"
          strokeWidth="3"
        >
          <rect width="29" height="29" rx="4" stroke="none" />
          <rect x="1.5" y="1.5" width="26" height="26" rx="2.5" fill="none" />
        </g>
        <rect
          id="Rectangle_296"
          data-name="Rectangle 296"
          width="15.19"
          height="15.19"
          rx="2"
          transform="translate(1305.06 1018.429)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default PictureInPictureIcon;
