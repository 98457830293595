import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Home from "../../views/home";
import LiveTv from "../../views/liveTv";
import Navbar from "../navbar";
import TvGuide from "../../views/tvGuide/TvGuide";
import GridView from "../../views/gridView/GridView";
import Search from "../search";
import TivibuSettings from "../TivibuSettings/TivibuSettings";
import Language from "../language";
import VersionInfo from "../versionInfo";
import ParentalControl from "../parentalControl";
import PurchaseHistory from "../purchaseHistory";
import SuggestionComplain from "../suggestioncomplain/SuggestionComplain";
import UserRecords from "../userRecords/UserRecords";
import PackageList from "../packageList";
import LockedChannel from "../lockedChannels";
import ModalRouting from "../modalRouting/ModalRouting";
import Footer from "../footer";
import { specialForMeId, specialForMeKidId } from "../../statics/config";
import ChatbotComponent from "../chatbotComponent/ChatBotComponent";

const Routing = () => {
  var kid = JSON.parse(localStorage.getItem("profile"));

  let menuId = kid?.kid ? specialForMeKidId : specialForMeId;
  return (
    <div>
      <Navbar />
      <Switch>
        <Route path={`/dashboard/home/:menuId/:categoryId`} component={Home} />
        <Route path={`/dashboard/livetv/:channelNo`} component={LiveTv} />
        <Route path={`/dashboard/tvguide`} component={TvGuide} />
        <Route path={`/dashboard/settings`} component={TivibuSettings} />
        <Route path={`/dashboard/language`} component={Language} />
        <Route path={`/dashboard/version`} component={VersionInfo} />
        <Route
          path={`/dashboard/purchasehistory`}
          component={PurchaseHistory}
        />
        <Route
          path={`/dashboard/suggestioncomplaint`}
          component={SuggestionComplain}
        />
        <Route
          path={`/dashboard/parentalcontrol`}
          component={ParentalControl}
        />
        <Route path={`/dashboard/records`} component={UserRecords} />
        {/* <Route path={`/dashboard/package`} component={PackageList} /> */}
        <Route path={`/dashboard/favlockchannel`} component={LockedChannel} />
        <Route
          path={`/dashboard/genre/:columnCode/:columnName`}
          component={GridView}
        />
        <Route path={`/dashboard/search/:term?`} component={Search} />
        <Redirect path={`/dashboard/livetv`} to={`/dashboard/livetv/all`} />
        <Redirect path={`/dashboard`} to={`/dashboard/home/${menuId}/all`} />
      </Switch>
      <ModalRouting />

      <Switch></Switch>
      <Footer />
      <ChatbotComponent />
    </div>
  );
};

export default Routing;
