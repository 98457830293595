import { reportingEvent } from "../statics/reportEventId";
import moment from "moment";
import { getColectAgent } from "../statics/config";

let player;
let data;
let actionTime;
let reportObject = {
  eventtype: "",
  eventid: "",
  sessionid: "",
  eventtime: "",
  actiontime: "",
  playid: "0",
  contentype: "",
  channelcode: "",
  contentcode: "",
  definition: "",
  programcode: "",
  result: "0",
  errormsg: ""
};
let collectAgent;
let isLive;
let isRecord;

export const registerReportUtils = (obj) => {
  player = obj.player;
  data = obj.data;
  isLive = obj.isLive;
  isRecord = obj.isRecord;
  actionTime = moment(
    moment().valueOf() - parseInt(localStorage.getItem("timeDiff"))
  ).format("yyyyMMDDHHmmss");
  setReportObject();
  registerPlayerEvents();
};

const setReportObject = () => {
  reportObject.definition = data?.definition;
  if (!isLive) {
    if (data?.programcode) {
      reportObject.programcode = data.programcode;
      reportObject.contentype = data.programtype;
      reportObject.contentcode = data.contentcode;
      reportObject.productcode = data.productcode;
      reportObject.producttype = data.producttype;
    } else if (data?.prevuecode) {
      reportObject.contentcode = data.prevuecode;
      if (isRecord) {
        reportObject.contentype = "3";
      } else {
        reportObject.contentype = "4";
      }
    }
  } else {
    reportObject.contentype = "2";
    reportObject.contentcode = data.channelcode;
    reportObject.channelcode = data.channelcode;
  }
};

const registerPlayerEvents = () => {
  collectAgent = getColectAgent();
  onPlaying();
  onEnd();
  onError();
  onDispose();
};

export const unRegisterReportUtils = () => {
  offPlaying();
  offEnd();
  offError();
  offDispose();

  // player = null;
  // data = null;
  // actionTime = null;
};

const onPlaying = () => {
  player.on("playing", () => {
    let eventTime = moment().format("yyyyMMDDHHmmss");

    reportObject.eventtype = reportingEvent.E_MEDIA;
    reportObject.eventid = reportingEvent.E_MEDIA_ONPLAYING;
    reportObject.sessionid = localStorage.getItem("sessionid");
    reportObject.eventTime = eventTime;
    reportObject.actiontime = actionTime;
    collectAgent.logMedia(reportObject);
    offPlaying();
  });
};

const onEnd = () => {
  player.on("ended", () => {
    let eventTime = moment().format("yyyyMMDDHHmmss");
    reportObject.eventtype = reportingEvent.E_MEDIA;
    reportObject.eventid = reportingEvent.E_MEDIA_END;
    reportObject.sessionid = localStorage.getItem("sessionid");
    reportObject.eventTime = eventTime;
    reportObject.actiontime = eventTime;
    collectAgent.logMedia(reportObject);
  });
};

const onDispose = (reportObject) => {
  player.on("dispose", () => {
    let eventTime = moment().format("yyyyMMDDHHmmss");
    reportObject.eventtype = reportingEvent.E_MEDIA;
    reportObject.eventid = reportingEvent.E_MEDIA_END;
    reportObject.sessionid = localStorage.getItem("sessionid");
    reportObject.eventTime = eventTime;
    reportObject.actiontime = eventTime;
    collectAgent.logMedia(reportObject);
  });
};

const onError = (reportObject) => {
  player.on("error", (e) => {
    let error = player.error();
    let errorCode;
    switch (error.code) {
      case 0:
        errorCode = "";
        break;
      case 1:
        errorCode = "";
        break;
      case 2:
        errorCode = "";
        break;
      case 3:
        errorCode = isLive ? "15098653" : "15097653"; //Video format error
        break;
      case 4:
        errorCode = "15097663"; //404 content not found
        break;
      case 5:
        errorCode = isLive ? "15098605" : "15097605"; //DRM error
        break;
      default:
        break;
    }
    let eventTime = moment().format("yyyyMMDDHHmmss");
    reportObject = {
      eventtype: reportingEvent.E_MEDIA,
      eventid: reportingEvent.E_MEDIA_ERROR,
      sessionid: localStorage.getItem("sessionid"),
      eventtime: eventTime,
      actiontime: eventTime,
      playid: "0",
      definition: data.definition,
      result: errorCode,
      errormsg: error.message
    };
    reportObject.eventtype = reportingEvent.E_MEDIA;
    reportObject.eventid = reportingEvent.E_MEDIA_ERROR;
    reportObject.sessionid = localStorage.getItem("sessionid");
    reportObject.eventTime = eventTime;
    reportObject.actiontime = eventTime;
    reportObject.result = errorCode;
    reportObject.errormsg = error.message;
    collectAgent.logMedia(reportObject);
  });
};

const offPlaying = () => {
  player.off("playing");
};

const offEnd = () => {
  if (isLive) {
    let eventTime = moment().format("yyyyMMDDHHmmss");
    reportObject.eventtype = reportingEvent.E_MEDIA;
    reportObject.eventid = reportingEvent.E_MEDIA_END;
    reportObject.sessionid = localStorage.getItem("sessionid");
    reportObject.eventTime = eventTime;
    reportObject.actiontime = eventTime;
    collectAgent.logMedia(reportObject);
  }
  player.off("ended");
};

const offError = () => {
  player.off("error");
};

const offDispose = () => {
  player.off("dispose");
};
