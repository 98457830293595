import React from "react";
import ReplayIcon from "../../../assets/images/replay.png";
import localizationStrings from "../../../statics/localization";

const RestartButton = ({
  player,
  setIsLiveEdge,
  isLive,
  isTimeshiftEnable,
  restartTv,
  isFullscreen
}) => {
  return (
    <button
      className="control-button"
      style={{
        cursor: isTimeshiftEnable ? "pointer" : "not-allowed",
        marginLeft: isFullscreen ? "1.2vw" : null
      }}
      title={localizationStrings.playerOptions.restart}
      onClick={() => {
        if (isTimeshiftEnable) {
          if (isLive) {
            setIsLiveEdge("1");
            restartTv(Math.random() * 1000);
          } else {
            player.currentTime(0);
          }
        }
      }}
    >
      <div style={{ display: "flex", fontSize: "1.6vw" }}>
        <img style={{ width: "1.2em", height: "1.2em" }} src={ReplayIcon} />
      </div>
    </button>
  );
};

export default RestartButton;
