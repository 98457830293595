import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Slider from "react-slick";
import NextIcon from "../../assets/icons/svg/NextIcon";
import PrevIcon from "../../assets/icons/svg/PrevIcon";
import { SessionContext } from "../../context/SessionContext";
import localizationStrings from "../../statics/localization";
import carouselImg from "../../assets/icons/svg/Icon awesome-angle-right.svg";
import AngleRightIcon from "../../assets/icons/svg/AngleRightIcon";
import defaultHorizontalPoster from "../../assets/images/default-vod-horizontal-poster.png";
import {
  getLiveHotChannels,
  getUserLastWatchedChannels
} from "../../api/ltv/ltv";
import moment from "moment";
import { getVodsByLabel } from "../../api/vod/vod";

/* const hotSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5.5,
  slidesToScroll: 5,
  arrows: false,
  draggable: true
}; */

const ChannelCarousel = ({ labelId }) => {
  const [channels, setChannels] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);
  /* const [hotChannels, setHotChannels] = useState(null); */
  const slider = useRef(null);
  /* const hotSlider = useRef(null); */
  const history = useHistory();

  /* let startOfDay = moment().startOf("day").format("YYYYMMDDHHmmss");
  let endOfDay = moment().endOf("day").format("YYYYMMDDHHmmss"); */
  const slidesToShow = 6;
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    arrows: false,
    draggable: true,
    beforeChange: (currentSlide, nextSlide) => {
      setSlideIndex(nextSlide);
    }
  };
  const renderedItems = channels?.map((item, index) => {
    const uniqueKey = `${item?.id}-${index}`;
    return <ChannelCarouselItem key={uniqueKey} item={item} />;
  });

  /* const renderedHotChannels = hotChannels?.map((item) => {
    return <HotChannelCarouselItem key={item.channelcode} item={item} />;
  }); */

  const getLastWatchedChannels = async () => {
    if (labelId) {
      const { data } = await getVodsByLabel(labelId, 0, 150);

      setChannels(data?.channels);
    }
  };

  /*  const getHotChannels = async () => {
    const { data } = await getLiveHotChannels(startOfDay, endOfDay);
    if (data.returncode === "0") {
      setHotChannels(data.data);
    }
  }; */

  useEffect(() => {
    getLastWatchedChannels();
    /* getHotChannels(); */
  }, [labelId]);

  return (
    <div
      className="content-carousel"
      style={{
        display: !channels?.length ? "none" : "",
        marginBottom: "2.78vw",
        marginLeft: "-30px"
      }}
    >
      <label
        className="label-title"
        onClick={() => {
          history.push(
            `/dashboard/genre/${labelId}/${localizationStrings.lastWatchedChannels}`
          );
        }}
      >
        {localizationStrings.lastWatchedChannels}
      </label>

      <div className="slider-row">
        <div
          className="slick-wrapper"
          style={{ left: "0", marginTop: ".78vw" }}
        >
          <Slider ref={slider} {...settings}>
            {renderedItems}
          </Slider>
        </div>

        <div
          className="direction-button prev"
          style={
            channels?.length > slidesToShow && slideIndex !== 0
              ? {}
              : { visibility: "hidden" }
          }
          onClick={() => slider.current.slickPrev()}
        >
          <span>
            <AngleRightIcon />
          </span>
        </div>
        <div
          className="direction-button next"
          style={
            channels?.length > slidesToShow &&
            channels?.length != slideIndex + slidesToShow
              ? {}
              : { visibility: "hidden" }
          }
          onClick={() => slider.current.slickNext()}
        >
          <AngleRightIcon />
        </div>
      </div>
      {/*    {hotChannels ? (
        <>
          <label
            className="label-title"
            style={{ marginTop: "80px" }}
            onClick={() => {
              history.push(
                `/dashboard/genre/hotchannels/${localizationStrings.watchTv}`
              );
            }}
          >
            {localizationStrings.watchTv}
          </label>

          <div className="slider-row">
            <div
              className="slick-wrapper"
              style={{ left: "-15px", marginTop: ".78vw" }}
            >
              <Slider ref={hotSlider} {...hotSettings}>
                {renderedHotChannels}
              </Slider>
            </div>

            <div
              className="direction-button prev"
              style={
                channels?.length > 5.5
                  ? { top: "27%" }
                  : { visibility: "hidden", top: "27%" }
              }
              onClick={() => hotSlider.current.slickPrev()}
            >
              <span>
                <AngleRightIcon />
              </span>
            </div>
            <div
              className="direction-button next"
              style={
                channels?.length > 5.5
                  ? { top: "27%" }
                  : { visibility: "hidden", top: "27%" }
              }
              onClick={() => hotSlider.current.slickNext()}
            >
              <AngleRightIcon />
            </div>
          </div>
        </>
      ) : null} */}
    </div>
  );
};

const ChannelCarouselItem = ({ item, fromSearch }) => {
  const [height, setHeight] = useState(0);
  const history = useHistory();
  const itemRef = useRef(null);

  return (
    <div
      onClick={() => {
        history.push(`/dashboard/livetv/${item?.id}`);
      }}
      className={`channel-carousel-item-card ${
        fromSearch ? "search-item" : ""
      }`}
    >
      <div
        ref={itemRef}
        className="channel-carousel-item"
        style={{ height: height + "px" }}
      >
        <img
          onLoad={() => {
            setHeight((itemRef.current?.offsetWidth * 32) / 57);
          }}
          src={item?.logo}
          style={{ width: "50%" }}
        />
      </div>
    </div>
  );
};

//// Home Hot Channel Item /////

/* const HotChannelCarouselItem = ({ item }) => {
  const [posterUrl, setPosterUrl] = useState("");
  const history = useHistory();
  const itemRef = useRef(null);

  useEffect(() => {
    if (item) {
      let url = `${item?.prevuearray[0]?.posterpath}/${
        item?.prevuearray[0]?.posterfilelist.split(";")[1]
      }`;
      setPosterUrl(url);
    }
  }, [item]);

  return (
    <div
      onClick={() => {
        history.push(`/dashboard/livetv/${item?.channelcode}`);
      }}
      className={`channel-carousel-item-card`}
    >
      <div ref={itemRef} className="carousel-item">
        <img
          src={posterUrl}
          alt={item?.channelname}
          onError={() => setPosterUrl(defaultHorizontalPoster)}
        />
      </div>
      <div className="title">
        {item?.channelname + " • " + item?.prevuearray[0]?.prevuename}{" "}
      </div>
    </div>
  );
}; */

//// Home Hot Channel Item /////

export {
  ChannelCarousel,
  ChannelCarouselItem
  /* HotChannelCarouselItem  */
};
