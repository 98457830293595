import React from "react";
import { useEffect, useContext, useRef } from "react";
import {
  getPipAudio,
  getPipCurrentTime,
  getPipPlayUrl,
  getPipPoster,
  getPipVod,
  getPipSelectedEpisode,
  getPipLiveui,
  getPipIsRecord,
  getPipIsPreview,
  getPipSelectedSeason,
  getPipSubtitles,
  getPipVolumeLevel,
  getWhereFrom,
  nullifyPip,
  setIsFromPip,
  getIsPltv,
  setCurrentTimeIsFromPip
} from "../../statics/config";
import videojs from "video.js";
import { SessionContext } from "../../context/SessionContext";
import { useHistory, useLocation } from "react-router-dom";
import { addBookmark } from "../../api/common/commonApi";
import { sendCustomBookmarkReport } from "../../utils/reporting/customReporting";

const playerOptions = {
  //fill: true,
  fluid: true,
  responsive: true,
  preload: "auto",
  controls: true,
  disablePictureInPicture: false,
  controlBar: {
    pictureInPictureToggle: true
  },
  liveui: !!getPipLiveui() ? getPipLiveui() : false,
  autoplay: true
  //loop: true
};

const PictureInPicturePlayer = ({ pictureInPicture }) => {
  const pipPlayData = getPipPlayUrl();
  //const pipAudio = getPipAudio();
  const pipVolumeLevel = getPipVolumeLevel();
  const pipCurrentTime = getPipCurrentTime();
  //const pipSubtitles = getPipSubtitles();
  const pipPoster = getPipPoster();
  const pipVod = getPipVod();
  const pipSelectedEpisode = getPipSelectedEpisode();
  const pipLiveui = getPipLiveui();
  const pipIsRecord = getPipIsRecord();
  const pipIsPreview = getPipIsPreview();
  const pipSelectedSeason = getPipSelectedSeason();
  const pipIsPltv = getIsPltv();
  let whereFrom = getWhereFrom();
  //const [currentTime, setCurrentTime] = useState(null);
  const {
    setPictureInPicture,
    selectedCutvProgram,
    setSelectedCutvProgram,
    selectedChannel
  } = useContext(SessionContext);
  const playerRef = useRef(null);
  const selectedCutvProgramRef = useRef(selectedCutvProgram);
  const bookmarkIntId = useRef(null);
  const toWaitBookmarkRef = useRef(null);
  const history = useHistory();
  const location = useLocation();

  const initPlayer = () => {
    if (!playerRef.current)
      playerRef.current = videojs("pictureInPicturePlayer", playerOptions);

    playerRef.current.src(pipPlayData.cache_.source);
    playerRef.current.currentTime(pipCurrentTime);
    playerRef.current.volume(pipVolumeLevel);
    playerRef.current.poster(pipPoster);

    playerRef.current.play();
    navigator.mediaSession.setActionHandler("play", () => {
      playerRef.current.play();
    });
    navigator.mediaSession.setActionHandler("pause", () => {
      playerRef.current.pause();
    });
  };

  const addBookmarkToContent = async (
    breakPoint,
    contentCode,
    columnCode,
    bookmarkType,
    programCode
  ) => {
    if (!breakPoint) {
      return;
    }
/*     const { data } = await addBookmark(
      bookmarkType,
      contentCode,
      columnCode,
      breakPoint,
      playerRef.current.duration()
    );

    sendCustomBookmarkReport(
      contentCode,
      programCode,
      breakPoint,
      columnCode,
      bookmarkType,
      1,
      data.returncode,
      data.errormsg
    ); */
    toWaitBookmarkRef.current && clearTimeout(toWaitBookmarkRef.current);
  };

  useEffect(() => {
    pipPlayData && initPlayer();
  }, [pipPlayData]);

  useEffect(() => {
    if (!!bookmarkIntId.current) clearInterval(bookmarkIntId.current);
    return () => {
      playerRef.current && playerRef.current.dispose();
    };
  }, []);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.on("loadedmetadata", () => {
        playerRef.current.requestPictureInPicture();

        if (
          pipLiveui &&
          !pipVod?.prevuecode &&
          !selectedCutvProgramRef.current?.prevuecode
        ) {
          if (!!bookmarkIntId.current) clearInterval(bookmarkIntId.current);
        } else {
          if (!!bookmarkIntId.current) clearInterval(bookmarkIntId.current);
          let bookmarkType;
          let contentCode;
          let columnCode = null;
          let programCode = null;
          if (pipVod?.programcode) {
            if (pipVod?.programtype == 1) {
              bookmarkType = 1;
            } else if (pipVod?.programtype == 14) {
              if (pipSelectedEpisode) {
                bookmarkType = 4;
              }
            }
            if (!!pipVod?.channelcode) {
              bookmarkType = 19; // cutv series
            }
            contentCode = pipVod.contentcode;
            columnCode = pipVod.columncode;
            programCode = pipVod.programcode;
          }
          if (selectedCutvProgramRef.current?.prevuecode) {
            bookmarkType = 3;
            contentCode = selectedCutvProgramRef.current.prevuecode;
            columnCode = selectedChannel.columncode;
          }
          if (pipVod?.prevucecode) {
            bookmarkType = 3;
            contentCode = pipVod.prevuecode;
            columnCode = selectedChannel.columncode;
          }
          //playerRef.current.off("timeupdate");
          //playerRef.current.on("timeupdate", () => {
          if (!pipIsPreview) {
            bookmarkIntId.current = setInterval(() => {
              let bookmarkTime;
              if (pipSelectedEpisode) {
                bookmarkTime = `${pipSelectedSeason.seriesseason}|${
                  pipSelectedEpisode.seriesnum
                }|${parseInt(playerRef.current.currentTime())}|${
                  pipSelectedEpisode?.contentcode
                }`;
              } else {
                bookmarkTime = parseInt(playerRef.current.currentTime());
              }
              addBookmarkToContent(
                bookmarkTime,
                contentCode,
                columnCode,
                bookmarkType,
                programCode
              );
            }, 30000);
          }
          // });
        }
        //!pipLiveui && listenTimeUpdate(playerRef.current);
      });

      playerRef.current.on("leavepictureinpicture", () => {
        let bookmarkType;
        let contentCode;
        let columnCode = null;
        let programCode = null;
        let currentTime = playerRef.current.currentTime(); // it is seconds but different format
        //console.log(currentTime);
        // if (pipIsPltv) {
        //   let now = new Date().getTime() / 1000; //seconds

        //   let liveCurrentTime = liveTracker.current.liveCurrentTime(); // it is seconds but different format
        //   // getting progress percentage with this formula: (now - (liveCurrentTime - currentTime) - begintime) / (endtime- begintime)
        //   progress =
        //     ((now -
        //       (liveCurrentTime - currentTime) -
        //       pipProgramBeginTime.current) /
        //       (pipProgramEndTime.current - pipProgramBeginTime.current)) *
        //     1000;
        // }

        if (pipVod?.programcode) {
          if (pipVod?.programtype == 1) {
            bookmarkType = 1;
          } else if (pipVod?.programtype == 14) {
            if (pipSelectedEpisode) {
              bookmarkType = 4;
            }
          }
          if (!!pipVod?.channelcode) {
            bookmarkType = 19; // cutv series
          }
          contentCode = pipVod.contentcode;
          columnCode = pipVod.columncode;
          programCode = pipVod.programcode;
        }
        if (selectedCutvProgramRef.current?.prevuecode) {
          bookmarkType = 3;
          contentCode = selectedCutvProgramRef.current.prevuecode;
          columnCode = selectedChannel.columncode;
        }
        if (pipVod?.prevucecode) {
          bookmarkType = 3;
          contentCode = pipVod.prevuecode;
          columnCode = selectedChannel.columncode;
        }
        if (!pipIsPreview) {
          let bookmarkTime;
          if (pipSelectedEpisode) {
            bookmarkTime = `${pipSelectedSeason.seriesseason}|${
              pipSelectedEpisode.seriesnum
            }|${parseInt(playerRef.current.currentTime())}|${
              pipSelectedEpisode?.contentcode
            }`;
          } else {
            bookmarkTime = parseInt(playerRef.current.currentTime());
          }
          addBookmarkToContent(
            bookmarkTime,
            contentCode,
            columnCode,
            bookmarkType,
            programCode
          );
        }
        toWaitBookmarkRef.current = setTimeout(2000);
        bookmarkIntId.current && clearInterval(bookmarkIntId.current);
        setPictureInPicture(false);
        whereFrom = getWhereFrom();
        if (!pipIsRecord) {
          if (
            //!window.location.href.includes(whereFrom) ||
            window.location.href.includes("livetv") ||
            !!!whereFrom
          ) {
            nullifyPip();
            return;
          }
          if (!!selectedCutvProgramRef.current) {
            setTimeout(() => {
              setSelectedCutvProgram(selectedCutvProgramRef.current);
            }, 2000);
          }
          nullifyPip();
          whereFrom.includes("pipPlay")
            ? history.push(whereFrom)
            : history.push(`${whereFrom}&pipPlay=1`);
          if (pipIsPltv) {
            setTimeout(() => {
              setCurrentTimeIsFromPip(currentTime + 3);
            }, 1000);
          }
        } else {
          //if (location.pathname + location.search == whereFrom) return;
          if (!!!whereFrom || window.location.href.includes("livetv")) {
            nullifyPip();
          } else {
            setIsFromPip(pipVod);
            history.push(whereFrom);
            nullifyPip();
          }
        }
      });
      // playerRef.current.on("enterpictureinpicture", () => { });
    }
  }, [playerRef.current]);

  // useEffect(() => {
  //   if (pipProgram?.prevuecode && playerRef.current) {
  //     pipProgramBeginTime.current =
  //       moment(pipProgram?.begintime, "YYYY.MM.DD HH:mm").valueOf() / 1000; // seconds
  //     pipProgramEndTime.current =
  //       moment(pipProgram?.endtime, "YYYY.MM.DD HH:mm").valueOf() / 1000; // seconds
  //   }
  // }, [pipProgram, playerRef.current]);

  return (
    <div style={{ width: "100%" }}>
      <video
        style={{
          width: "100%",
          height: "auto",
          position: "absolute",
          top: "0"
        }}
        id="pictureInPicturePlayer"
        className="picture-in-picture-player"
        onClick={() => {
          if (playerRef.current.paused()) {
            playerRef.current.play();
          } else {
            playerRef.current.pause();
          }
        }}
      ></video>
      {/* <div
        className="control-buttons"
        style={{
          position: "absolute",
          top: "-50px",
          display: "flex",
          flexDirection: "row"
        }}
      >
        <div
          className="exit"
          style={{ backgroundColor: "white" }}
          onClick={() => {
            setPictureInPicture(false);
          }}
        >
          exit
        </div>
        <div
          className="back to page"
          style={{ backgroundColor: "white" }}
          onClick={() => {
            whereFrom.includes("play")
              ? history.push(whereFrom)
              : history.push(`${whereFrom}&play=1`);
            setPictureInPicture(false);
            nullifyPip();
          }}
        >
          goback
        </div>
        <div
          className="full-app-pip"
          style={{ backgroundColor: "red" }}
          onClick={() => {
            playerRef.current.requestPictureInPicture();
            //playerRef.current.play();
          }}
        >
          full app pip
        </div> 
      </div> */}
    </div>
  );
};

export default PictureInPicturePlayer;
