import React, { useRef } from "react";
import PlayerInfoIcon from "../../../assets/icons/svg/PlayerInfoIcon";
import ProgramDetail from "../../programDetail";
import ReactDOM from "react-dom";
import localizationStrings from "../../../statics/localization";

const PlayerInfo = ({
  player,
  prevueCode,
  setSelectedCutvProgram,
  selectedChannel,
  userFrameConfig,
  userInfo,
  location,
  history,
  setRecordStatus,
  isFullscreen
}) => {
  const programDetailEl = useRef(null);

  const createInfoEl = () => {
    programDetailEl.current = document.createElement("div");
    programDetailEl.current.classList.add("items-wrapper");
    programDetailEl.current.setAttribute("id", `player-program-info`);
    player.el().appendChild(programDetailEl.current);

    renderInfoBox();
  };

  const removeInfoEl = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById(`player-program-info`)
    );
    programDetailEl.current.remove();
    programDetailEl.current = null;
  };

  const renderInfoBox = () => {
    ReactDOM.render(
      <ProgramDetail
        prevueCode={prevueCode}
        closeModal={() => removeInfoEl()}
        fromLive={true}
        setSelectedCutvProgram={setSelectedCutvProgram}
        selectedChannel={selectedChannel}
        userFrameConfig={userFrameConfig}
        userInfo={userInfo}
        location={location}
        hasHeight={true}
        isPlayer={true}
        history={history}
        setRecordStatus={setRecordStatus}
      />,
      document.getElementById(`player-program-info`)
    );
  };

  return (
    <button
      className="control-button"
      style={{ marginLeft: isFullscreen ? "1.2vw" : null }}
      title={localizationStrings.playerOptions.info}
      onClick={() => {
        programDetailEl.current ? removeInfoEl() : createInfoEl();
      }}
    >
      <PlayerInfoIcon />
    </button>
  );
};

export default PlayerInfo;
