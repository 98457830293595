import React, { useState } from "react";
import localizationStrings from "../../../statics/localization";
import Notification from "../../notification";
import PlayerExitIcon from "../../../assets/icons/svg/PlayerExitIcon";
import { getVodPlayUrlById } from "../../../api/vod/vod";
import moment, { months } from "moment";
import { isSafari } from "react-device-detect";

const ExitButton = ({
  player,
  goBack,
  showExitNotification,
  contentId,
  vod,
  selectedSeason,
  selectedEpisode,
  isCutvVod
}) => {
  const [showExitContainer, setShowExitContainer] = useState(false);
  const cuEpisodeInfo = isCutvVod
    ? isSafari
      ? moment(selectedEpisode?.onlinetime, "YYYY-MM-DDTHH:mm:ssZ").format(
          "D MMMM"
        )
      : `${moment(selectedEpisode?.onlinetime).date()} ${months(
          moment(selectedEpisode?.onlinetime).month()
        )}`
    : null;
  const handleSendExitTime = async () => {
    if (contentId) {
      let exitTime = Math.floor(player.cache_.currentTime);
      const data = await getVodPlayUrlById(contentId, exitTime);
      console.log({ data });
    }
  };

  const clickConfirm = () => {
    handleSendExitTime();
    setShowExitContainer(false);
    showExitNotification(false);
    goBack();
  };

  const clickNotConfirm = () => {
    setShowExitContainer(false);
    showExitNotification(false);
  };

  return (
    <div>
      {showExitContainer ? (
        <Notification
          text={localizationStrings.exitPlayerText}
          confirmText={localizationStrings.yes}
          notConfirmText={localizationStrings.no}
          onClickConfirm={clickConfirm}
          onClickNotConfirm={clickNotConfirm}
          dontUsePortal={true}
        />
      ) : (
        <div className="exit-and-info-wrapper">
          <button
            className="exit-button"
            onClick={() => {
              setShowExitContainer(true);
              showExitNotification(true);
              player.pause();
            }}
          >
            <PlayerExitIcon />
          </button>
          <div className="content-info-box">
            <div className="content-title">{vod?.title}</div>
            {selectedSeason && selectedEpisode ? (
              <>
                {isCutvVod ? (
                  <div className="episode-info">{cuEpisodeInfo}</div>
                ) : (
                  <div className="episode-info">
                    {selectedSeason?.seasonNo
                      ? `S${selectedSeason?.seasonNo}:`
                      : null}
                    {selectedEpisode?.episodeNo
                      ? `${
                          localizationStrings.getLanguage() === "eng"
                            ? "E"
                            : "B"
                        }${selectedEpisode?.episodeNo}`
                      : null}
                    {(selectedSeason?.seriesseason ||
                      selectedEpisode?.seasonNo) &&
                    selectedEpisode?.title ? (
                      <span>&nbsp; | &nbsp;</span>
                    ) : (
                      ""
                    )}
                    {selectedEpisode?.title}
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExitButton;
