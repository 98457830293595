import LocalizedStrings from "react-localization";

const DEFAULT_LANGUAGE = "eng";

let localizationStrings = new LocalizedStrings({
  eng: {
    rentals: "leased",
    illuminationText: "Illumination Text",
    inform: "İnform",
    support: "Support",
    communication: "Communication",
    onePassword: "One Password",
    commetcial: "Commetcial",
    movie: "Movies",
    watchTv: "Live TV",
    series: "Series",
    tvGuide: "TV Guide",
    liveTv: "Live TV",
    catchupTv: "CATCHUP TV",
    specialForMe: "My Stuff",
    menuKid: "Kids",
    movieSerie: "Movie/Serie",
    menuRent: "Rent & Purchase",
    rentVod: "Rent",
    buyVod: "Buy",
    addToMyList: "ADD TO MY LIST",
    share: "SHARE",
    removeFromMyList: "Remove from my list",
    removeContent: "Remove content",
    recorded: "Recorded",
    recordSaved: "Recorded",
    toBeRecorded: "To be recorded",
    recordeds: "Recordeds",
    willRecord: "To be recorded",
    delete: "Delete",
    send: "Send",
    deleteAll: "Remove all",
    removeList: "Remove list",
    restartTv: "STARTOVER",
    toLive: "WATCH LIVE",
    recordProgram: "Record",
    nextPrograms: "Next Programs",
    prevPrograms: "Previous Programs",
    director: "Director",
    cast: "Cast",
    watch: "Watch",
    goChannel: "Watch Channel",
    goLink: "Open Link",
    goCategory: "Open Category",
    score: "SCORE",
    relatedVods: "Related Contents",
    season: "Season",
    seasons: "Seasons",
    episode: "Episode",
    episodes: "Episodes",
    filter: "Filter",
    allChannels: "All Channels",
    today: "Today",
    tomorrow: "Tomorrow",
    yesterday: "Yesterday",
    noProgram: "No program info",
    settings: "Settings",
    packageOperations: "Package Operations",
    myRecords: "My Records",
    parentalControl: "Parental Control",
    mySuggestion: "I have a suggestion",
    lockChannel: "Channel Locking",
    favAndLock: "Lock Channel / Add to Favorite",
    favChannel: "Favorite Channels",
    favlockChannel: "Favorite Channels and Channel Lock",
    purchaseHistory: "Rent - Purchase History",
    purchaseHistoryy: "Hire - Purchase History",
    rentPurchase: "Rental and Purchase History",
    searchHistory: "Search History",
    historySearch: "Search History",
    menuLanguage: "Menu Language",
    versionAndUser: "Version and User Info",
    suggestionComplaint: "Suggestion / Complaint",
    tivibugo: "Web, Mobile, Tablet and Smart TV with Tivibu Go",
    tivibuHouse: "You can watch Tivibu from anywhere and on any device.",
    tivibuEv: "Unlimited television enjoyment with Tivibu Ev to live",
    tivibuPleasure: "Enjoy Tivibu from your TV whenever you want.",
    goTivibu: "Tivibu Go",
    houseTivibu: "Tivibu Ev",
    mesaage: "Write Your Message",
    logout1: "Exit",
    logout: "Log out",
    logout2: "Log out",
    delete: "Delete",
    userCode: "User Code",
    appVersion: "Application Version",
    networkStatus: "Network Status",
    ipStatus: "IP Status",
    mwAuth: "MW Authentication",
    videoStreamStatus: "Video Stream Availability",
    videoDecodeStatus: "Video Decoding Status",
    edit: "Edit",
    deleteAllHistory: "Delete All History",
    deleteAllPurchaseHistoryText:
      "Are you sure you want to delete all purchase history?",
    yes: "Yes",
    no: "No",
    lockChannelText: "Are you sure you want to lock this channel?",
    favChannelText:
      "Are you sure you want to add this channel to your favorites?",
    deleteRecordText: "Are you sure you want to delete selected records?",
    changeLangText: "Are you sure you want to change application language?",
    logoutText: "Are you sure want to log out?",
    deleteSearchHistoryText: "Are you sure want to delete search history?",
    userRecords: "MY RECORDS",
    records: "All My Records",
    signIn: "Sign In",
    yourPackage: "Package you have",
    myCurrentPackage: "My Current Package",
    programFlow: "Program Flow",
    login: {
      title: "The latest movies, series, TV shows and much more",
      subtitle: "Are you ready to join the colorful and fun world of Tivibu?",
      easyLogin: "EASY LOGIN",
      new: "New",
      onePasswordLogin: "Login",
      // onePasswordLogin: "Login with SinglePassword",
      notYetSub: "Not yet a Tivibu customer?",
      rememberMe: "Remember Me",
      doneMessage:
        "Your GSM information will be checked and the password will be sent. If you do not receive the password, you can check your information or call 444 5 375.",
      username: "E-Mail / Gsm No",
      password: "Password",
      pin: "PIN",
      fastLogin: "Fast Sign In",
      text: "Sign In",
      or: "or",
      beMember: "Are you not Tivibu a member?",
      signUp: "Sign Up",
      forgotPassword: "Forgot My Password",
      changePassword: "Change My Password",
      phoneNumberAndEmail: "phone number / e-mail",
      mobilePhoneNumber: "Mobile Phone Number",
      validNumberText: "Please enter a valid 10-digit phone number",
      enterText:
        " Enter your phone number or e-mail address to reset your password.",
      enterTextPhone: "Enter your phone number to reset your password",
      confirmText:
        "Your new Password will be sent to your mobile phone number.",
      confirm: "Okay"
    },
    minute: "min",

    profile: {
      addProfile: "Add Profile",
      change: "Change Profile",
      createPassword: "Create Profile Password",
      editPassword: "Update Profile Password",
      save: "Save",
      new: "New Profile",
      add: "Add",
      delete: "Delete Profile",
      text: "Which profile do you want to enter?",
      logging: "Logging in",
      enterProfilePassword:
        "Profile password is required to watch this content.",
      enterProfilePasswordToBuyRent:
        "Profile password is required to buy/rent this content.",
      enterProfilePasswordToLock:
        "Profile password is required to lock this content.",
      enterProfilePasswordToUnlock:
        "Profile password is required to unlock this content.",
      enterAdminProfilePassword:
        "Admin profile password is required to watch this content.",
      enterAdminProfilePasswordToLock:
        "Admin profile password is required to lock this content.",
      enterAdminProfilePasswordToUnlock:
        "Admin profile password is required to unlock this content.",
      removedProfile: "This profile is not existing in the system.",
      pinLength: "password length must be 4 digits number"
    },
    cancel: "Cancel",
    enter: "Enter",
    payment: {
      selectPayment: "Choose payment method",
      option1: "My Bill",
      option2: "Mobile Payment",
      option3: "Credit Card",
      option4: "Use Promotion Code",
      rentPassword: "Enter your rental password",
      purchasePassword: "Enter your purchase password",
      rent: "rent",
      purchase: "purchase",
      confirmText:
        "Do you approve to {action} {content} for {day} for {price}?",
      confirmTextBuy: "Do you approve to {action} {content} serie for {price}?",
      confirmTextBundle:
        "Do you approve to {action} {content} serie for {day} for {price}?",
      confirmTextBuyBundle: "Do you approve to {action} {content} for {price}?",
      confirm: "Confirm",
      cancel: "Cancel",
      enterPhoneNumber: "Enter mobile phone number",
      enterSmsCode: "Enter the SMS confirmation code sent to your phone",
      notEnteredSmsCode:
        "You did not enter the SMS confirmation code in the time given to you.",
      newSmsCode: "Request New SMS Verification Code",
      remainingTime: "Remaining time ",
      enterCreditCardInfo: "Enter credit card informations",
      nameOnCard: "Name on card",
      cardNo: "Card No",
      month: "Month",
      year: "Year",
      cvcCode: "CVC Code",
      readPrivacyPolicy: "I Accept The {privacyPolicy}",
      cancelPayment: "Cancel Payment",
      askAdminPassword: "Enter admin profile password",
      enterPromoCode: "Enter promotion code"
    },
    days: "days",
    privacyPolicy: "Privacy Policy",
    allChannels: "All Channels",
    minuteShort: "min",
    secondShort: "secs",
    quality: "Quality",
    subtitle: "Subtitle",
    dubbing: "Audio",
    off: "Off",
    exitPlayerText: "Are you sure you want to exit the video?",
    channelList: "Channel List",
    myList: "My List",
    continueToWatch: "Continue to Watch",
    recomendedByTT: "Recommended by Tivibu",
    youMaybeLike: "Reccomend for You",
    watchItAgain: "Watch It Again",
    myTvodPurchase: "My Rentals",
    myEstPurchase: "My Purchases",
    newReleased: "New Released",
    haveSeenAll: "You have seen it all",
    stopRecord: "STOP RECORD",
    deleteRecord: "Delete Record",
    toBeRecord: "To Be Record",
    recording: "Recording",
    recordDeleted: "Record Deleted",
    deleteRecordConfirmText: "Are you sure want to delete this record?",
    recordAllSerie:
      "Record this episode and any episodes that will release later",
    recordThisEpisode: "Record only this episode",
    deleteRecordEpisode: "Delete this episode record",
    deleteAllSerie: "Delete the whole series record",
    profilePincode: "Enter pin for {profileName} profile",
    error: {
      tvSubscription: "Your product does not contain this channel"
    },
    watchNowText: "Do you want to watch this content now?",
    watchNow: "Watch Now",
    watchLater: "Watch Later",
    parentalControlText: {
      general: "General Spectator",
      sevenPlus: "Over 7 Years Old",
      thirteenPlus: "Over 13 Years Old",
      allAge: "Over 18 Years Old",
      changeText:
        "Are you sure want to change parental control for this profile?"
    },
    categories: "Categories",
    kidsProfile: "Kids Profile",
    ppvText: "Do you approve to {action} {content} for {price}?",
    npvrQuota: "Total Time That Can Be Recorded",
    npvrQuotaa: "Recorded:",
    npvrRemaining: "Remaining:",
    total: "You have used %{percentage} of your total quota.",
    expired: "Saved content will be deleted after 90 days.",
    hour: "hour",
    minutes: "minutes",
    lastWatchedChannels: "Last Watched Channels",
    purchaseFilter: "Last {count} {format}",
    live: "live",
    searchFilters: {
      all: "All",
      movie: "Movie",
      series: "Series",
      program: "Programs",
      cuTv: "CUTV",
      tvChannel: "TV Channels"
    },
    searchResult: "Search Results",
    createProfilePinCodeText: "First you should create a profile password",
    createAdminProfilePinCodeText:
      "First you should create admin profile password",
    createProfilePinCode: "Create Password",
    lockedChannel: "the channel is locked",
    usePromoCode: "Use Promotion Code",
    loginText: "You should sign in to watch this content.",
    outOfProduct:
      "Your package does not contain this content. You can look our other packages which contains this content.",
    cancelPaymentExpired:
      "Payment cancellation failed. Rental cancellation period has expired",
    askCancelPayment: "Do you want to cancel payment?",
    close: "Close",
    continue: "Continue",
    next: "Next",
    startOver: "Start Over",
    bookmarkText: "Do you want to continue?",
    child: "Child",
    normalProfile: "Normal Profile",
    blockAutoDelete: "Block automatically delete",
    allowAutoDelete: "Allow automatically delete",
    npvrHourLimitText:
      "Too much content added to the recording schedule today. The total duration of the recording exceeds {hour} hours. Would you still want to continue?",
    npvrEpisodeLimitText:
      "Too much content added to the recording schedule today. Too many episodes. Would you still want continue?",
    disableButtonParentalText: "Your parental control setting is not allow",
    vodLoginText: "You should sign in to do this process",
    wrongPinText: "Profile password is not correct. Please try again.",
    mesMessage: {
      suspend: "Your account has suspended. We will sign you out",
      removedProfile: "This profile is not active. We will sign you out",
      orderPackage: "Your product has been changed. Please relogin",
      recoverPackage: "Your product is recovered",
      deviceQuota: "New device signed in with this account. You will sign out.",
      userOnlineQuery:
        "There is a active session with this account. If you login, other user will kick out. Do you want to still continue? "
    },
    audioOriginal: "Original",
    bundleContentText: "Do you want to {action} {contentName} serie?",
    incompatibleBrowser:
      "Your browser is not compatible with tivibu application",
    shouldLogin: "You should login",
    turkish: "Turkish",
    arabic: "Arabic",
    english: "English",
    tvodSaveTime: "This program record does not exist.",
    descriptive: "Descriptive",
    returnCode: "Code",
    message: "Message",
    shortHour: "hrs",
    shortMinutes: "mins",
    searchChannel: "Search Channel",
    tvSearchResultText: "Found {count} channel results for {term}",
    tvSearchNoResultText: "No channel result found for {term}",
    info: "Information",
    releaseDate: "Release Date",
    seeAll: "See All",
    goLive: "back live",
    inspect: "Inspect",
    inMyList: "In my list",
    shareNow: "Share Now",
    play: "Play",
    duration: "Duration",
    genre: "Genre",
    preview: "Preview",
    profileManagement: "Profile Management",
    closeSession: "Sign Out",
    profileLoginText: "Select the profile you want to login",
    editProfile: "Edit Profile",
    enterProfilePassword: "Enter Your Profile Password",
    profileName: "Profile Name",
    optional: "optional",
    again: "Again",
    saveProfile: "Save Profile",
    passwordsMatch: "Passwords does not match",
    watch: "Watch",
    playSpeed: "Play Speed",
    contentError:
      "We have some problems to get content. Please try again later.",
    removeProfilePassword: "Remove Profile Password",
    removeProfilePasswordText: "Are you sure to remove this profile password?",
    rentedOnes: "Rented ones",
    noSearchHistory: "There is no search history",
    month: "month",
    lockedChannels: "Locked Channels",
    renteds: "Renteds",
    purchaseds: "Purchaseds",
    noChannelFound: "There is no channel with these filters.",
    noRecords: "There is no record",
    typeMessage: "Write your message",
    suggestion: "Suggestion",
    complaint: "Complaint",
    feedbackResponse:
      "Your demand is recieved to us. Thank you for your feedback.",
    feedbackResponseError:
      "We can not get your demand. Please try again later.",
    askProfilePin: "Please enter profile password.",
    askAdminPin: "Please enter admin profile password.",
    searchSuggestions: "Suggestions",
    noSearchResult: "your {term} returned no result.",
    sugg1: "You can try different keywords",
    sugg2: "Are you looking for movie or serie?",
    sugg3: "You can try movie, serie, program, channel, actor or director name",
    sugg4: "You can try like comedy, love, sport or dram genre",
    record: "Record",
    channelDrmErrorMessage:
      "Channel cannot be played. Restart your app, then try again. If the problem continues, get support from customer service.(444 5 375)",
    vodDrmErrorMessage:
      "Content cannot be played. Restart your app, then try again. If the problem continues, get support from customer service.(444 5 375)",
    errorCode: "Error Code",
    errorMessage: "Message",
    noNetworkMessage:
      "Try again after checking your internet connections. If the problem persists, you can get support from customer service. (444 5 375)",
    invalidParamError:
      "The requested operation could not be completed. Try again after closing and reopening your application. Then call 444 5 375 if the problem persists.",
    contentNotFoundError:
      "Channel/Content cannot be played. Restart your app, then try again. If the problem continues, get support from customer service.(444 5 375)",
    manifestChannelError:
      "Channel cannot be played. Restart your app, then try again. If the problem continues, get support from customer service.(444 5 375)",
    manifestVodError:
      "Content cannot be played. Restart your app, then try again. If the problem continues, get support from customer service.(444 5 375)",
    videoFormatErrorChannel:
      "Channel cannot be played. Restart your app, then try again. If the problem continues, get support from customer service.(444 5 375)",
    videoFormatErrorVod:
      "Content cannot be played. Restart your app, then try again. If the problem continues, get support from customer service.(444 5 375)",
    recordOption: "Which option do you want record this content?",
    deleteRecordOption: "Which delete option do you want to use?",
    profileNameWarning: "You should enter profile name",
    autoBitrate: "Auto",
    searchSuggestText: "Year, Director, Actor...",
    informText:
      "Dear User, Your Tivibu GO service is provided by Net Screen 11 Televizyon and Medya Hiz. AS is offered by As long as your membership continues, you can continue to receive service by maintaining your subscription rights.",
    installationProcess: "How do I upgrade my package?",
    channelCoverage: "Your package does not include this channel.",
    contentControl: "This program has content control.",
    programMonitoring: "How do I watch the program?",
    channelLocking: "You have locked this channel.",
    grooving: "How do I unlock a locked channel?",
    packageInstall: {
      packageChange:
        "You can contact our customer service at {phone} or write {watchChannel} and send an SMS to {number} to get information about our packages in which this Channel is included and to request a package change.",
      watchChannel: "WATCH CHANNEL",
      number: "6606",
      phone: "444 5 375",
      control:
        "{adjustment} menüsü altındaki {parentalControl} listesini güncelleyerek bu programı izleyebilirsiniz.",
      adjustment: "Settings",
      parentalControl: "Parental Control",
      channelLock:
        "You can remove the channel lock from the {lockChannel} list under the {adjustment} menu.",
      lockChannel: "Lock Channel"
    },
    forceLogoutMessage:
      "Only one device can be logged in at a time. Your session has been terminated due to logging in from a different device.",
    mainLoadingText: "Loading. Please wait...",
    emptyMessageText: "This area can not be empty.",
    rebuildSessionText: "Your session timed out, you should login.",
    playerOptions: {
      track: "Subtitle/Audio",
      quality: "Quality",
      record: "Record",
      info: "Program Info",
      secsToBack: "Rewind 15 secs",
      sectsToForward: "Forward 15 secs",
      play: "Play",
      pause: "Pause",
      audioOff: "Audio Off",
      audioOn: "Audio On",
      restart: "Restart",
      offFullscreen: "Toggle Off Fullscreen",
      onFullscreen: "Toggle On Fullscreen"
    }
  },

  arb: {
    illuminationText: "نص الإضاءة",
    inform: "إشعار",
    support: "دعم",
    communication: "تواصل",
    onePassword: "كلمة مرور واحدة",
    commetcial: " التجاري",
    watchVod: "شاهد و اختر",
    movie: "فيلم",
    series: "مسلسل",
    watchTv: "مباشر",
    tvGuide: "دليل التلفزيون",
    liveTv: "عرض مباشر",
    menuKid: "أطفال",
    movieSerie: "فيلم/مسلسل",
    catchupTv: "مشاهدة متأخرة",
    specialForMe: "خاص بي",
    menuRent: "استئجار و شراء",
    rentVod: "استئجار",
    buyVod: "شراء",
    renteds: "تم استئجارها",
    purchaseds: "تم شراؤها",
    addToMyList: "أضف إلى قائمتي",
    share: "مشاركة",
    removeFromMyList: "إزالة من قائمتي",
    removeContent: "حذف المحتوى",
    recordeds: "تم تسجيلها",
    willRecord: "سيتم التسجيل",
    delete: "حذف",
    deleteAll: "حذف الكل",
    removeList: "تنظيف القائمة",
    restartTv: "إعادة التشغيل",
    toLive: "مشاهدة مباشرة",
    recordProgram: "تسجيل",
    stopRecord: "إيقاف التسجيل",
    deleteRecord: "حذف التسجيل",
    mySuggestion: "اقتراحي",
    send: "إرسال",
    nextPrograms: "البرامج القادمة",
    prevPrograms: "البرامج السابقة",
    director: "المخرج",
    cast: "الأبطال",
    score: "تقييم",
    relatedVods: "المحتويات ذات الصلة",
    season: "الموسم",
    seasons: "المواسم",
    episode: "الحلقة",
    episodes: "الحلقات",
    today: "اليوم",
    tomorrow: "غدًا",
    yesterday: "أمس",
    noProgram: "لا توجد معلومات برنامج",
    settings: "إعدادات ",
    packageOperations: "عمليات الحزمة",
    packages: "الحزم",
    myRecords: "تسجيلاتي",
    parentalControl: "الرقابة الأبوية",
    lockChannel: "قفل القناة",
    favChannel: "القنوات المفضلة",
    favlockChannel: "القنوات المفضلة وقفل القناة",
    purchaseHistory: "سجل الشراء والاستئجار",
    searchHistory: "سجل البحث",
    menuLanguage: "لغة القائمة",
    versionAndUser: "معلومات الإصدار والمستخدم",
    suggestionComplaint: "اقتراح / شكوى",
    favAndLock: "قفل القناة / إضافة إلى المفضلة",
    allChannels: "كل القنوات",
    filter: "تصفية",
    logout: "تسجيل الخروج",
    logout2: "خروج",
    delete: "حذف",
    userCode: "رمز المستخدم",
    toBeRecorded: "سيتم تسجيله",
    appVersion: "إصدار التطبيق",
    networkStatus: "حالة الشبكة",
    ipStatus: "حالة عنوان IP",
    mwAuth: "المصادقة بواسطة MW",
    videoStreamStatus: "حالة بث الفيديو",
    videoDecodeStatus: "حالة فك تشفير الفيديو",
    myCurrentPackage: "حزمتي الحالية",
    edit: "تعديل",
    deleteAllHistory: "حذف كل السجلات",
    npvrQuotaa: "إجمالي المحتوى المسجل",
    npvrRemaining: "المتبقي من الحق في التسجيل",
    expired: "سيتم حذف المحتويات المسجلة بعد 90 يومًا",
    deleteAllPurchaseHistoryText: "هل أنت متأكد أنك تريد حذف كل سجلات الشراء؟",
    yes: "نعم",
    no: "لا",
    tivibugo:
      "تيفيبو جو عبر الويب، الهاتف المحمول، الجهاز اللوحي والتلفزيون الذكي",
    tivibuHouse: "شاهد تيفيبو من أي مكان وعلى أي جهاز ترغب فيه",
    tivibuEv: "تجربة تيفيبو المنزلية غير محدودة للتلفزيون",
    tivibuPleasure: "استمتع بتيفيبو في أي وقت من تلفزيونك",
    lockChannelText: "هل أنت متأكد أنك تريد قفل هذه القناة؟",
    total: "إجمالي استخدام الحصة الخاصة بك %{percentage}",
    favChannelText: "هل أنت متأكد أنك تريد إضافة هذه القناة إلى المفضلة؟",
    deleteRecordText: "هل أنت متأكد أنك تريد حذف التسجيلات المحددة؟",
    changeLangText: "هل أنت متأكد أنك تريد تغيير لغة التطبيق؟",
    logoutText: "هل أنت متأكد أنك تريد تسجيل الخروج؟",
    deleteSearchHistoryText: "هل أنت متأكد أنك تريد حذف سجل البحث؟",
    userRecords: "تسجيلاتي",
    signIn: "تسجيل الدخول",
    yourPackage: "الحزمة الخاصة بك",
    programFlow: "تدفق البرنامج",
    login: {
      title: "أحدث الأفلام والمسلسلات والبرامج التلفزيونية وأكثر من ذلك بكثير",
      subtitle: "هل أنت مستعد للانضمام إلى عالم Tivibu الملون والممتع؟",
      easyLogin: "تسجيل الدخول السهل",
      new: "جديد",
      onePasswordLogin: "تسجيل الدخول",
      notYetSub: "ألست بعد عميلًا في Tivibu؟",
      rememberMe: "تذكرني",
      doneMessage: "سيتم إرسال كلمة المرور بعد التحقق من معلومات رقم هاتفك المحمول. إذا لم تصلك كلمة المرور، يرجى التحقق من المعلومات الخاصة بك أو الاتصال بالرقم 4445375.",
      username: "البريد الإلكتروني / رقم الجوال",
      password: "كلمة المرور",
      fastLogin: "تسجيل الدخول السريع",
      text: "تسجيل الدخول",
      or: "أو",
      beMember: "لست عضوًا في Tivibu؟",
      signUp: "التسجيل",
      forgotPassword: "نسيت كلمة المرور",
      changePassword: "تغيير كلمة المرور",
      phoneNumberAndEmail: "رقم الهاتف / البريد الإلكتروني",
      mobilePhoneNumber: "رقم الجوال",
      validNumberText: "يرجى إدخال رقم هاتف صالح يحتوي على 10 أرقام",
      enterText: "لإعادة تعيين كلمة المرور، يرجى إدخال رقم هاتفك أو عنوان بريدك الإلكتروني.",
      enterTextPhone: "لإعادة تعيين كلمة المرور، يرجى إدخال رقم هاتفك.",
      confirmText: "سيتم إرسال كلمة المرور الجديدة إلى رقم هاتفك الجوال.",
      confirm: "حسنًا"
    },
    
    profile: {
      addProfile: "إضافة ملف شخصي",
      change: "تغيير الملف الشخصي",
      createPassword: "إنشاء كلمة مرور للملف الشخصي",
      editPassword: "تعديل كلمة مرور الملف الشخصي",
      save: "حفظ",
      new: "ملف شخصي جديد",
      add: "إضافة",
      delete: "حذف الملف الشخصي",
      text: "أي ملف شخصي ترغب في استخدامه؟",
      logging: "جارٍ تسجيل الدخول",
      enterProfilePassword: "لمشاهدة هذا المحتوى، يتطلب ذلك كلمة مرور الملف الشخصي.",
      enterProfilePasswordToLock: "لقفل هذا المحتوى، يتطلب ذلك كلمة مرور الملف الشخصي.",
      enterProfilePasswordToUnlock: "لإلغاء قفل هذا المحتوى، يتطلب ذلك كلمة مرور الملف الشخصي.",
      enterAdminProfilePassword: "لمشاهدة هذا المحتوى، يتطلب ذلك كلمة مرور الملف الشخصي الرئيسي.",
      enterAdminProfilePasswordToLock: "لقفل هذا المحتوى، يتطلب ذلك كلمة مرور الملف الشخصي الرئيسي.",
      enterAdminProfilePasswordToUnlock: "لإلغاء قفل هذا المحتوى، يتطلب ذلك كلمة مرور الملف الشخصي الرئيسي.",
      removedProfile: "هذا الملف الشخصي غير متوفر في النظام.",
      pinLength: "يجب أن تكون كلمة مرور الملف الشخصي مكونة من 4 أحرف."
    },
    minute: "دقيقة",
    watch: "شاهد",
    goChannel: "الذهاب إلى القناة",
    goLink: "الذهاب إلى الرابط",
    goCategory: "الذهاب إلى الفئة",
    cancel: "إلغاء",
    enter: "دخول",
    payment: {
      selectPayment: "اختر طريقة الدفع",
      option1: "اعتماد على فاتورتي",
      option2: "الدفع الجوال",
      option3: "الدفع ببطاقة الائتمان",
      option4: "استخدام رمز الترويج",
      rentPassword: "أدخل كلمة مرور الإيجار",
      purchasePassword: "أدخل كلمة مرور الشراء",
      rent: "الإيجار",
      purchase: "الشراء",
      confirmText:
        "هل توافق على {action} {content} لمدة {day} {price}؟",
      confirmTextBuy:
        "هل توافق على {action} {content} بسعر {price}؟",
      confirmTextBundle:
        "هل توافق على {action} {content} لمدة {day} {price}؟",
      confirmTextBuyBundle:
        "هل توافق على {action} {content} بسعر {price}؟",
      confirm: "أوافق",
      cancel: "إلغاء",
      enterPhoneNumber: "أدخل رقم هاتفك النقال",
      enterSmsCode: "أدخل رمز التحقق من الرسائل النصية",
      newSmsCode: "اطلب رمز التحقق الجديد عبر الرسائل النصية",
      remainingTime: "الوقت المتبقي",
      enterCreditCardInfo: "أدخل معلومات بطاقة الائتمان",
      nameOnCard: "الاسم على البطاقة",
      cardNo: "رقم البطاقة",
      month: "الشهر",
      year: "السنة",
      cvcCode: "رمز CVC",
      readPrivacyPolicy: "أوافق على {privacyPolicy}.",
      notEnteredSmsCode: "لم تدخل رمز التحقق من الرسائل النصية في الوقت المحدد.",
      cancelPayment: "إلغاء الدفع",
      askAdminPassword: "أدخل كلمة مرور الملف الشخصي الرئيسي",
      enterPromoCode: "أدخل رمز العرض الترويجي"
    },
    days: "أيام",
    privacyPolicy: "سياسة الخصوصية",
    allChannels: "جميع القنوات",
    minuteShort: "دق",
    secondShort: "ثا",
    quality: "الجودة",
    subtitle: "ترجمة",
    dubbing: "تدبيج",
    off: "إيقاف",
    exitPlayerText: "هل أنت متأكد أنك تريد الخروج من الفيديو؟",
    channelList: "قائمة القنوات",
    myList: "قائمتي",
    continueToWatch: "متابعة المشاهدة",
    recomendedByTT: "موصى به من تيفيبو",
    youMaybeLike: "قد تعجبك",
    watchItAgain: "شاهده مرة أخرى",
    myTvodPurchase: "مشترياتي الإيجارية",
    myEstPurchase: "مشترياتي الشرائية",
    newReleased: "أحدث الإصدارات",
    haveSeenAll: "لقد رأيت الكل",
    records: "جميع السجلات",
    toBeRecord: "التسجيل",
    recording: "جار التسجيل",
    recordDeleted: "تم حذف السجل",
    deleteRecordConfirmText: "هل أنت متأكد أنك تريد حذف السجل؟",
    recordAllSerie: "سجل هذا الحلقة وكل الحلقات اللاحقة",
    recordThisEpisode: "سجل هذه الحلقة فقط",
    deleteRecordEpisode: "حذف سجل هذه الحلقة",
    deleteAllSerie: "حذف سجل هذا المسلسل بأكمله",
    profilePincode: "أدخل كلمة المرور لملف {profileName}.",
    error: {
      tvSubscription: "هذه القناة غير متوفرة في حزمتك التلفزيونية"
    },
    watchNowText: "هل ترغب في مشاهدة هذا المحتوى الآن؟",
    watchNow: "شاهد الآن",
    watchLater: "شاهد لاحقاً",
    parentalControlText: {
      general: "للجميع",
      sevenPlus: "لمن هم فوق 7 سنوات",
      thirteenPlus: "لمن هم فوق 13 سنة",
      allAge: "لمن هم فوق 18 سنة",
      changeText: "هل أنت متأكد أنك تريد تغيير إعدادات الرقابة الأبوية لهذا الملف؟"
    },
    categories: "الفئات",    
    kidsProfile: "ملف الأطفال",
    ppvText: "هل توافق على {action} {content} بسعر {price}؟",
    npvrQuota: "الوقت الكلي المسجل",
    hour: "ساعة",
    minutes: "دقيقة",
    lastWatchedChannels: "القنوات التي شاهدتها مؤخراً",
    purchaseFilter: "الأخير {count} {format}",
    live: "مباشر",
    searchFilters: {
      all: "الكل",
      movie: "فيلم",
      series: "مسلسل",
      program: "برامج",
      cuTv: "شاهد مرة أخرى",
      tvChannel: "قنوات التلفزيون"
    },
    searchResult: "نتائج البحث",
    createProfilePinCodeText: "يجب أولاً إنشاء رمز PIN للملف الشخصي",
    createAdminProfilePinCodeText: "يجب أولاً إنشاء رمز PIN رئيسي للملف الشخصي",
    createProfilePinCode: "إنشاء كلمة مرور",
    lockedChannel: "القناة مقفلة",
    loginText: "يجب عليك تسجيل الدخول لمشاهدة هذا المحتوى.",
    outOfProduct:
      "المحتوى الذي اخترته غير متوفر ضمن حزمتك. يمكنك زيارة حزمنا الأخرى التي تحتوي على المحتويات التي ترغب فيها.",
    cancelPaymentExpired:
      "تعذر إلغاء الدفع. انتهت مدة إلغاء الإيجار.",
    askCancelPayment: "هل أنت متأكد أنك تريد إلغاء الدفع؟",
    close: "إغلاق",
    continue: "متابعة",
    next: "التالي",
    startOver: "ابدأ من جديد",
    bookmarkText: "هل ترغب في متابعة العرض؟",
    child: "طفل",
    normalProfile: "ملف شخصي عادي",
    blockAutoDelete: "منع الحذف التلقائي",
    allowAutoDelete: "السماح بالحذف التلقائي",
    npvrHourLimitText:
      "تمت إضافة الكثير من المحتويات إلى قائمة التسجيل. تجاوزت مدة التسجيل الإجمالية {hour} ساعة. هل ترغب في المتابعة؟",
    npvrEpisodeLimitText:
      "تمت إضافة الكثير من المحتويات إلى قائمة التسجيل. عدد الحلقات كبير. هل ترغب في المتابعة؟",
    disableButtonParentalText: "الرقابة الأبوية لا تسمح بهذا.",
    vodLoginText: "يجب عليك تسجيل الدخول لإتمام هذا الإجراء",
    wrongPinText: "كلمة المرور غير صحيحة. يرجى المحاولة مرة أخرى.",
    mesMessage: {
      suspend:
        "تم تعليق حسابك. سيتم تسجيل خروج تلقائي من حسابك.",
      removedProfile:
        "نظرًا لإزالة هذا الملف الشخصي، سيتم توجيهك مرة أخرى إلى شاشة اختيار الملف الشخصي.",
      orderPackage:
        "نظرًا لتغيير حزمتك، سيتم تسجيل خروج تلقائيًا.",
      recoverPackage: "تم استعادة المنتج الخاص بك",
      deviceQuota:
        "تم تسجيل الدخول باستخدام جهاز جديد. سيتم تسجيل خروج تلقائيًا.",
      userOnlineQuery:
        "هناك جلسة نشطة أخرى باستخدام هذا الحساب. إذا كنت ترغب في المتابعة، سيتم إنهاء جلسة المستخدم الآخر."
    },
    audioOriginal: "أصلي",
    bundleContentText: "هل ترغب في {action} سلسلة {contentName}؟",
    incompatibleBrowser: "المتصفح غير متوافق مع تطبيق تيفيبو.",
    shouldLogin: "يجب عليك تسجيل الدخول",
    turkish: "التركية",
    english: "الإنجليزية",
    tvodSaveTime: "لم يتم حفظ تسجيل هذا البرنامج.",
    descriptive: "وصفي",
    returnCode: "رمز الخطأ",
    message: "رسالة",
    shortHour: "ساعة",
    shortMinutes: "دقيقة",
    searchChannel: "البحث عن قناة",
    tvSearchResultText: "تم العثور على {count} قناة متعلقة بـ {term}.",
    tvSearchNoResultText: "لم يتم العثور على أي نتائج قناة متعلقة بـ {term}.",
    info: "معلومات",
    releaseDate: "تاريخ الإصدار",
    recorded: "مسجل",
    recordSaved: "المسجلات المحفوظة",
    seeAll: "عرض الكل",
    goLive: "العودة للبث المباشر",
    inspect: "تفقد",
    inMyList: "في قائمتي",
    shareNow: "مشاركة الآن",
    play: "تشغيل",
    duration: "المدة",
    genre: "النوع",
    preview: "معاينة",
    profileManagement: "إدارة الملف الشخصي",
    closeSession: "إغلاق الجلسة",
    profileLoginText: "اختر الملف الشخصي الذي ترغب في تسجيل الدخول إليه",
    editProfile: "تعديل الملف الشخصي",
    enterProfilePassword: "ادخل كلمة المرور للملف الشخصي",
    profileName: "اسم الملف الشخصي",
    optional: "اختياري",
    again: "مرة أخرى",
    saveProfile: "حفظ الملف الشخصي",
    passwordsMatch: "كلمات المرور غير متطابقة",
    watch: "مشاهدة",
    playSpeed: "سرعة التشغيل",
    contentError:
      "حدث خطأ أثناء جلب معلومات المحتوى. يرجى المحاولة مرة أخرى في وقت لاحق.",
    removeProfilePassword: "إزالة كلمة المرور للملف الشخصي",
    removeProfilePasswordText:
      "هل أنت متأكد من رغبتك في إزالة كلمة مرور الملف الشخصي؟",
    noSearchHistory: "لا توجد سجلات بحث.",
    month: "شهر",
    lockedChannels: "القنوات المقفلة",
    noChannelFound: "لم يتم العثور على أي قناة متعلقة بالفل",
    feedbackResponse: "تم استلام طلبك. شكرًا لتعليقك.",
    feedbackResponseError: "لم نتمكن من استلام طلبك. يرجى المحاولة مرة أخرى لاحقًا.",
    askProfilePin: "الرجاء إدخال كلمة مرور الملف الشخصي الخاصة بك.",
    askAdminPin: "الرجاء إدخال كلمة مرور الصفحة الرئيسية.",
    searchSuggestions: "الاقتراحات",
    noSearchResult: "لم تعثر على أي نتائج لبحثك عن {term}.",
    sugg1: "جرب كلمات مفتاحية مختلفة",
    sugg2: "هل تبحث عن فيلم أو مسلسل؟",
    sugg3: "جرب بالاسم: فيلم، مسلسل، برنامج، قناة، ممثل أو مخرج",
    sugg4: "جرب نوعًا مثل كوميدي، رومانسي، رياضي أو درامي",
    record: "تسجيل",
    errorCode: "رمز الخطأ",
    errorMessage: "رسالة",
    channelDrmErrorMessage: "لا يمكن تشغيل القناة. يرجى إعادة فتح التطبيق والمحاولة مرة أخرى. إذا استمرت المشكلة، يمكنك الحصول على الدعم من خدمة العملاء. (444 5 375)",
    vodDrmErrorMessage: "لا يمكن تشغيل المحتوى. يرجى إعادة فتح التطبيق والمحاولة مرة أخرى. إذا استمرت المشكلة، يمكنك الحصول على الدعم من خدمة العملاء. (444 5 375)",
    noNetworkMessage: "يرجى التحقق من اتصال الإنترنت الخاص بك والمحاولة مرة أخرى. إذا استمرت المشكلة، يمكنك الحصول على الدعم من خدمة العملاء. (444 5 375)",
    invalidParamError: "لم يتمكن من إكمال العملية المطلوبة. يرجى إعادة فتح التطبيق والمحاولة مرة أخرى. إذا استمرت المشكلة، يرجى الاتصال بـ 444 5 375.",
    contentNotFoundError: "لا يمكن تشغيل القناة/المحتوى. يرجى إعادة فتح التطبيق والمحاولة مرة أخرى. إذا استمرت المشكلة، يمكنك الحصول على الدعم من خدمة العملاء. (444 5 375)",
    manifestChannelError: "لا يمكن تشغيل القناة. يرجى إعادة فتح التطبيق والمحاولة مرة أخرى. إذا استمرت المشكلة، يمكنك الحصول على الدعم من خدمة العملاء. (444 5 375)",
    manifestVodError: "لا يمكن تشغيل المحتوى. يرجى إعادة فتح التطبيق والمحاولة مرة أخرى. إذا استمرت المشكلة، يمكنك الحصول على الدعم من خدمة العملاء. (444 5 375)",
    videoFormatErrorChannel: "لا يمكن تشغيل القناة. يرجى إعادة فتح التطبيق والمحاولة مرة أخرى. إذا استمرت المشكلة، يمكنك الحصول على الدعم من خدمة العملاء. (444 5 375)",
    videoFormatErrorVod: "لا يمكن تشغيل المحتوى. يرجى إعادة فتح التطبيق والمحاولة مرة أخرى. إذا استمرت المشكلة، يمكنك الحصول على الدعم من خدمة العملاء. (444 5 375)",
    recordOption: "كيف ترغب في تسجيل هذا المحتوى؟",
    deleteRecordOption: "أي خيار للحذف ترغب في استخدامه؟",
    profileNameWarning: "يجب إدخال اسم الملف الشخصي",
    autoBitrate: "تلقائي",
    searchSuggestText: "العام، المخرج، الممثل...",
    informText: "عزيزنا العميل، يتم تقديم خدمة Tivibu GO من قبل Net Ekran 11 Television and Media Hiz. AŞ، التي هي شركة تابعة لمجموعة Türk Telekom. طالما تستمر اشتراكك، يمكنك الاستمرار في الاستفادة من حقوق الحزمة التي اشتركت بها.",
    channelCoverage: "حزمتك لا تشمل هذه القناة.",
    installationProcess: "كيف يمكنني ترقية حزمتي؟",
    contentControl: "هناك مراقبة للمحتوى في هذا البرنامج.",
    programMonitoring: "كيف يمكنني مشاهدة البرنامج؟",
    channelLocking: "لقد قمت بتأمين هذه القناة.",
    grooving: "كيف يمكنني فتح القناة المؤمنة؟",
    packageInstall: {
      packageChange: "يمكنك الحصول على معلومات حول باقاتنا التي تتضمن هذه القناة وطلب تغيير الحزمة عن طريق الاتصال بخدمة العملاء على الرقم {phone}، أو يمكنك إرسال كلمة {watchChannel} إلى {number}.",
      watchChannel: "مشاهدة القناة",
      number: "6606",
      phone: "444 5 375",
      control: "يمكنك مشاهدة هذا البرنامج عن طريق تحديث قائمة {parentalControl} في قائمة {adjustment} تحت {adjustment}.",
      adjustment: "الإعدادات",
      parentalControl: "التحكم الأبوي",
      channelLock: "يمكنك إلغاء قفل القناة من قائمة {lockChannel} تحت قائمة {adjustment}.",
      lockChannel: "قفل القناة"
    },
    forceLogoutMessage: "يمكن فقط تسجيل الدخول من جهاز واحد في نفس الوقت. تم إنهاء جلستك لأن تم تسجيل الدخول من جهاز آخر.",
    mainLoadingText: "Yükleniyor. Lütfen bekleyiniz...",
    emptyMessageText: "Bu alanı boş bırakmayınız.",
    rebuildSessionText:
      "انتهت صلاحية جلستك، يجب عليك تسجيل الدخول مرة أخرى.",
    playerOptions: {
      track: "الترجمة / الصوت",
      quality: "الجودة",
      record: "تسجيل",
      info: "معلومات البرنامج",
      secsToBack: "الرجوع 15 ثانية",
      sectsToForward: "التقدم 15 ثانية",
      play: "تشغيل",
      pause: "إيقاف",
      audioOff: "إيقاف الصوت",
      audioOn: "تشغيل الصوت",
      restart: "إعادة البدء",
      offFullscreen: "الخروج من وضع الشاشة الكاملة",
      onFullscreen: "الدخول إلى وضع الشاشة الكاملة"
    }
  },
  tr: {
    illuminationText: "Aydınlatma Metni",
    inform: "Bilgilendirme",
    support: "Destek",
    communication: "İletişim",
    onePassword: "Tek Şifre",
    commetcial: "Ticari",
    watchVod: "SEÇ İZLE",
    movie: "Film",
    series: "Dizi",
    watchTv: "Canlı TV",
    tvGuide: "TV Rehberi",
    liveTv: "Yayın Görünümü",
    menuKid: "Çocuk",
    movieSerie: "Film/Dizi",
    catchupTv: "TEKRAR İZLE",
    specialForMe: "Bana Özel",
    menuRent: "Kirala & Satın Al",
    rentVod: "Kirala",
    buyVod: "Satın Al",
    renteds: "Kiralananlar",
    purchaseds: "Satın Alınanlar",
    addToMyList: "LİSTEME EKLE",
    share: "PAYLAŞ",
    removeFromMyList: "Listemden çıkar",
    removeContent: "İçeriği sil",
    recordeds: "Kayıt Edilenler",
    willRecord: "Kayıt Edilecekler",
    delete: "Sil",
    deleteAll: "Tümünü Sil",
    removeList: "Listeyi temizle",
    restartTv: "BAŞTAN BAŞLAT",
    toLive: "CANLI İZLE",
    recordProgram: "Kaydet",
    stopRecord: "KAYDI DURDUR",
    deleteRecord: "Kaydı Sil ",
    mySuggestion: "Önerim var",
    send: "Gönder",
    nextPrograms: "Sonraki Programlar",
    prevPrograms: "Önceki Programlar",
    director: "Yönetmen",
    cast: "Başroldekiler",
    score: "PUANLA",
    relatedVods: "Benzer İçerikler",
    season: "Sezon",
    seasons: "Sezonlar",
    episode: "Bölüm",
    episodes: "Bölümler",
    today: "Bugün",
    tomorrow: "Yarın",
    yesterday: "Dün",
    noProgram: "Program bilgisi bulunmamaktadır",
    settings: "Ayarlar",
    packageOperations: "Paket İşlemleri",
    packages: "Paketler",
    myRecords: "Kayıtlarım",
    parentalControl: "Ebeveyn Kontrolü",
    lockChannel: "Kanal Kilitleme",
    favChannel: "Favori Kanallar",
    favlockChannel: "Favori Kanallar ve Kanal Kilidi",
    purchaseHistory: "Kiralama ve Satın Alma Geçmişi",
    searchHistory: "Arama Geçmişi",
    menuLanguage: "Menü Dili",
    versionAndUser: "Versiyon ve Kullanıcı Bilgileri",
    suggestionComplaint: "Öneri / Şikayet",
    favAndLock: "Kanal Kilitle / Favoriye Ekle",
    allChannels: "Tüm Kanallar",
    filter: "Filtrele",
    logout: "Çıkış",
    logout2: "Çıkış Yap",
    delete: "Sil",
    userCode: "Kullanıcı Kodu",
    toBeRecorded: "Kayıt Edilecek",
    appVersion: "Uygulama Versiyonu",
    networkStatus: "Ağ Durumu",
    ipStatus: "IP Durumu",
    mwAuth: "MW Kimlik Doğrulama",
    videoStreamStatus: "Video Yayın Durumu",
    videoDecodeStatus: "Video Şifre Çözme Durumu",
    suggestionComplaint: "Öneri / Şikayet",
    myCurrentPackage: "Mevcut Paketim",
    edit: "Düzenle",
    deleteAllHistory: "Tüm Geçmişi Sil",
    npvrQuotaa: "Kaydedilen toplam içerik",
    npvrRemaining: "Kalan kaydetme hakkınız",
    expired: "Kaydedilen içerikler 90 gün sonra silinecektir",
    deleteAllPurchaseHistoryText:
      "Tüm geçmiş satınalmaları silmek istediğinize emin misiniz?",
    yes: "Evet",
    no: "Hayır",
    tivibugo: "Tivibu Go ile Web, Cep, Tablet ve Smart TV",
    tivibuHouse: "İstediğiniz yerden, istediğiniz cihazdan Tivibu izleyin",
    tivibuEv: "Tivibu Ev ile sınırsız televizyon keyfini yaşayın",
    tivibuPleasure:
      "İstediğiniz zaman televizyonunuzdan Tivibu keyfini yaşayın.",
    lockChannelText: "Bu kanalı kilitlemek istediğinize emin misiniz?",
    total: "Toplam kota kullanımınız %{percentage}",
    favChannelText:
      "Bu kanalı favorilerinize eklemek istediğinize emin misiniz?",
    deleteRecordText: "Seçili kayıtları silmek istediğinize emin misiniz?",
    changeLangText: "Uygulama dilini değiştirmek istediğinize emin misiniz?",
    logoutText: "Çıkış yapmak istediğinize emin misiniz?",
    deleteSearchHistoryText:
      "Arama geçmişini silmek istediğinize emin misiniz?",
    userRecords: "KAYITLARIM",
    signIn: "Giriş Yap",
    yourPackage: "Sahip Olduğunuz Paket",
    programFlow: "Program Akışı",
    login: {
      title: "En yeni filmler, diziler, TV programları ve çok daha fazlası",
      subtitle:
        "Tivibu'nun renkli ve eğlenceli dünyasına katılmaya hazır mısınız?",
      easyLogin: "KOLAY GİRİŞ",
      new: "Yeni",
      onePasswordLogin: "Giriş Yap",
      // onePasswordLogin: "TekŞifre ile giriş",
      notYetSub: "Henüz Tivibu müşterisi değil misiniz?",
      rememberMe: "Beni Hatırla",
      doneMessage:
        "GSM bilginiz kontrol edilerek şifre gönderimi sağlanacaktır,şifre ulaşmadığı durumda bilgilerinizi kontrol edebilir ya da 444 5 375'i arayabilirsiniz.",
      username: "E-Posta / Gsm No",
      password: "Şifre",
      fastLogin: "Hızlı Giriş",
      text: "Giriş Yap",
      or: "veya",
      beMember: "Tivibu üyesi değil misin?",
      signUp: "Üye Ol",
      forgotPassword: "Şifremi Unuttum",
      changePassword: "Şifremi Değiştir",
      phoneNumberAndEmail: "telefon numarası / e-posta",
      mobilePhoneNumber: "Cep Telefon Numarası",
      validNumberText: "Lütfen 10 haneli geçerli bir telefon numarası girin",
      enterText:
        "Şifrenizi yenilemek için telefon numaranızı veya e-posta adresinizi girin.",
      enterTextPhone: "Şifrenizi yenilemek için telefon numaranızı girin",
      confirmText: "Yeni Şifreniz cep telefonu numaranıza gönderilecektir.",
      confirm: "Tamam"
    },

    profile: {
      addProfile: "Profil Ekle",
      change: "Profil Değiştir",
      createPassword: "Profil Şifresi Oluştur",
      editPassword: "Profil Şifreni Değiştir",
      save: "Kaydet",
      new: "Yeni Profil",
      add: "Ekle",
      delete: "Profili Sil",
      text: "Hangi profil ile girmek istiyorsun?",
      logging: "Giriş yapılıyor",
      enterProfilePassword:
        "Bu içeriği izleyebilmek için profil şifreniz gerekmektedir.",
      enterProfilePasswordToLock:
        "Bu içeriği kilitleyebilmek için profil şifreniz gerekmektedir.",
      enterProfilePasswordToUnlock:
        "Bu içeriğin kilidini kaldırmak için profil şifreniz gerekmektedir.",
      enterAdminProfilePassword:
        "Bu içeriği izleyebilmek için ana profil şifreniz gerekmektedir.",
      enterAdminProfilePasswordToLock:
        "Bu içeriği kilitleyebilmek için ana profil şifreniz gerekmektedir.",
      enterAdminProfilePasswordToUnlock:
        "Bu içeriğin kilidini kaldırmak için ana profil şifreniz gerekmektedir.",
      removedProfile: "Bu profil sistemde mevcut değildir.",
      pinLength: "Profil şifresi 4 karakter olmalıdır."
    },
    minute: "dk",
    watch: "İzle",
    goChannel: "Kanala Git",
    goLink: "Linke Git",
    goCategory: "Kategoriye Git",
    cancel: "Vazgeç",
    enter: "Giriş",
    payment: {
      selectPayment: "Ödeme yöntemi seçin",
      option1: "Faturama Yansıt",
      option2: "Mobil Ödeme",
      option3: "Kredi Kartı ile",
      option4: "Promosyon Kodu Kullan",
      rentPassword: "Kiralama şifrenizi girin",
      purchasePassword: "Satın alma şifrenizi girin",
      rent: "kiralamayı",
      purchase: "satın almayı",
      confirmText:
        "{price}‘ye {content} içeriğini {day}  süresince {action} onaylıyor musunuz?",
      confirmTextBuy:
        "{price}‘ye {content} içeriğini {action} onaylıyor musunuz?",
      confirmTextBundle:
        "{price}‘ye {content} serisini {day}  süresince {action} onaylıyor musunuz?",
      confirmTextBuyBundle:
        "{price}‘ye {content} serisini {action} onaylıyor musunuz?",
      confirm: "Onaylıyorum",
      cancel: "İptal",
      enterPhoneNumber: "Cep telefon numaranızı girin",

      enterSmsCode: "Telefonunuza gelen SMS onay kodunu girin",
      newSmsCode: "Yeni SMS Onay Kodu İste",
      remainingTime: "Kalan süre ",
      enterCreditCardInfo: "Kredi kartı bilgilerinizi girin",
      nameOnCard: "Kart üzerindeki isim",
      cardNo: "Kart numarası",
      month: "Ay",
      year: "Yıl",
      cvcCode: "CVC Kodu",
      readPrivacyPolicy: "{privacyPolicy}'nı Kabul Ediyorum.",
      notEnteredSmsCode: "Size tanınan sürede SMS onay kodunu girmediniz.",
      cancelPayment: "Ödemeyi İptal Et",
      askAdminPassword: "Ana profil şifrenizi girin",
      enterPromoCode: "Promosyon kodunuzu girin"
    },
    days: "gün",
    privacyPolicy: "Gizlilik Politikası",
    allChannels: "Tüm Kanallar",
    minuteShort: "dk",
    secondShort: "sn",
    quality: "Kalite",
    subtitle: "Altyazı",
    dubbing: "Seslendirme",
    off: "Kapalı",
    exitPlayerText: "Videodan çıkmak istediğinize emin misiniz?",
    channelList: "Kanal Listesi",
    myList: "Listem",
    continueToWatch: "İzlemeye Devam Et",
    recomendedByTT: "Tivibu'nun Önerdikleri",
    youMaybeLike: "Sizin İçin Seçtiklerimiz",
    watchItAgain: "Yeniden İzle",
    myTvodPurchase: "Kiraladıklarım",
    myEstPurchase: "Satın Aldıklarım",
    newReleased: "Yeni Eklenenler",
    haveSeenAll: "Hepsini gördünüz",
    records: "Tüm Kayıtlarım",
    toBeRecord: "Kaydedilecek",
    recording: "Kaydediliyor",
    recordDeleted: "Kayıt Silindi",
    deleteRecordConfirmText: "Kaydı silmek istediğinize emin misiniz?",
    recordAllSerie: "Bu bölümü ve sonra yayınlanacak tüm bölümleri kaydet",
    recordThisEpisode: "Yalnız bu bölümü kaydet",
    deleteRecordEpisode: "Bu bölümün kaydını sil",
    deleteAllSerie: "Bu dizinin tüm kaydını sil",
    profilePincode: "{profileName} profili için şifre girin.",
    error: {
      tvSubscription: "Bu kanal paketinizde bulunmamaktadır"
    },
    watchNowText: "Bu içeriği hemen izlemek ister misiniz?",
    watchNow: "Hemen İzle",
    watchLater: "Sonra İzle",
    parentalControlText: {
      general: "Genel İzleyici Kitlesi",
      sevenPlus: "7 Yaş ve Üzeri için",
      thirteenPlus: "13 Yaş ve Üzeri için",
      allAge: "18 Yaş ve Üzeri için",
      changeText:
        "Bu profilin ebeveyn kontrolünü değiştirmek istediğinize emin misiniz?"
    },
    categories: "Kategoriler",
    kidsProfile: "Çocuk Profili",
    ppvText: "{price}‘ye {content} içeriğini {action} onaylıyor musunuz?",
    npvrQuota: "Kaydedilebilecek Toplam Süre",
    hour: "saat",
    minutes: "dakika",
    lastWatchedChannels: "Son İzlediğim Kanallar",
    purchaseFilter: "Son {count} {format}",
    live: "canlı",
    searchFilters: {
      all: "Tümü",
      movie: "Film",
      series: "Dizi",
      program: "Programlar",
      cuTv: "Tekrar İzle",
      tvChannel: "TV Kanalları"
    },
    searchResult: "Arama Sonuçları",
    createProfilePinCodeText: "Önce profil şifresi oluşturmalısın",
    createAdminProfilePinCodeText: "Önce ana profil şifresi oluşturmalısın",
    createProfilePinCode: "Şifre Oluştur",
    lockedChannel: "Kanal kilitli",
    loginText: "Bu içeriği izleyebilmek için giriş yapmalısınız.",
    outOfProduct:
      "Seçtiğiniz içerik paketinizde yer almamaktadır. Paketlerinize giderek istediğiniz içeriklerin bulunduğu diğer paketlerimize göz atabilirsiniz.",
    cancelPaymentExpired:
      "Ödeme iptali gerçekleştirilemedi. Kiralama iptal süresi dolmuştur.",
    askCancelPayment: "Ödemeyi iptal etmek istediğinize emin misiniz?",
    close: "Kapat",
    continue: "Devam Et",
    next: "Sonraki",
    startOver: "Baştan Başla",
    bookmarkText: "Devam Etmek İster Misin?",
    child: "Çocuk",
    normalProfile: "Normal Profil",
    blockAutoDelete: "Otomatik silmeyi engelle",
    allowAutoDelete: "Otomatik silmeye izin ver",
    npvrHourLimitText:
      "Kayıt listesine çok fazla içerik eklendi. Toplam kayıt süresi {hour} saati aşmaktadır. Devam etmek ister misiniz?",
    npvrEpisodeLimitText:
      "Kayıt listesine çok fazla içerik eklendi. Bölüm sayısı fazladır. Devam etmek ister misiniz?",
    disableButtonParentalText: "Ebeveyn kontrolün izin vermiyor.",
    vodLoginText: "Bu işlemi yapabilmeniz için giriş yapmalısınız",
    wrongPinText: "Profil şifresi doğru değil. Lütfen tekrar deneyiniz.",
    mesMessage: {
      suspend:
        "Hesabınız askıya alındı. Hesabınızdan otomatik çıkış yapılacaktır",
      removedProfile:
        "Bu profil kaldırıldığından tekrardan profil seçme ekranına yönlendirileceksiniz",
      orderPackage:
        "Paketiniz değiştiğinden otomatik olarak çıkış yapılacaktır.",
      recoverPackage: "Your product is recovered",
      deviceQuota:
        "Yeni bir cihazla giriş yapıldı. Otomatik çıkış yapılacaktır.",
      userOnlineQuery:
        "Bu hesapla başka bir aktif oturum bulunmaktadır. Devam etmek isterseniz diğer kullanıcının oturumu sonlanacaktır."
    },
    audioOriginal: "Orijinal",
    bundleContentText: "{contentName} serisini {action} istiyor musunuz?",
    incompatibleBrowser: "Tarayıcınız tivibu uygulamasıyla uyumlu değildir.",
    shouldLogin: "Giriş yapmanız gerekir",
    turkish: "Türkçe",
    english: "İngilizce",
    tvodSaveTime: "Bu programın kaydı bulunmamaktadır.",
    descriptive: "Betimlemeli",
    returnCode: "Hata Kodu",
    message: "Mesaj",
    shortHour: "sa",
    shortMinutes: "dk",
    searchChannel: "Kanal Ara",
    tvSearchResultText: "{term} ile ilgili {count} kanal sonucu bulundu.",
    tvSearchNoResultText: "{term} ile ilgili kanal sonucu bulunamadı.",
    info: "Bilgiler",
    releaseDate: "Yayın Tarihi",
    recorded: "Kaydedildi",
    recordSaved: "Kayıt Edilenler",
    seeAll: "Tümünü Gör",
    goLive: "canlıya dön",
    inspect: "İncele",
    inMyList: "Listemde",
    shareNow: "Hemen Paylaş",
    play: "Oynat",
    duration: "Süre",
    genre: "Tür",
    preview: "Ön İzleme",
    profileManagement: "Profil Yönetimi",
    closeSession: "Oturumu Kapat",
    profileLoginText: "Giriş yapmak istediğiniz profili seçin",
    editProfile: "Profili Düzenle",
    enterProfilePassword: "Profil Şifrenizi Giriniz",
    profileName: "Profil Adı",
    optional: "opsiyonel",
    again: "tekrar",
    saveProfile: "Profili Kaydet",
    passwordsMatch: "Şifreler aynı değil",
    watch: "İzle",
    playSpeed: "Oynatma Hızı",
    contentError:
      "İçerik bilgilerini alırken bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
    removeProfilePassword: "Profil Şifresini Kaldır",
    removeProfilePasswordText:
      "Profil şifresini kaldırmak istediğinize emin misiniz?",
    noSearchHistory: "Arama geçmişiniz bulunmamaktadır.",
    month: "ay",
    lockedChannels: "Kilitli Kanallar",
    noChannelFound: "Uyguladığınız filtreler ile ilgili kanal bulunamadı.",
    noRecords: "Kayıt bulunmamaktadır.",
    typeMessage: "Mesajınızı yazınız",
    suggestion: "Önerim Var",
    complaint: "Şikayetim Var",
    feedbackResponse:
      "Talebiniz bize ulaşmıştır. Geri bildiriminiz için teşekkür ederiz.",
    feedbackResponseError:
      "Talebinizi alamadık. Lütfen daha sonra tekrar deneyiniz.",
    askProfilePin: "Profil şifrenizi giriniz.",
    askAdminPin: "Ana profil şifrenizi giriniz.",
    searchSuggestions: "Önerilenler",
    noSearchResult: "{term} aramanız herhangi bir sonuç getirmedi.",
    sugg1: "Farklı anahtar sözcükler deneyin",
    sugg2: "Bir filmi veya diziyi mi arıyorsunuz?",
    sugg3: "Film, dizi, program, kanal, oyuncu veya yönetmen adıyla deneyin",
    sugg4: "Komedi, aşk, spor veya drama gibi bir tür deneyin",
    record: "Kayıt",
    errorCode: "Hata kodu",
    errorMessage: "Mesaj",
    channelDrmErrorMessage:
      "Kanal oynatılamamaktadır. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    vodDrmErrorMessage:
      "İçerik oynatılamamaktadır. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    noNetworkMessage:
      "İnternet bağlantılarınızı kontrol ettikten sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    invalidParamError:
      "İstenen işlem tamamlanamadı. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz.  Problemin devam etmesi halinde 444 5 375'i arayınız.",
    contentNotFoundError:
      "Kanal/İçerik oynatılamamaktadır. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    manifestChannelError:
      "Kanal oynatılamamaktadır. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    manifestVodError:
      "İçerik oynatılamamaktadır. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    videoFormatErrorChannel:
      "Kanal oynatılamamaktadır. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    videoFormatErrorVod:
      "İçerik oynatılamamaktadır. Uygulamanızı kapatıp açtıktan sonra tekrar deneyiniz. Sorunun devam etmesi halinde müşteri hizmetlerinden destek alabilirsiniz. (444 5 375)",
    recordOption: "Bu içeriği nasıl kaydetmek istersiniz?",
    deleteRecordOption: "Hangi silme seçeneğini kullanmak istersiniz?",
    profileNameWarning: "Profil ismi girmelisin",
    autoBitrate: "Otomatik",
    searchSuggestText: "Yıl, Yönetmen, Oyuncu...",
    informText:
      "Değerli Kullanıcımız, Tivibu GO hizmetiniz, Türk Telekom Grup şirketi olan Net Ekran 11 Televizyon ve Medya Hiz. AŞ. tarafından sunulmaktadır. Üyeliğiniz devam ettiği sürece, abonesi olduğunuz paket haklarınızı aynen koruyarak hizmet almaya devam edebilirsiniz.",
    channelCoverage: "Paketiniz bu kanalı kapsamamaktadır.",
    installationProcess: " Paketimi nasıl yükseltirim?",
    contentControl: "Bu programda içerik kontrolü var.",
    programMonitoring: "Programı nasıl izlerim?",
    channelLocking: "Bu kanalı kilitlediniz.",
    grooving: "Kilitli kanalı nasıl açarım?",
    packageInstall: {
      packageChange:
        "Bu Kanalın dahil olduğu paketlerimiz ile ilgili bilgi almak ve paket değişikliği talebinde bulunmak için {phone} numaralı müşteri hizmetlerimiz ile iletişime geçebilir ya da {watchChannel} yazıp {number}'ya SMS gönderebilirsiniz.",
      watchChannel: "KANAL İZLE",
      number: "6606",
      phone: "444 5 375",
      control:
        "{adjustment} menüsü altındaki {parentalControl} listesini güncelleyerek bu programı izleyebilirsiniz.",
      adjustment: "Ayarlar",
      parentalControl: "Ebeveyn Kontrolü",
      channelLock:
        "Kanal kilidini, {adjustment} menüsü altındaki {lockChannel} listesinden kaldırabilirsiniz.",
      lockChannel: "Kanal Kilitleme"
    },
    forceLogoutMessage:
      "Aynı anda yalnızca bir cihazdan giriş yapılabilmektedir. Farklı bir cihazdan giriş yapıldığından dolayı oturumunuz sonlandırılmıştır.",
    mainLoadingText: "Yükleniyor. Lütfen bekleyiniz...",
    emptyMessageText: "Bu alanı boş bırakmayınız.",
    rebuildSessionText:
      "Oturumunuz zaman aşımına uğramıştır, tekrar giriş yapmanız gerekmektedir.",
    playerOptions: {
      track: "Altyazı/Ses",
      quality: "Kalite",
      record: "Kayıt",
      info: "Program Bilgisi",
      secsToBack: "15 sn geri al",
      sectsToForward: "15 saniye ileri al",
      play: "Oynat",
      pause: "Durdur",
      audioOff: "Sesi Kapat",
      audioOn: "Sesi Aç",
      restart: "Baştan Başlat",
      offFullscreen: "Tam Ekrandan Çık",
      onFullscreen: "Tam Ekran"
    }
  }
});

let lang = localStorage.getItem("language");
if (!lang) {
  lang = DEFAULT_LANGUAGE;
  localStorage.setItem("language", DEFAULT_LANGUAGE);
  localizationStrings.setLanguage(DEFAULT_LANGUAGE);
}else{
  localStorage.setItem("language", lang);
  localizationStrings.setLanguage(lang);
}

export default localizationStrings;
