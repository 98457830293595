import axios from "axios";
import { apiEndPointConstant } from "../../statics/constants";
import { axiosInstance } from "../config/configApi";

const getLoginToken = async (userName, password) => {
  return await axiosInstance.post(
    apiEndPointConstant.authClient + "/auth/login",
    {
      username: userName,
      password: password
    }
  );
};

const sendMePassword = async (phoneNumber) => {
  return await axios.post("/messageEndpoint", {
    params: {
      phoneNumber: phoneNumber
    }
  });
};

export { getLoginToken, sendMePassword };
