import React, { useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import WatchTvIcon from "../../assets/icons/svg/WatchTvIcon";
import { SessionContext } from "../../context/SessionContext";
import localizationStrings from "../../statics/localization";

const IconRender = (props) => {
  const ref = useRef(null);
  const { selectedMenuId, path, setShowLoginMessage, selectedChannel } =
    useContext(SessionContext);
  const history = useHistory();
  const onClickMenu = () => {
    if (
      props.text === localizationStrings.specialForMe ||
      props.text === localizationStrings.series
    ) {
      if (localStorage.getItem("usertype") === "0") {
        // let lang = localStorage.getItem("language");
        // let deviceid = localStorage.getItem("deviceid");
        // let softLoginUrl = localStorage.getItem("loginurl");
        // let loginUrl = `${softLoginUrl}?lan=${
        //   lang ? lang : "tur"
        // }&deviceid=${deviceid}`;
        // window.location.replace(loginUrl);
        setShowLoginMessage(true);
        return;
      }
    }

    let url =
      props.link !== "livetv"
        ? `/dashboard/home/${props.link}/all`
        : `/dashboard/${props.link}/${
            selectedChannel ? selectedChannel?.id : ""
          }`;

    history.push(url);
  };

  return (
    <a
      style={{ textDecoration: "none" }}
      className={`menu-item ${
        props.link !== "livetv"
          ? selectedMenuId == props.link
            ? "is-active"
            : ""
          : path?.includes("/dashboard/live")
          ? "is-active"
          : ""
      }`}
      onClick={() => onClickMenu()}
    >
      <div className="category centered" ref={ref}>
        {props.link === "livetv" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {path?.includes("/dashboard/live") ? (
              <div className="watch-tv-selected">
                {localizationStrings.watchTv}
              </div>
            ) : (
              <WatchTvIcon text={localizationStrings.watchTv} />
            )}
          </div>
        ) : (
          <label>{props.text}</label>
        )}
      </div>
    </a>
  );
};

export default IconRender;
