import React from "react";

const MyRecordsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="44.707"
      viewBox="0 0 48 44.707"
    >
      <g id="Group_800" data-name="Group 800" transform="translate(-1086 -399)">
        <path
          id="Stroke_1"
          data-name="Stroke 1"
          d="M0,1V33a1,1,0,0,0,1,1H45a1,1,0,0,0,1-1V1a1,1,0,0,0-1-1H1A1,1,0,0,0,0,1Z"
          transform="translate(1087 408.707)"
          fill="none"
          stroke="#fff"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Stroke_3"
          data-name="Stroke 3"
          d="M0,0,6,6l6-6"
          transform="translate(1104 399.707)"
          fill="none"
          stroke="#fff"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Fill_7"
          data-name="Fill 7"
          d="M6.432,3.216A3.216,3.216,0,1,1,3.216,0,3.216,3.216,0,0,1,6.432,3.216Z"
          transform="translate(1107.5 421)"
          fill="#fff"
        />
        <path
          id="Fill_9"
          data-name="Fill 9"
          d="M9.5,19A9.5,9.5,0,1,1,19,9.5,9.511,9.511,0,0,1,9.5,19Zm0-17.713A8.212,8.212,0,1,0,17.712,9.5,8.22,8.22,0,0,0,9.5,1.287Z"
          transform="translate(1101 415)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default MyRecordsIcon;
