import React from "react";

const SubtitleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 30.702 30.702"
    >
      <g
        id="Group_1329"
        data-name="Group 1329"
        transform="translate(-1723 -1121)"
      >
        <g
          id="Group_1247"
          data-name="Group 1247"
          transform="translate(650.363 496)"
        >
          <path
            id="Line_90"
            data-name="Line 90"
            d="M2.706.492H-.659A.929.929,0,0,1-1.5-.5a.929.929,0,0,1,.841-1H2.706a.929.929,0,0,1,.841,1A.929.929,0,0,1,2.706.492Z"
            transform="translate(1078.822 637.696)"
            fill="#fff"
          />
          <path
            id="Line_93"
            data-name="Line 93"
            d="M2.706.592H-.659A.961.961,0,0,1-1.5-.454.961.961,0,0,1-.659-1.5H2.706A.961.961,0,0,1,3.547-.454.961.961,0,0,1,2.706.592Z"
            transform="translate(1093.404 642.212)"
            fill="#fff"
          />
          <path
            id="Line_91"
            data-name="Line 91"
            d="M10.7.492H-.659A.929.929,0,0,1-1.5-.5a.929.929,0,0,1,.841-1H10.7a.929.929,0,0,1,.841,1A.929.929,0,0,1,10.7.492Z"
            transform="translate(1085.414 637.696)"
            fill="#fff"
          />
          <path
            id="Line_92"
            data-name="Line 92"
            d="M10.7.592H-.659A.961.961,0,0,1-1.5-.454.961.961,0,0,1-.659-1.5H10.7a.961.961,0,0,1,.841,1.046A.961.961,0,0,1,10.7.592Z"
            transform="translate(1078.822 642.212)"
            fill="#fff"
          />
        </g>
        <g
          id="Rectangle_932"
          data-name="Rectangle 932"
          transform="translate(1723 1121)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        >
          <rect width="1em" height="1em" rx="5" stroke="none" />
          <rect x="1" y="1" width="27" height="27" rx="4" fill="none" />
        </g>
      </g>
    </svg>
  );
};

export default SubtitleIcon;
