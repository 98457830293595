import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import ShowPasswordIcon from "../../assets/icons/svg/ShowPasswordIcon";
import HidePasswordIcon from "../../assets/icons/svg/HidePasswordIcon";
import localizationStrings from "../../statics/localization";
import "./LoginPage.scss";
import LoginWrapper from "../loginWrapper";
import { getLoginToken } from "../../api/login/loginApi";
import { SessionContext } from "../../context/SessionContext";

let errorMessage =
  "H-402 Tivibu ürününüz bulunmamaktadır. Üyeliğiniz ile ilgili sorunlar için 444 5 375 numaralı Çağrı Merkezimiz ile iletişime geçebilirsiniz.";

const LoginPage = () => {
  const [isShownPassword, setIsShownPassword] = useState(false);
  const [notSubMessage, setNotSubMessage] = useState(null);
  const history = useHistory();
  const { setHasLoggedIn } = useContext(SessionContext);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const {
    onePasswordLogin,
    mobilePhoneNumber,
    password,
    rememberMe,
    forgotPassword,
    changePassword
  } = localizationStrings.login;

  const { emptyMessageText } = localizationStrings;

  const handleLogin = async (loginData) => {
    const { data } = await getLoginToken(
      loginData.userName,
      loginData.password
    );

    if (!!data && data?.token) {
      setHasLoggedIn(true);
      localStorage.setItem("userToken", data.token);
      localStorage.setItem("hasLoggedIn", true);
      history.push("/selectprofile");
    }
  };

  const handleError = (errors) => {};

  const handleClickChange = () => {
    window.open(
      "https://teksifre.turktelekom.com.tr/kullanici-giris/sso-giris?bmctx=96703605727DD648953DAFF3520DB8DEE37B490F44F558382E1B33734CAAEC72&password=secure_string&contextType=external&username=string&challenge_url=https%3A%2F%2Fteksifre.turktelekom.com.tr%2Fkullanici-giris%2Fsso-giris&request_id=-7201795736024367508&authn_try_count=0&locale=tr_TR&resource_url=https%253A%252F%252Fteksifre.turktelekom.com.tr%252Fkullanici-islemleri%252Fsifre-degistirme-giris"
    );
  };

  const handleTogglePassword = () => {
    setIsShownPassword((prevState) => !prevState);
  };

  const registerOptions = {
    userName: {
      required: `${emptyMessageText}`,
      minLength: {
        value: 1,
        message: "Password must have at least 8 characters"
      }
    },
    password: {
      required: `${emptyMessageText}`,
      minLength: {
        value: 1,
        message: "Password must have at least 8 characters"
      }
    }
  };

  return (
    <LoginWrapper>
      <p className="login-title">{onePasswordLogin}</p>
      <input
        className="input-number"
        name="userName"
        placeholder={`${mobilePhoneNumber}`}
        {...register("userName", registerOptions.userName)}
        style={
          errors.userName || notSubMessage ? { border: "2px solid red" } : {}
        }
      />
      <div className="input-error">
        <p> {errors?.userName && errors.userName.message}</p>
      </div>

      <div className="password-section">
        <input
          className="input-password"
          name="password"
          type={isShownPassword ? "text" : "password"}
          placeholder={`${password}`}
          {...register("password", registerOptions.password)}
          style={
            errors.password || notSubMessage ? { border: "2px solid red" } : {}
          }
        />

        <span className="eye-icon" onClick={handleTogglePassword}>
          {isShownPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
        </span>
      </div>

      <div className="input-error">
        <p>{errors?.password && errors.password.message}</p>
        {notSubMessage && <p>{notSubMessage}</p>}
      </div>

      <div className="sub-section">
        <div className="input-checkbox">
          <input type="checkbox" id="remember-me" name="remember-me" />
          <label htmlFor="remember-me">{rememberMe}</label>
        </div>

        <Link to="/forgot-password">{forgotPassword}</Link>
      </div>

      <button
        onClick={handleSubmit(handleLogin, handleError)}
        className="continue-button"
      >
        {localizationStrings.continue}
      </button>
      {/* <button onClick={handleClickChange} className="change-password-button">
        {changePassword}
      </button> */}
    </LoginWrapper>
  );
};

export default LoginPage;
