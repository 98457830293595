import React from "react";

const ParentalControlIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="41"
        viewBox="0 0 48 41"
      >
        <g
          id="Group_802"
          data-name="Group 802"
          transform="translate(-483 -713)"
        >
          <path
            id="Stroke_1"
            data-name="Stroke 1"
            d="M4,10A3.89,3.89,0,0,0,8,6V4A3.891,3.891,0,0,0,4,0,3.891,3.891,0,0,0,0,4V6A3.887,3.887,0,0,0,4,10Z"
            transform="translate(489 714)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Stroke_3"
            data-name="Stroke 3"
            d="M14,27V4h6c3.018,0,4-1.781,4-4H7C2.252,0,0,1.512,0,6v6a4.086,4.086,0,0,0,4,4"
            transform="translate(484 727.003)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Stroke_5"
            data-name="Stroke 5"
            d="M.5,0V13"
            transform="translate(492.5 741.003)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Stroke_7"
            data-name="Stroke 7"
            d="M.5,0V21"
            transform="translate(487.5 733.003)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Stroke_9"
            data-name="Stroke 9"
            d="M0,5V0H28V24H0V15"
            transform="translate(502 719.003)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Stroke_11"
            data-name="Stroke 11"
            d="M0,0V10L8.26,5Z"
            transform="translate(513 725.781)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </g>
      </svg>
    </div>
  );
};

export default ParentalControlIcon;
