import React from "react";
import { useHistory } from "react-router-dom";

import PlayerExitIcon from "../../assets/icons/svg/PlayerExitIcon";
import logo from "../../assets/logo/Logo.png";
import LoginBackground from "../../assets/images/loginBackground";
import localizationStrings from "../../statics/localization";
import "./LoginWrapper.scss";

const LoginWrapper = ({ children, hideSignup }) => {
  const { goBack } = useHistory();

  const { notYetSub, signUp } = localizationStrings.login;

  const handleClickSignup = () => {
    window.open("https://odeme.tivibu.com.tr/");
  };

  return (
    <section className="login-page-container">
      <LoginBackground />
      <span onClick={goBack} className="go-back-icon">
        <PlayerExitIcon />
      </span>
      <div className="login-content">
        <img
          className="atlas-logo"
          src={logo}
          alt="atlas-logo"
          style={{ minHeight: "100px", minWidth: "100px" }}
        />
        {children}
        {/* {!hideSignup && (
          <>
            <div className="login-line" />
            <p className="article-2">{notYetSub}</p>
            <button onClick={handleClickSignup} className="sign-up-button">
              {signUp}
            </button>
          </>
        )} */}
      </div>
    </section>
  );
};

export default LoginWrapper;
