import React from "react";

const YoutubeFooterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 53.3 53.301"
    >
      <g id="youtube" transform="translate(0.65 0.65)" opacity="0.5">
        <circle
          id="base"
          cx="26"
          cy="26"
          r="26"
          transform="translate(0 0)"
          fill="none"
          stroke="#6e8496"
          strokeWidth="1.3"
        />
        <path
          id="Shape"
          d="M17.215,14.56H3.583A3.292,3.292,0,0,1,.448,12.773,4.481,4.481,0,0,1,0,10.986V3.573A3.279,3.279,0,0,1,1.791.447,4.512,4.512,0,0,1,3.583,0H17.215a3.294,3.294,0,0,1,3.137,1.786A4.477,4.477,0,0,1,20.8,3.573v7.413a3.28,3.28,0,0,1-1.792,3.127A4.515,4.515,0,0,1,17.215,14.56ZM7.628,3.3v7.974L14.438,7.29Z"
          transform="translate(15.6 18.2)"
          fill="#6e8496"
        />
      </g>
    </svg>
  );
};

export default YoutubeFooterIcon;
