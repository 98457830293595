import React, { useRef, useState } from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { changePlayerGradient } from "../../../statics/config";
import localizationStrings from "../../../statics/localization";
import useOutsideClick from "../../../utils/useOutsideClick";

const PlaybackRate = ({ player, isFullScreen }) => {
  const [rate, setRate] = useState(1);
  const rateEl = useRef(null);
  const rateButtonRef = useRef(null);
  const itemRef = useRef(null);
  const isOpen = useRef(false);
  const controlBarElRef = useRef(null);

  useOutsideClick(rateEl, () => {
    removeOptions();
  });

  const removeOptions = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById(`vod-player-rate`));
    rateEl.current.remove();
    rateEl.current = null;
    changePlayerGradient(false);
  };

  const createOptions = () => {
    rateEl.current = document.createElement("div");
    rateEl.current.classList.add("items-wrapper");
    rateEl.current.setAttribute("id", `vod-player-rate`);
    controlBarElRef.current.insertBefore(
      rateEl.current,
      controlBarElRef.current.childNodes[0]
    );
    changePlayerGradient(true);
    calcRightPositionOfEl();
  };

  const changePlaybackRate = (newRate) => {
    if (rate != newRate) {
      setRate(newRate);
      player.playbackRate(newRate);
    }
  };

  const calcRightPositionOfEl = () => {
    let playerPosition = player.el().getBoundingClientRect();
    let playerRightOffset =
      window.innerWidth - playerPosition.left - playerPosition.width;
    let buttonPosition = rateButtonRef.current.getBoundingClientRect();
    let buttonRightOffset =
      window.innerWidth - buttonPosition.left - buttonPosition.width;
    let rightPosition = buttonRightOffset - playerRightOffset;
    renderQualityBox(rightPosition);
  };

  const renderQualityBox = (rightPosition) => {
    ReactDOM.render(
      <div
        ref={itemRef}
        className="box"
        style={{
          width: "150px",
          marginRight: `calc(${rightPosition}px - 16em)`
        }}
      >
        <div className="player-option-item">
          <label className="player-option-label title">
            {localizationStrings.playSpeed}
          </label>
        </div>
        <div
          className="player-option-item"
          onClick={() => changePlaybackRate(0.5)}
        >
          <label
            className={`player-option-label ${rate === 0.5 ? "selected" : ""} `}
          >
            0.5 x
          </label>
        </div>
        <div
          className="player-option-item"
          onClick={() => changePlaybackRate(1)}
        >
          <label
            className={`player-option-label ${rate === 1 ? "selected" : ""} `}
          >
            1 x
          </label>
        </div>
        <div
          className="player-option-item"
          onClick={() => changePlaybackRate(1.5)}
        >
          <label
            className={`player-option-label ${rate === 1.5 ? "selected" : ""} `}
          >
            1.5 x
          </label>
        </div>
        <div
          className="player-option-item"
          onClick={() => changePlaybackRate(2)}
        >
          <label
            className={`player-option-label ${rate === 2 ? "selected" : ""} `}
          >
            2 x
          </label>
        </div>
      </div>,
      document.getElementById(`vod-player-rate`)
    );
  };

  useEffect(() => {
    if (rate) {
      rateEl.current && calcRightPositionOfEl();
    }
  }, [rate]);

  useEffect(() => {
    if (player) {
      controlBarElRef.current = player.getChild("controlBar").el();
    }
  }, [player]);

  return (
    <button
      ref={rateButtonRef}
      className="control-button playback-rate-button"
      style={{ marginLeft: isFullScreen ? "1.2vw" : null }}
      onClick={() => {
        if (!isOpen.current) {
          createOptions();
        }
        isOpen.current = !isOpen.current;
      }}
    >
      {rate} x
    </button>
  );
};

export default PlaybackRate;
