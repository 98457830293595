import React from "react";

const favAndLockChannel = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="45.707"
      viewBox="0 0 48 45.707"
    >
      <g id="Group_798" data-name="Group 798" transform="translate(-484 -406)">
        <path
          id="Stroke_1"
          data-name="Stroke 1"
          d="M0,6V5A5,5,0,0,1,10,5V33h1l9-6,9,6h1V5a5,5,0,0,0-5-5H5"
          transform="translate(496 407)"
          fill="none"
          stroke="#fff"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Stroke_3"
          data-name="Stroke 3"
          d="M41,0h5V29H0V0H21"
          transform="translate(485 415)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Stroke_5"
          data-name="Stroke 5"
          d="M0,2,2,0H21l2,2"
          transform="translate(497 449)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Stroke_7"
          data-name="Stroke 7"
          d="M.5,0V3"
          transform="translate(504.5 446)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Stroke_9"
          data-name="Stroke 9"
          d="M.5,0V3"
          transform="translate(511.5 446)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Stroke_11"
          data-name="Stroke 11"
          d="M6.38,0,8.271,4.276l4.489.359L9.44,7.827l.883,4.254L6.38,10.016,2.437,12.067,3.32,7.83,0,4.635l4.489-.359Z"
          transform="translate(509.619 417.365)"
          fill="none"
          stroke="#fff"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default favAndLockChannel;
