import React from "react";

const BackSecondsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2em"
      height="1.2em"
      viewBox="0 0 29.822 30.971"
    >
      <g id="Group_8" data-name="Group 8" transform="translate(29.822)">
        <path
          id="Fill_6"
          data-name="Fill 6"
          d="M-27.61,20.681a13.21,13.21,0,0,0,12.246,8A13.224,13.224,0,0,0-2.294,15.522,13.206,13.206,0,0,0-15.485,2.294,13.1,13.1,0,0,0-24.72,6.067l1.6,1.6c.277.277.184.534-.205.571l-5.009.481a.566.566,0,0,1-.64-.64l.481-5.009c.037-.39.294-.482.571-.205l1.582,1.582A15.381,15.381,0,0,1-15.485,0,15.384,15.384,0,0,1-4.536,4.536,15.384,15.384,0,0,1,0,15.485,15.466,15.466,0,0,1-4.493,26.392a15.391,15.391,0,0,1-11.3,4.575,15.5,15.5,0,0,1-10.66-4.554,15.464,15.464,0,0,1-3.274-4.837,1.148,1.148,0,0,1,.486-1.443,1.153,1.153,0,0,1,1.635.547"
          transform="translate(0 0)"
          fill="#fff"
        />
        <text
          id="Time"
          transform="translate(-21.97 19.295)"
          fill="#fff"
          fontSize="10"
          fontFamily="SegoeUI, Segoe UI"
        >
          <tspan x="0" y="0">
            15
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default BackSecondsIcon;
