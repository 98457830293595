import React from "react";
import { programs } from "../../../api/mockup/mockup";
import ForwardSecondsIcon from "../../../assets/icons/svg/ForwardSecondsIcon";
import localizationStrings from "../../../statics/localization";

const RewindForwardButton = ({ player, isFullscreen }) => {
  let secsToBack = 15;

  return (
    <button
      className="control-button"
      style={{ marginLeft: isFullscreen ? "1.2vw" : null }}
      title={localizationStrings.playerOptions.sectsToForward}
      onClick={() => {
        player.currentTime(player.currentTime() + secsToBack);
      }}
    >
      <ForwardSecondsIcon />
    </button>
  );
};

export default RewindForwardButton;
