import React from "react";

const CheckIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.5"
        height="14.541"
        viewBox="0 0 19.5 14.541"
      >
        <path
          id="Icon_awesome-check"
          data-name="Icon awesome-check"
          d="M6.623,18.833.286,12.5a.975.975,0,0,1,0-1.379L1.664,9.738a.975.975,0,0,1,1.379,0l4.269,4.269,9.144-9.144a.975.975,0,0,1,1.379,0l1.379,1.379a.975.975,0,0,1,0,1.379L8,18.833A.975.975,0,0,1,6.623,18.833Z"
          transform="translate(0 -4.577)"
          fill="#fff"
        />
      </svg>
    </div>
  );
};

export default CheckIcon;
