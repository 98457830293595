import React, { useEffect, useState } from "react";
import ToBeReacordIcon from "../../assets/icons/svg/ToBeReacordIcon";
import localizationStrings from "../../statics/localization";
import TrashIcon from "../../assets/icons/svg/TrashIcon";
import Notification from "../notification";
import { removeRecord } from "../../api/npvr/npvr";
import SinglePlayIcon from "../../assets/icons/svg/SinglePlayIcon";
import defaultHorizontalPoster from "../../assets/images/default-vod-horizontal-poster.png";
import { prettyTimeFormatter } from "../../utils/prettyTimeFormatter";

const UserRecordItem = ({
  item,
  setDeletedRecordCode,
  clickRecord,
  isEpisode,
  selectedFilter,
  setPopupOpened
}) => {
  const [posterUrl, setPosterUrl] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const deleteRecord = async () => {
    let isDeletedAll = true;
    if (isEpisode) isDeletedAll = false;

    const data = await removeRecord(item?.epgProgramId, isDeletedAll);
    if (data.status === 200) {
      setDeletedRecordCode(item?.epgProgramId);
    }

    setShowNotification(false);
    setPopupOpened(false);
  };

  useEffect(() => {
    if (item) {
      let poster = item?.posters?.find(
        (poster) => poster.posterType === "HorizontalLow"
      );

      setPosterUrl(poster?.posterUrl);
    }
  }, [item]);

  const formatReleaseDate = (releaseDate) => {
    const date = new Date(releaseDate);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;

    return formattedDate;
  };

  return (
    <div className="record-card">
      <div
        className="record-poster-wrapper"
        onClick={() => {
          clickRecord(item);
        }}
      >
        <img
          src={posterUrl}
          onError={() => {
            setPosterUrl(defaultHorizontalPoster);
          }}
        />
        {item?.newstatus === "1" && (isEpisode || item?.isseries !== "1") ? (
          <div className="record-gradient">
            <div className="record-play-button">
              <SinglePlayIcon />
            </div>
          </div>
        ) : null}
      </div>

      <div className="info-box">
        <div
          className="row"
          style={{ marginTop: "0.5vw", marginBottom: ".26vw" }}
        >
          <label
            className="title"
            onClick={() => {
              if (!item || (item.newstatus && item.newstatus !== "1")) return;
              clickRecord(item);
            }}
          >
            {item?.title}{" "}
            {isEpisode && item && formatReleaseDate(item?.releaseDate)}
          </label>
        </div>
        <div className="row">
          {!item?.category ? (
            <div className="info">
              <label>{item?.genre}</label>
              <div className="date">
                {item?.isSerie
                  ? localizationStrings.series
                  : prettyTimeFormatter(item?.duration)}
              </div>
            </div>
          ) : (
            <div className="info">
              <div className="date">
                {item?.isSerie
                  ? localizationStrings.series
                  : prettyTimeFormatter(item?.duration)}
              </div>
            </div>
          )}
        </div>

        {/* staticly added will be recorded label for recording items */}

        {selectedFilter === 2 && (
          <div className="row">
            <div className="status blue">
              <span style={{ marginTop: ".104vw" }}>
                <ToBeReacordIcon />
              </span>
              <label className="label">
                {localizationStrings.toBeRecorded}
              </label>
            </div>
          </div>
        )}

        {/*    <div className="row">
          {item?.newstatus === "999" ? (
            <div className="status red">
              <span style={{ marginTop: ".208vw" }}>
                <ToBeReacordIcon />
              </span>
              <label className="label">{localizationStrings.recording}</label>
            </div>
          ) : item?.newstatus === "0" ? (
            <div className="status blue">
              <span style={{ marginTop: ".104vw" }}>
                <ToBeReacordIcon />
              </span>
              <label className="label">
                {localizationStrings.toBeRecorded}
              </label>
            </div>
          ) : null}
        </div> */}
      </div>

      <div className="delete">
        <div
          className="delete-button"
          onClick={() => {
            setShowNotification(true);
            setPopupOpened(true);
          }}
        >
          <TrashIcon />
        </div>
      </div>

      {showNotification ? (
        <Notification
          text={localizationStrings.deleteRecordConfirmText}
          confirmText={localizationStrings.yes}
          notConfirmText={localizationStrings.cancel}
          onClickConfirm={deleteRecord}
          onClickNotConfirm={() => {
            setShowNotification(false);
            setPopupOpened(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default UserRecordItem;
