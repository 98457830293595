import React from "react";

const DoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Group_3"
      data-name="Group 3"
      width="108"
      height="81"
      viewBox="0 0 108 81"
    >
      <path
        id="Fill_1"
        data-name="Fill 1"
        d="M83.4,4.283l-42,42.271L24.6,29.647a14.314,14.314,0,0,0-20.429,0,14.756,14.756,0,0,0,0,20.563L31.184,77.4c4.769,4.8,15.668,4.8,20.433,0L103.832,24.85a14.769,14.769,0,0,0,0-20.567,14.326,14.326,0,0,0-20.433,0"
        fill="#d7df23"
      />
    </svg>
  );
};

export default DoneIcon;
