import React from "react";

const ForwardSecondsIcon = () => {
  return (
    <svg
      id="Group_8"
      data-name="Group 8"
      xmlns="http://www.w3.org/2000/svg"
      width="1.2em"
      height="1.2em"
      viewBox="0 0 29.822 30.971"
    >
      <path
        id="Fill_6"
        data-name="Fill 6"
        d="M27.61,20.681A13.192,13.192,0,1,1,24.72,6.067l-1.6,1.6c-.277.277-.184.534.205.571l5.009.481a.566.566,0,0,0,.64-.64L28.5,3.069c-.037-.39-.294-.482-.571-.205L26.342,4.445A15.485,15.485,0,0,0,4.493,26.392a15.5,15.5,0,0,0,25.238-4.816,1.151,1.151,0,0,0-2.121-.9"
        transform="translate(0 0)"
        fill="#fff"
      />
      <text
        id="Time"
        transform="translate(8.45 19.295)"
        fill="#fff"
        fontSize="10.14"
        fontFamily="SegoeUI, Segoe UI"
      >
        <tspan x="0" y="0">
          15
        </tspan>
      </text>
    </svg>
  );
};

export default ForwardSecondsIcon;
