import React from "react";

const RtukGeneralIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
    >
      <path
        id="Fill_1"
        data-name="Fill 1"
        d="M16,32A16,16,0,0,1,4.686,4.686,16,16,0,1,1,27.314,27.314,15.9,15.9,0,0,1,16,32ZM16,2.85A13.151,13.151,0,0,0,5.4,23.777V22.461a2.82,2.82,0,0,1,1.718-2.725,2.313,2.313,0,1,1,1.472.015A2.739,2.739,0,0,1,10.194,21.8a4.5,4.5,0,0,1,2.712-4.042,2.884,2.884,0,1,1,2.2-.013A4.087,4.087,0,0,1,17.4,19.933a4.948,4.948,0,0,1,3.729-4.185,3.572,3.572,0,1,1,1.72-.008,5.02,5.02,0,0,1,3.835,5.048v2.887A13.15,13.15,0,0,0,16,2.85Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RtukGeneralIcon;
