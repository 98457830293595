import React from "react";

const ChannelLocking = () => {
  return (
    <div>
      <svg
        id="Group_7"
        data-name="Group 7"
        xmlns="http://www.w3.org/2000/svg"
        width="1.976vw"
        height="2.678vw"
        viewBox="0 0 37.989 51.68"
      >
        <path
          id="Fill_1"
          data-name="Fill 1"
          d="M18.966,32.358A18.968,18.968,0,0,1,.416,17.3a17.247,17.247,0,0,1-.409-3.713C0,9.724,0,5.8,0,2V1.945A1.741,1.741,0,0,1,1.952,0C4.539,0,12.523,0,35.871,0c1.5,0,2.115.618,2.115,2.132V5.391c0,2.662,0,5.416,0,8.147A19.016,19.016,0,0,1,23.636,31.8,19.961,19.961,0,0,1,18.966,32.358Zm.043-24.53a3.6,3.6,0,0,0-2.6,1.1A3.514,3.514,0,0,0,15.4,11.476a3.661,3.661,0,0,0,1.193,2.6.793.793,0,0,1,.284.77c-.157,1.216-.309,2.451-.456,3.646q-.178,1.446-.359,2.892a1.424,1.424,0,0,0,.232,1.15,1.349,1.349,0,0,0,1.056.365h3.387a1.246,1.246,0,0,0,.977-.347,1.333,1.333,0,0,0,.231-1.078q-.137-1.1-.27-2.2c-.176-1.439-.358-2.927-.552-4.4a.844.844,0,0,1,.3-.818A3.671,3.671,0,0,0,22.6,11.442a3.572,3.572,0,0,0-1.07-2.577A3.52,3.52,0,0,0,19.009,7.828Z"
          transform="translate(0 19.322)"
          fill="#fff"
        />
        <path
          id="Fill_4"
          data-name="Fill 4"
          d="M.007,14.152A13.839,13.839,0,0,1,.5,9.278a13.924,13.924,0,0,1,6.1-7.116A14.427,14.427,0,0,1,25.177,5.073a13.868,13.868,0,0,1,3.379,9.1c.009.93.008,1.879.008,2.792,0,.363-.11.482-.474.478-1.524-.015-3.049-.019-4.573,0-.4.006-.522-.128-.515-.506,0-.906.008-1.782,0-2.673a8.713,8.713,0,0,0-17.413-.462c-.057,1.027-.049,2.058-.039,3.087,0,.4-.094.561-.526.554-1.5-.023-3.01-.019-4.514,0-.385,0-.514-.133-.514-.5,0-.935.007-1.861.007-2.792"
          transform="translate(4.71 0)"
          fill="#fff"
        />
      </svg>
    </div>
  );
};

export default ChannelLocking;
