import axios from "axios";

const sendCustomerMessage = async (body, message) => {
  let apiEndpoint =
    "https://ott2.mvp.tivibu.com.tr/emw-edge/SubscriberPetition";

  return await axios.post(apiEndpoint, body, {
    params: {
      message: message
    }
  });
};

export { sendCustomerMessage };
