import React from "react";

const VersionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="39.707"
      viewBox="0 0 48 39.707"
    >
      <g id="Group_804" data-name="Group 804" transform="translate(-1085 -696)">
        <path
          id="Stroke_1"
          data-name="Stroke 1"
          d="M0,1V28a1,1,0,0,0,1,1H45a1,1,0,0,0,1-1V1a1,1,0,0,0-1-1H1A1,1,0,0,0,0,1Z"
          transform="translate(1086 705.707)"
          fill="none"
          stroke="#fff"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Stroke_3"
          data-name="Stroke 3"
          d="M0,0,6,6l6-6"
          transform="translate(1103 696.707)"
          fill="none"
          stroke="#fff"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          id="Fill_5"
          data-name="Fill 5"
          d="M3.211,0H8.4a3.294,3.294,0,0,1,3.211,2.6A7.335,7.335,0,0,1,5.8,5.438,7.34,7.34,0,0,1,0,2.6,3.294,3.294,0,0,1,3.211,0"
          transform="translate(1103.195 722.693)"
          fill="#fff"
        />
        <path
          id="Fill_7"
          data-name="Fill 7"
          d="M3.216,0A3.216,3.216,0,1,1,0,3.216,3.216,3.216,0,0,1,3.216,0"
          transform="translate(1105.783 715.401)"
          fill="#fff"
        />
        <path
          id="Fill_9"
          data-name="Fill 9"
          d="M9.5,19A9.5,9.5,0,1,1,19,9.5,9.511,9.511,0,0,1,9.5,19Zm0-17.713A8.212,8.212,0,1,0,17.712,9.5,8.22,8.22,0,0,0,9.5,1.287Z"
          transform="translate(1099.5 711.28)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default VersionIcon;
