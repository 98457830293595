import React, { useContext, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SessionContext } from "../../context/SessionContext";
import MiniProgramDetail from "../miniProgramDetail/MiniProgramDetail";
import MiniVodDetail from "../miniVodDetail/MiniVodDetail";
import ModalPortal from "../modalPortal";
import ProgramDetail from "../programDetail";
import RecordItem from "../recordItem/RecordItem";
import VodItem from "../vodItem";
import { ChannelCarouselItem } from "../channelCarousel/ChannelCarousel";

const VodGrid = ({
  items,
  fromSearch,
  isFromVodList,
  itemsToShow,
  posterPosition,
  posterPath
}) => {
  const [showModal, setShowModal] = useState(false);
  const [prevueCode, setPrevueCode] = useState(null);
  const [showMiniVodDetail, setShowMiniVodDetail] = useState(false);
  const [showMiniProgramDetail, setShowMiniProgramDetail] = useState(false);
  const { setSelectedCutvProgram, selectedChannel, userFrameConfig, userInfo } =
    useContext(SessionContext);
  const location = useLocation();
  const previewData = useRef({});
  const isFirstRow = useRef(false);
  const isRight = useRef(false);
  const isLeft = useRef(false);
  const history = useHistory();
  const programPreviewData = useRef({});

  const openProgramModal = (programItem) => {
    setPrevueCode(programItem?.prevuecode);
    setShowModal(true);
  };

  const renderedItems = items?.map((item, index) => {
    /* console.log({ item }); */
    return item?.type === "MOVIE" || item?.type === "SERIES" ? (
      <VodItem
        key={item?.id}
        item={item}
        useSubType={!isFromVodList}
        fromSearch={fromSearch}
        itemsToShow={posterPosition == 7 ? 5 : 6}
        posterPath={posterPath}
        posterPosition={posterPosition}
        onHoverItem={(isShow, data) => {
          if (isShow) {
            previewData.current = data;
            isFirstRow.current =
              (posterPosition == 7 && index < 5) ||
              ((posterPosition == 6 || posterPosition == 1) && index < 6);
            isRight.current =
              (posterPosition == 7 && !((index + 1) % 5)) ||
              ((posterPosition == 6 || posterPosition == 1) &&
                !((index + 1) % 6));
            isLeft.current =
              (posterPosition == 7 && !(index % 5)) ||
              ((posterPosition == 6 || posterPosition == 1) && !(index % 6));
          }
          setShowMiniVodDetail(isShow);
        }}
      />
    ) : item?.contenttype === "1" || item?.contenttype === "3" ? (
      <RecordItem
        key={item?.id}
        item={item}
        onSelect={openProgramModal}
        fromSearch={true}
        onHoverProgramItem={(isShow, data) => {
          if (isShow) {
            programPreviewData.current = data;
          }
          setShowMiniProgramDetail(isShow);
        }}
      />
    ) : item?.type === "CHANNEL" ? (
      <ChannelCarouselItem key={item?.id} item={item} fromSearch={true} />
    ) : null;
  });

  return (
    <div className="content-grid-wrapper">
      <div id="search-grid" className={`content-grid`}>
        {renderedItems}
        {showModal ? (
          <ModalPortal viewClass="vod-modal">
            <ProgramDetail
              prevueCode={prevueCode}
              closeModal={() => setShowModal(false)}
              fromVodGrid={true}
              setSelectedCutvProgram={setSelectedCutvProgram}
              selectedChannel={selectedChannel}
              userFrameConfig={userFrameConfig}
              userInfo={userInfo}
              location={location}
              history={history}
            />
          </ModalPortal>
        ) : null}
      </div>
      {showMiniVodDetail ? (
        <MiniVodDetail
          vodData={previewData.current}
          closeModal={() => {
            setShowMiniVodDetail(false);
          }}
          isFirstRow={isFirstRow.current}
          isRight={isRight.current}
          isLeft={isLeft.current}
        />
      ) : null}
      {/* {showMiniProgramDetail ? (
        <MiniProgramDetail
          programData={programPreviewData.current}
          closeModal={() => {
            setShowMiniProgramDetail(false);
          }}
          onSelectRecord={(item, isPlay) => {
            let obj = {};
            obj.record = item;
            obj.isPlay = isPlay;
            console.log("isPlay: ", isPlay);
            // setSelectedRecord(obj);
          }}
        />
      ) : null} */}
    </div>
  );
};

export default VodGrid;
