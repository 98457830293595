import React, { useState, useRef, useEffect } from "react";
import Chatbot, { createChatBotMessage } from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";
import "./ChatBotComponent.scss";
import UserChatMessageComponent from "./UserChatMessage";
const botName = "Atlas Compass";

const config = {
  botName: botName,
  runInitialMessagesWithHistory: true,
  initialMessages: [
    createChatBotMessage(`Merhaba ben ${botName}`),
    createChatBotMessage("Size yardımcı olmak için buradayım", {
      withAvatar: true,
      delay: 500,
      widget: "overview"
    })
  ],
  customComponents: {
    botAvatar: (props) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512"
        className="appChatbotButtonIcon_Lq7z"
        height={25}
        width={25}
        style={{ marginRight: "10px" }}
        fill="#0c1927"
        {...props}
      >
        <path d="M192 408h64v-48h-64zm384-216h-32a96 96 0 00-96-96H344V24a24 24 0 00-48 0v72H192a96 96 0 00-96 96H64a48 48 0 00-48 48v128a48 48 0 0048 48h32a96 96 0 0096 96h256a96 96 0 0096-96h32a48 48 0 0048-48V240a48 48 0 00-48-48zM96 368H64V240h32zm400 48a48.14 48.14 0 01-48 48H192a48.14 48.14 0 01-48-48V192a48 48 0 0148-48h256a48 48 0 0148 48zm80-48h-32V240h32zM240 208a48 48 0 1048 48 47.996 47.996 0 00-48-48zm160 0a48 48 0 1048 48 47.996 47.996 0 00-48-48zm-16 200h64v-48h-64zm-96 0h64v-48h-64z"></path>
      </svg>
    ),
    userAvatar: (props) => (
      <div className="react-chatbot-kit-user-avatar-container">
        <img
          width={40}
          height={40}
          alt="profile avatar"
          className="profile-logo"
          src="/profilePosters/blueshirtmale.png"
          {...props}
        />
      </div>
    ),
    userChatMessage: (props) => <UserChatMessageComponent {...props} />
  }
};

const ChatbotComponent = () => {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const chatWindowRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [message, setMessage] = useState("");

  const toggleChat = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsActive(true);
      setIsOpen(true);
    }
  };

  useEffect(() => {
    const handleAnimationEnd = () => {
      if (!isOpen) {
        setIsActive(false);
      }
    };

    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("animationend", handleAnimationEnd);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [isOpen]);

  const handleSendMessage = () => {
    if (message.trim()) {
      const messageParser = new MessageParser(
        new ActionProvider(createChatBotMessage, this.setState)
      );
      messageParser.parse(message);
      setMessage("");
    }
  };

  return (
    <div>
      {/* Chatbot aç/kapa butonu */}
      <button
        onClick={toggleChat}
        className={`chatbot-button ${isActive ? "active" : ""}`}
      >
        <svg
          viewBox="0 0 15 15"
          fill="none"
          width={25}
          height={25}
          xmlns="http://www.w3.org/2000/svg"
          className="w-[80%] h-[80%] fill-current"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.262 13.577a.094.094 0 0 1-.083-.047v-1.374A4.723 4.723 0 0 1 .802 9.377a4.706 4.706 0 0 1 .434-4.469A4.724 4.724 0 0 1 3.311 3.2a4.682 4.682 0 0 1 1.833-.369h4.712a4.69 4.69 0 0 1 2.634.805 4.705 4.705 0 0 1 1.273 6.542 4.723 4.723 0 0 1-2.073 1.706c-.58.245-1.204.37-1.834.37H6.49l1.808-1.822h1.52a2.85 2.85 0 0 0 0-5.7h-4.56a2.85 2.85 0 0 0 0 5.7h2.265L4.52 13.378l-.176.152a.091.091 0 0 1-.035.035.095.095 0 0 1-.047.012Z"
            fill="currentColor"
          ></path>
        </svg>
      </button>

      {/* Chatbot ekranı */}
      {isActive && (
        <div
          className={`chatbot-window ${isOpen ? "open" : "close"}`}
          ref={chatContainerRef} // Attach ref here
        >
          <div
            onClick={toggleChat}
            style={{
              backgroundColor: "#376b7e",
              padding: "10px",
              borderRadius: "5px 5px 0 0",
              color: "white",
              textAlign: "center",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <span>{botName}</span>
            <button
              onClick={toggleChat}
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.3)",
                border: "1px solid rgba(255, 255, 255, 0.5)",
                borderRadius: "50%",
                color: "white",
                fontSize: "18px",
                cursor: "pointer",
                width: "30px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <svg
                id="Actions_Navigation_close_24"
                data-name="Actions / Navigation / close / 24"
                xmlns="http://www.w3.org/2000/svg"
                width="13.863"
                height="13.863"
                viewBox="0 0 15.863 15.863"
              >
                <path
                  id="Fill"
                  d="M15.863,1.388,14.475,0,7.931,6.543,1.388,0,0,1.388,6.543,7.931,0,14.475l1.388,1.388L7.931,9.319l6.543,6.543,1.388-1.388L9.319,7.931Z"
                  fill="#fff"
                ></path>
              </svg>
            </button>
          </div>
          <div ref={chatWindowRef}>
            <Chatbot
              placeholderText="Merhaba, bir mesaj yazın..."
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatbotComponent;
