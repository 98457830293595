import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import "./ForgotPasswordPage.scss";
import localizationStrings from "../../statics/localization";
import LoginWrapper from "../loginWrapper";
import DoneIcon from "../../assets/icons/svg/DoneIcon";
import { sendMePassword } from "../../api/login/loginApi";

const ForgotPasswordPage = () => {
  const [isSumbitted, setIsSumbited] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { goBack } = useHistory();

  const {
    forgotPassword,
    enterTextPhone,
    mobilePhoneNumber,
    doneMessage,
    confirm,
    validNumberText
  } = localizationStrings.login;

  const { emptyMessageText } = localizationStrings;

  const handleRegistration = (data) => {
    const response = sendMePassword(data.phoneNumber);
    response && setIsSumbited((prevState) => !prevState);
  };

  const registerOptions = {
    required: `${emptyMessageText}`, // Error message for required field
    pattern: {
      value: /^\d{10}$/,
      message: `${validNumberText}` // Error message for incorrect format
    }
  };

  let content;

  if (isSumbitted) {
    content = (
      <div className="done-section">
        <DoneIcon />
        <p className="done-message">{doneMessage}</p>
        <button className="submit-button" onClick={goBack}>
          {confirm}
        </button>
      </div>
    );
  } else {
    content = (
      <>
        <p className="forgot-password-title">{forgotPassword}</p>
        <p className="forgot-password-subtitle">{enterTextPhone}</p>
        <Controller
          name="phoneNumber"
          control={control}
          defaultValue=""
          rules={registerOptions}
          render={({ field }) => (
            <input
              {...field}
              type="tel"
              className="input-phone-number"
              placeholder={`${mobilePhoneNumber}`}
              style={errors.phoneNumber ? { border: "2px solid red" } : {}}
            />
          )}
        />

        <div className="input-error">
          <p> {errors?.phoneNumber && errors.phoneNumber.message}</p>
        </div>
        <button
          onClick={handleSubmit(handleRegistration)}
          className="submit-button"
        >
          {localizationStrings.continue}
        </button>
      </>
    );
  }

  return (
    <LoginWrapper hideSignup={isSumbitted ? true : false}>
      {content}
    </LoginWrapper>
  );
};

export default ForgotPasswordPage;
