import React, { useContext, useEffect, useRef, useState } from "react";
import localizationStrings from "../../statics/localization";
import FavAndLockChannel from "../../assets/icons/svg/FavAndLockChannel.js";
import RentHistory from "../../assets/icons/svg/RentHistory.js";
import MyRecordsIcon from "../../assets/icons/svg/MyRecordsIcon.js";
import PacketIcon from "../../assets/icons/svg/PacketIcon.js";
import ParentalControlIcon from "../../assets/icons/svg/ParentalControlIcon.js";
import VersionIcon from "../../assets/icons/svg/VersionIcon.js";
import LanguageIcon from "../../assets/icons/svg/LanguageIcon.js";
import Feedback from "../../assets/icons/svg/Feedback.js";
import LogoutIcon from "../../assets/icons/svg/LogoutIcon.js";
import { SessionContext } from "../../context/SessionContext";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import ReturnIcon from "../../assets/icons/svg/ReturnIcon";
import Notification from "../notification";
import { setCookieApi } from "../../api/common/commonApi";
import {
  config,
  fastLoginSessionKillUrl,
  getColectAgent,
  getWs
} from "../../statics/config";
import { sendAliveReport } from "../../utils/reporting/customReporting";
import { reportingEvent } from "../../statics/reportEventId";
import moment from "moment";
import { callLogout } from "../../api/user/user";
import cookies from "universal-cookie";
import openKillSessionPopup from "../../utils/openKillSessionPopup";

const TivibuSettings = () => {
  const [height, setHeight] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const itemRef = useRef(null);
  const { userInfo, userFrameConfig, setPath, profile } =
    useContext(SessionContext);
  const history = useHistory();
  let { url } = useRouteMatch();

  const clickConfirm = () => {
    //onaylayı tıklayın
    if (isLogout) {
      removeCookieApi("upd", "");
    } else {
      cookies.remove(`${profile?.profilecode}-searchTermHistory`, {
        domain: config.cookieDomain
      });
    }
    setShowNotification(false);
  };

  const logout = async () => {
    //çıkış yapma
    const { data } = await callLogout(userInfo?.usercode);
    if (data.ReturnCode === "0") {
      let collectAgent = getColectAgent();
      if (collectAgent) {
        let eventtime = moment(
          moment().valueOf() - parseInt(localStorage.getItem("timeDiff"))
        )
          .utc()
          .format("yyyyMMDDHHmmss");

        collectAgent.logLogout({
          eventid: reportingEvent.E_LOGIN_OUT,
          eventtime: eventtime, //login triggering time (String UTC time, eg: "20210326101813"
          actiontime: eventtime,
          result: "0",
          logintype: 0 //login type 0: Account login, 1: Mobile login, 2: Mailbox login, 3: Loginname login (String, eg: "3."
        });
      }
      sendAliveReport(); //canlı raporu gönder
      let ws = getWs();
      if (ws?.readyState === WebSocket.OPEN) {
        let prams = {
          TransactionID: JSON.stringify(new Date().getTime()),
          DeviceID: localStorage.getItem("deviceid"),
          UserID: userInfo?.usercode,
          TerminalFlag: 4,
          UserToken: userInfo?.usertoken,
          DKey: 0,
          Timestamp: JSON.stringify(new Date().getTime()),
          MsgType: "terminaldelreq"
        };
        ws.send(JSON.stringify(prams));
      }

      if (parseInt(localStorage.getItem("logintype")) == 4) {
        localStorage.clear();
        openKillSessionPopup(userFrameConfig?.easHttpsDomain);
        //window.location.replace(fastLoginSessionKillUrl);
      } else {
        localStorage.clear();
        window.location.replace(userFrameConfig?.easHttpsDomain);
      }
    }
  };

  const removeCookieApi = async (key, value) => {
    //çerez apisini kaldırma
    const { data } = await setCookieApi(key, value);
    if (data.returncode === "0") {
      if (key === "upd") {
        removeCookieApi("uid", "");
      } else if (key === "uid") {
        logout();
      }
    }
  };

  const clickNotConfirm = () => {
    //onaylamayı tıklayın
    setShowNotification(false);
  };

  const clickedLogout = () => {
    //tıklandı çıkış
    setNotificationText(localizationStrings.logoutText);
    setIsLogout(true);
    setShowNotification(true);
  };

  const clickedDeleteSearchHistory = () => {
    //arama geçmişinde sili tıkladı
    setNotificationText(localizationStrings.deleteSearchHistoryText);
    setIsLogout(false);
    setShowNotification(true);
  };

  useEffect(() => {
    if (itemRef.current?.offsetWidth) {
      setPath(url);

      setHeight(itemRef.current.offsetWidth);
    }
  }, [itemRef.current]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="settings-container">
      <div>
        <div
          className="settings-icon"
          onClick={() => {
            history.push(`/dashboard/home`);
          }}
        >
          <ReturnIcon />
          <div className="icon-settings">{localizationStrings.settings}</div>
        </div>
      </div>

      <div className="settings-box-wrapper">
        <div className="row">
          <div
            ref={itemRef}
            className="settings-box"
            style={{ height: height + "px" }}
          >
            <NavLink
              style={{ textDecoration: "none" }}
              to={{
                pathname: `/dashboard/favlockchannel`
              }}
            >
              <div className="icon">
                <FavAndLockChannel />
              </div>
              <div className="settings-title">
                {localizationStrings.favlockChannel}
              </div>
            </NavLink>
          </div>

          <div
            ref={itemRef}
            className="settings-box"
            style={{ height: height + "px" }}
          >
            <NavLink
              style={{ textDecoration: "none" }}
              to={{
                pathname: `/dashboard/purchasehistory`
              }}
            >
              <div className="icon">
                <RentHistory />
              </div>
              <div className="settings-title">
                {localizationStrings.purchaseHistory}
              </div>
            </NavLink>
          </div>

          <div
            ref={itemRef}
            className="settings-box"
            style={{ height: height + "px" }}
          >
            <NavLink
              style={{ textDecoration: "none" }}
              to={{
                pathname: `/dashboard/records`
              }}
            >
              <div className="icon">
                <MyRecordsIcon />
              </div>
              <div className="settings-title">
                {localizationStrings.myRecords}
              </div>
            </NavLink>
          </div>

          {/* <div
            ref={itemRef}
            className="settings-box"
            style={{ height: height + "px" }}
          >
            <NavLink
              style={{ textDecoration: "none" }}
              to={{
                pathname: `/dashboard/package`
              }}
            >
              <div className="icon">
                <PacketIcon />
              </div>
              <div className="settings-title">
                {localizationStrings.packageOperations}
              </div>
            </NavLink>
          </div> */}

          <div
            ref={itemRef}
            className="settings-box"
            style={{ height: height + "px" }}
          >
            <NavLink
              style={{ textDecoration: "none" }}
              to={{
                pathname: `/dashboard/parentalcontrol`
              }}
            >
              <div className="icon">
                <ParentalControlIcon />
              </div>
              <div className="settings-title">
                {localizationStrings.parentalControl}
              </div>
            </NavLink>
          </div>
        </div>
        <div className="row">
          <div
            ref={itemRef}
            className="settings-box"
            style={{ height: height + "px" }}
          >
            <NavLink
              style={{ textDecoration: "none" }}
              to={{
                pathname: `/dashboard/version`
              }}
            >
              <div className="icon">
                <VersionIcon />
              </div>
              <div className="settings-title">
                {localizationStrings.versionAndUser}
              </div>
            </NavLink>
          </div>

          <div
            ref={itemRef}
            className="settings-box"
            style={{ height: height + "px" }}
          >
            <NavLink
              style={{ textDecoration: "none" }}
              to={{ pathname: `/dashboard/language` }}
            >
              <div className="icon">
                <LanguageIcon />
              </div>
              <div className="settings-title">
                {localizationStrings.menuLanguage}
              </div>
            </NavLink>
          </div>
          {/* <div
            ref={itemRef}
            className="settings-box"
            style={{ height: height + "px" }}
          >
            <NavLink
              style={{ textDecoration: "none" }}
              to={{ pathname: `/dashboard/suggestioncomplaint` }}
            >
              <div className="icon">
                <Feedback />
              </div>
              <div className="settings-title">
                {localizationStrings.suggestionComplaint}
              </div>
            </NavLink>
          </div> */}
        </div>

        {/* <div className="row-logout">
          <div
            className="red-button"
            style={{ marginTop: "0", marginBottom: "1.6vw" }}
            onClick={() => clickedLogout()}
          >
            {localizationStrings.logout2}
          </div>
          {showNotification ? (
            <Notification
              text={notificationText}
              confirmText={localizationStrings.yes}
              notConfirmText={localizationStrings.no}
              onClickConfirm={clickConfirm}
              onClickNotConfirm={clickNotConfirm}
            />
          ) : null}
        </div> */}
      </div>
    </div>
  );
};

export default TivibuSettings;
