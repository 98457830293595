import React, { useState } from "react";
import LikeIcon from "../../assets/icons/svg/LikeIcon";

const ProductPlacementItem = ({ product }) => {
  const [imageIndex, setImageIndex] = useState(0);
  console.log("product:", product);
  return (
    <div className="product-item">
      <div
        className="product-image"
        onClick={() => {
          if (product?.otherimages.length - 1 > imageIndex) {
            setImageIndex(imageIndex + 1);
          } else {
            setImageIndex(0);
          }
        }}
      >
        <img
          src={product?.otherimages[imageIndex]}
          alt={product?.productImageAltName}
        />
      </div>
      <div
        className="product-info-group"
        onClick={() => {
          window.open(product?.url);
        }}
      >
        <div className="product-info">
          <div className="product-name">{product.productName}</div>
          <div className="product-category">
            {product?.shoppingName} • {product?.productTitle} •{" "}
            {product?.categoryName}
          </div>
        </div>
        <div className="product-info">
          <div className="product-rating-price">
            <div className="product-rating">
              <LikeIcon /> {product?.ratingScore}/5
            </div>
            <div className="product-price">
              {/* Product Price */}
              <span>{product.sellingPrice} TL</span>
            </div>
          </div>

          {/* <div className="market-place">
      Market Place {product?.shoppingName}
    </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProductPlacementItem;
