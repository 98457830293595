import React, { useEffect, useRef, useState } from "react";
import QualityIcon from "../../../assets/icons/svg/QualityIcon";
import localizationStrings from "../../../statics/localization";
import ReactDOM from "react-dom";
import { setCookieApiCall } from "../../../api/config/configApi";
import {
  changePlayerGradient,
  getVideoProfile,
  setVideoProfile
} from "../../../statics/config";
import useOutsideClick from "../../../utils/useOutsideClick";
import { level } from "winston";

const QualityButton = ({
  player,
  liveui,
  onChangeQuality,
  levels,
  isFullscreen
}) => {
  const [qualityLevels, setQualityLevels] = useState([]);
  const [selectedQuality, setSelectedQuality] = useState({});
  const qualityButtonRef = useRef(null);
  const qualityEl = useRef(null);
  const itemRef = useRef(null);
  const isOpen = useRef(false);
  const controlBarElRef = useRef(null);
  let autoLevel = { qualityIndex: "auto", height: "auto" };

  useOutsideClick(itemRef, () => {
    removeOptions();
  });

  useEffect(() => {
    qualityEl.current && calcRightPositionOfEl();
  }, [selectedQuality]);

  useEffect(() => {
    if (levels?.length) {
      let userVideoProfile = getVideoProfile();
      let index;

      index = levels.findIndex((level) => level.height == userVideoProfile);

      if (index != -1) {
        setSelectedQuality(levels[index]);
        changeQuality(levels[index]);
      } else {
        setSelectedQuality(autoLevel);
        changeQuality(autoLevel);
      }
      if (levels?.length) {
        levels.sort((a, b) => b.bitrate - a.bitrate);
        let hdLevels = levels.filter((level) => level.height == 1080);
        if (hdLevels.length > 1) {
          hdLevels[0].height = "1080p high";
        }
      }

      setQualityLevels(levels);
    }
  }, [levels]);

  useEffect(() => {
    if (player) {
      controlBarElRef.current = player.getChild("controlBar").el();
    }
  }, [player]);

  const createOptions = () => {
    qualityEl.current = document.createElement("div");
    qualityEl.current.classList.add("items-wrapper");
    qualityEl.current.setAttribute("id", `vod-player-quality-${liveui}`);
    controlBarElRef.current.insertBefore(
      qualityEl.current,
      controlBarElRef.current.childNodes[0]
    );
    calcRightPositionOfEl();
    changePlayerGradient(true);
  };

  const calcRightPositionOfEl = () => {
    let playerPosition = player.el().getBoundingClientRect();
    let playerRightOffset =
      window.innerWidth - playerPosition.left - playerPosition.width;
    let buttonPosition = qualityButtonRef.current.getBoundingClientRect();
    let buttonRightOffset =
      window.innerWidth - buttonPosition.left - buttonPosition.width;
    let rightPosition = buttonRightOffset - playerRightOffset;
    renderQualityBox(rightPosition);
  };

  const changeQuality = (level) => {
    player.dash.mediaPlayer.updateSettings({
      streaming: {
        abr: {
          autoSwitchBitrate: { video: level.qualityIndex === "auto" }
        }
      }
    });

    if (level.qualityIndex !== "auto") {
      player.dash.mediaPlayer.setQualityFor("video", level.qualityIndex);
    }
    onChangeQuality();
    setSelectedQuality(level);
    setVideoProfile(level.height);
  };

  const renderQualityBox = (rightPosition) => {
    const renderedItems = qualityLevels?.map((level) => {
      return (
        <div
          key={level.qualityIndex}
          className="player-option-item"
          onClick={() => changeQuality(level, false)}
        >
          <label
            className={`player-option-label ${
              selectedQuality?.qualityIndex == level?.qualityIndex
                ? "selected"
                : ""
            } `}
          >
            {level.height}
            {level.height == "1080p high" ? "" : "p"}
          </label>
        </div>
      );
    });

    ReactDOM.render(
      <div
        ref={itemRef}
        className="box"
        style={{
          marginRight: isFullscreen
            ? `calc(${rightPosition - 35}px - 12em)`
            : `calc(${rightPosition - 35}px - 8em)`
        }}
      >
        <div className="player-option-item">
          <label className="player-option-label title">
            {localizationStrings.quality}
          </label>
        </div>
        <div
          key="auto"
          className="player-option-item"
          onClick={() => changeQuality(autoLevel)}
        >
          <label
            className={`player-option-label ${
              selectedQuality?.qualityIndex == "auto" ? "selected" : ""
            } `}
          >
            {localizationStrings.autoBitrate}
          </label>
        </div>
        {renderedItems}
      </div>,
      document.getElementById(`vod-player-quality-${liveui}`)
    );
  };

  const removeOptions = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById(`vod-player-quality-${liveui}`)
    );
    qualityEl.current.remove();
    qualityEl.current = null;
    changePlayerGradient(false);
  };

  return (
    <button
      ref={qualityButtonRef}
      id="quality-button"
      className="control-button"
      style={{
        //display: isFullscreen ? "flex" : "none"
        marginLeft: isFullscreen ? "1.2vw" : null
      }}
      title={localizationStrings.playerOptions.quality}
      onClick={() => {
        if (!isOpen.current) {
          createOptions();
        }
        isOpen.current = !isOpen.current;
      }}
    >
      <QualityIcon />
    </button>
  );
};

export default QualityButton;
