import React from "react";

const SelectedIcon = () => {
  return (
    <div>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 26 26"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          id="base"
          width="25"
          height="25"
          rx="12.5"
          transform="translate(0.5 0.5)"
          fill="none"
          stroke="#778da3"
          strokeWidth="1"
        />
        <rect
          id="select"
          width="15"
          height="15"
          rx="7.5"
          fill="#00deff"
          x="5.57"
          y="5.345"
        />
      </svg>
    </div>
  );
};

export default SelectedIcon;
