import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useLayoutEffect
} from "react";
import { SessionContext } from "../../context/SessionContext";
import {
  getLtvChannels,
  toggleFavById,
  getChannels,
  toggleLockById
} from "../../api/ltv/ltv";
import localizationStrings from "../../statics/localization";
import {
  addLockToChannel,
  deleteChannelLock
} from "../../api/common/commonApi";
import PasswordModal from "../passwordModal";
import { useHistory } from "react-router-dom";
import Notification from "../notification";
import { addFavorite, deleteFavorite } from "../../api/common/commonApi";
import { sendCustomFavoriteReport } from "../../utils/reporting/customReporting";
import ReturnIcon from "../../assets/icons/svg/ReturnIcon";
import { getColumnList } from "../../api/config/configApi";
import { channelGenres } from "../../api/mockup/mockup";
import TvFavoriteIcon from "../../assets/icons/svg/TvFavoriteIcon";
import TvLockIcon from "../../assets/icons/svg/TvLockIcon";
import BigLockIcon from "../../assets/icons/svg/BigLockIcon";
import useOutsideClick from "../../utils/useOutsideClick";

const LockedChannel = () => {
  const [channels, setChannels] = useState(null);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [askAdminPin, setAskAdminPin] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(3);
  const [genres, setGenres] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [showGenreOptions, setShowGenreOptions] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const allChannels = useRef(null);
  const option1Ref = useRef(null);
  const option2Ref = useRef(null);
  const {
    userFrameConfig,
    profile,
    path,
    adminProfile,
    setIsChangeLockChannel,
    isChangeLockChannel,
    isChildProfile
  } = useContext(SessionContext);
  const history = useHistory();

  useOutsideClick(option1Ref, () => {
    setShowGenreOptions(false);
  });

  useOutsideClick(option2Ref, () => {
    setShowFilterOptions(false);
  });

  const getChannelItems = async (columnCode) => {
    //kanal ögelerini al
    const data = await getChannels(columnCode, 1, 500);
    const channels = data.data;
    allChannels.current = data.data;
    setChannels(channels);
  };

  const getGenres = async () => {
    // const data = await getColumnList(userFrameConfig?.TV_Column);
    // if (data.data.returncode === "0") {
    //   let arr = data.data.data;
    setGenres(channelGenres);
    // let colCode = isChildProfile
    //   ? userFrameConfig?.ChildMode_TV_Column_All
    //   : userFrameConfig?.TV_Column_All;
    setSelectedGenre(channelGenres[0]);
  };
  useEffect(() => {
    getChannelItems();
  }, []);
  const clickConfirm = () => {
    setShowNotification(false);
    history.push(
      `/profile/${
        askAdminPin ? adminProfile?.profilecode : profile?.profilecode
      }?page=1`
    );
  };

  const clickNotConfirm = () => {
    setShowNotification(false);
    history.push(`${path}/settings/main`);
  };

  const filterChannel = (genre = selectedGenre, type = selectedFilter) => {
    let filtered = [];
    let genreFilter = [];

    setSelectedGenre(genre);
    setSelectedFilter(type);

    genreFilter = allChannels.current.filter(
      (item) => item.genre === genre.name
    );

    if (genreFilter.length === 0) {
      genreFilter = allChannels.current;
    }
    if (type === 1) {
      filtered = genreFilter.filter((item) => {
        return item.interactions.find(
          (interaction) => interaction === "CHANNEL_LOCK"
        );
      });
    } else if (type === 2) {
      filtered = genreFilter.filter((item) => {
        return item.interactions.find(
          (interaction) => interaction === "CHANNEL_FAV"
        );
      });
    } else if (type === 3) {
      filtered = genreFilter;
    } else {
      return;
    }
    setChannels(filtered);
  };

  useLayoutEffect(() => {
    if (isChildProfile) {
      let arr = [
        {
          columnname: localizationStrings.child,
          columncode: userFrameConfig?.ChildMode_TV_Column_All
        },
        {
          columncode: "fav-channel",
          columnname: localizationStrings.favChannel
        }
      ];
      setSelectedGenre();
      setGenres(arr);
    } else {
      getGenres();
    }
  }, [userFrameConfig]);

  useEffect(() => {
    if (userFrameConfig) {
      let columnCode = isChildProfile
        ? userFrameConfig?.ChildMode_TV_Column_All
        : userFrameConfig?.TV_Column_All;
      getChannelItems(columnCode);
    }
    return () => {
      if (path?.includes("/livetv")) {
        setIsChangeLockChannel(
          isChangeLockChannel ? (isChangeLockChannel === "1" ? "2" : "1") : "1"
        );
      }
    };
  }, [userFrameConfig]);

  useEffect(() => {
    if (userFrameConfig && profile && adminProfile) {
      if (profile?.profileroleid === userFrameConfig.Kids_Profile_Id) {
        setAskAdminPin(true);
        if (adminProfile.havepwd === "1") {
          setShowPasswordModal(true);
        } else {
          setShowNotification(true);
        }
      } else if (profile.havepwd === "1") {
        setShowPasswordModal(true);
      } else {
        setShowNotification(true);
      }
    }
  }, [profile, userFrameConfig, adminProfile]);

  const renderedItems = channels?.map((item, index) => {
    return (
      <LockChannelItem
        key={item.channelcode}
        item={item}
        index={index}
        channels={channels}
        selectedGenre={selectedGenre}
        setLockChannelCode={(channelId) => {
          if (channelId) {
            allChannels.current = allChannels.current.map((item) => {
              if (item.id === channelId) {
                if (item.interactions.includes("CHANNEL_LOCK")) {
                  return {
                    ...item,
                    interactions: item.interactions.filter(
                      (interaction) => interaction !== "CHANNEL_LOCK"
                    )
                  };
                } else {
                  return {
                    ...item,
                    interactions: [...item.interactions, "CHANNEL_LOCK"]
                  };
                }
              }
              return item;
            });
          }
        }}
        setFavChannelCode={(channelId) => {
          if (channelId) {
            allChannels.current = allChannels.current.map((item) => {
              if (item.id === channelId) {
                if (item.interactions.includes("CHANNEL_FAV")) {
                  return {
                    ...item,
                    interactions: item.interactions.filter(
                      (interaction) => interaction !== "CHANNEL_FAV"
                    )
                  };
                } else {
                  return {
                    ...item,
                    interactions: [...item.interactions, "CHANNEL_FAV"]
                  };
                }
              }
              return item;
            });
          }
        }}
      />
    );
  });

  return (
    <div className="settings-container">
      <div
        className="settings-icon"
        onClick={() => {
          history.push(`/dashboard/settings`);
        }}
      >
        <ReturnIcon />
        <label>{localizationStrings.favAndLock}</label>
      </div>
      <div className="select-box-row">
        <div
          ref={option1Ref}
          className={`settings-select-box ${showGenreOptions ? "opened" : ""}`}
          onClick={() => setShowGenreOptions(!showGenreOptions)}
        >
          <span>{selectedGenre?.name}</span>
          <div className="icon arrow-down"></div>
          <div
            className={`primary-select-box-options ${
              showGenreOptions ? "show" : ""
            }`}
            style={{ top: "3.5vw" }}
          >
            {genres?.map((item) => {
              return (
                <div
                  className="option"
                  onClick={() => {
                    setSelectedGenre(item);
                    filterChannel(item);
                  }}
                >
                  <div
                    className={`option-text ${
                      selectedGenre?.columncode === item.columncode
                        ? "selected"
                        : ""
                    }`}
                  >
                    {item?.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div
          ref={option2Ref}
          className={`settings-select-box ${showFilterOptions ? "opened" : ""}`}
          onClick={() => setShowFilterOptions(!showFilterOptions)}
        >
          <span>
            {selectedFilter === 1
              ? localizationStrings.lockedChannels
              : selectedFilter === 2
              ? localizationStrings.favChannel
              : localizationStrings.filter}
          </span>
          <div className="icon arrow-down"></div>
          <div
            className={`primary-select-box-options ${
              showFilterOptions ? "show" : ""
            }`}
            style={{ top: "3.5vw" }}
          >
            <div
              className="option"
              onClick={() => filterChannel(undefined, 1)} // 1 means locked channel
            >
              <div
                className={`option-text ${
                  selectedFilter === 1 ? "selected" : ""
                }`}
              >
                {localizationStrings.lockedChannels}
              </div>
            </div>
            <div
              className="option"
              onClick={() => filterChannel(undefined, 2)} // 2 means fav channels
            >
              <div
                className={`option-text ${
                  selectedFilter === 2 ? "selected" : ""
                }`}
              >
                {localizationStrings.favChannel}
              </div>
            </div>
            <div
              className="option"
              style={{
                borderRadius: "0 0 4px 4px"
              }}
              onClick={() => filterChannel(undefined, 3)} // 3 means all channels
            >
              <div
                className={`option-text ${
                  selectedFilter === 3 ? "selected" : ""
                }`}
              >
                {localizationStrings.searchFilters.all}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="list-history">
        {renderedItems}
        {channels !== null && !channels?.length ? (
          <div className="not-found">{localizationStrings.noChannelFound}</div>
        ) : null}
      </div>

      {showPasswordModal ? (
        <Notification
          text={
            askAdminPin
              ? localizationStrings.askAdminPin
              : localizationStrings.askProfilePin
          }
          confirmText={localizationStrings.enter}
          onClickConfirm={() => {}}
          onClickNotConfirm={() => {
            setShowPasswordModal(false);
            history.push(`/dashboard/settings`);
          }}
          showCloseButton={true}
          isPassword={true}
          icon={<BigLockIcon />}
          sendResult={(val) => {
            if (!val) {
              history.push(`${path}/settings/main`);
            }
            setShowPasswordModal(false);
          }}
          profileCode={
            askAdminPin ? adminProfile.profilecode : profile.profilecode
          }
        />
      ) : null}
      {showNotification ? (
        <Notification
          text={localizationStrings.createProfilePinCodeText}
          confirmText={localizationStrings.createProfilePinCode}
          notConfirmText={localizationStrings.cancel}
          onClickConfirm={clickConfirm}
          onClickNotConfirm={clickNotConfirm}
        />
      ) : null}
    </div>
  );
};

const LockChannelItem = ({
  item,
  channels,
  setChannels,
  selectedGenre,
  setLockChannelCode,
  setFavChannelCode
}) => {
  const [isLocked, setIsLocked] = useState(false);
  const [isFav, setIsFav] = useState(false);

  const handleToggleLock = async (item) => {
    const { data } = await toggleLockById(item.id);
    if (data) {
      setLockChannelCode(item.id);
    }
  };

  const handleToggleFav = async (item) => {
    const { data } = await toggleFavById(item.id);
    if (data) {
      setFavChannelCode(item.id);
    }
  };

  useEffect(() => {
    const isFavourite = item?.interactions.find(
      (element) => element === "CHANNEL_FAV"
    );

    const isLock = item?.interactions.find(
      (element) => element === "CHANNEL_LOCK"
    );

    setIsFav(!!isFavourite);
    setIsLocked(!!isLock);
  }, [item?.interactions]);

  return (
    <div className="favlockchannel-list-item" style={{ width: "100%" }}>
      <div className="favlockchannel-logo">
        <img className="logo" alt={item.channelname} src={`${item.logo}`} />
      </div>
      <div className="seperate"></div>
      <div className="favlockchannel-info">
        <div className="favlockchannel-name">{item?.name}</div>
      </div>
      <div className="icons">
        <div
          className={`icon ${isFav ? "fill-red" : ""}`}
          style={{}}
          onClick={() => {
            setIsFav(!isFav);
            handleToggleFav(item);
          }}
        >
          <TvFavoriteIcon />
        </div>

        <div
          className={`icon ${isLocked ? "fill-yellow" : ""}`}
          style={{ marginLeft: "15px", marginRight: "30.5px" }}
          onClick={() => {
            setIsLocked(!isLocked);
            handleToggleLock(item);
          }}
        >
          <TvLockIcon />
        </div>
      </div>
    </div>
  );
};

export default LockedChannel;
