import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SessionContext } from "../../context/SessionContext";
import { getIsFromPip, setIsFromPip } from "../../statics/config";
import MiniProgramDetail from "../miniProgramDetail/MiniProgramDetail";
import MiniVodDetail from "../miniVodDetail/MiniVodDetail";
import ModalPortal from "../modalPortal";
import ProgramDetail from "../programDetail";
import RecordItem from "../recordItem";
import VodItem from "../vodItem";

const ContentGrid = ({ items, posterPosition }) => {
  const [showMiniVodDetail, setShowMiniVodDetail] = useState(false);
  const [showMiniProgramDetail, setShowMiniProgramDetail] = useState(false);
  const [recordRefreshCounter, setRecordRefreshCounter] = useState(0);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedRecordItem, setSelectedRecordItem] = useState({});
  const [showModal, setShowModal] = useState(false);
  const { setSelectedCutvProgram, selectedChannel, userFrameConfig, userInfo } =
    useContext(SessionContext);
  const location = useLocation();
  const history = useHistory();
  const previewData = useRef({});
  const programPreviewData = useRef({});
  const isFirstRow = useRef(false);
  const isRight = useRef(false);
  const isLeft = useRef(false);

  let isFromPip = getIsFromPip();

  const openProgramModal = (programItem) => {
    setSelectedRecordItem(programItem);
    setShowModal(true);
  };

  useEffect(() => {
    if (selectedRecord) {
      setShowModal(true);
    }
    setSelectedRecordItem(selectedRecord?.record);
  }, [selectedRecord]);

  useEffect(() => {
    if (isFromPip) {
      let obj = {};
      obj.record = isFromPip;
      obj.isPlay = true;
      setSelectedRecord(obj);
      setIsFromPip(null);
    }
  }, [isFromPip]);

  const render = items.map((item, index) => {
    return item?.type === "RECORD" ? (
      <RecordItem
        key={item.prevuecode + item.seriesheadid}
        item={item}
        onSelect={openProgramModal}
        title={item.prevuename}
        onHoverProgramItem={(isShow, data) => {
          if (isShow) {
            programPreviewData.current = data;
          }
          setShowMiniProgramDetail(isShow);
        }}
      />
    ) : item?.type === "CHANNEL" ? (
      <div className="channel-carousel-item-box">
        <div className="channel-carousel-item" style={{ width: "auto" }}>
          <img src={item?.logo} style={{ width: "50%" }} />
        </div>
      </div>
    ) : (
      <VodItem
        key={item?.id}
        item={item}
        itemsToShow={posterPosition == 7 ? 5 : 6}
        posterPosition={posterPosition}
        onHoverItem={(isShow, data) => {
          if (isShow) {
            previewData.current = data;
            isFirstRow.current =
              (posterPosition == 7 && index < 5) ||
              ((posterPosition == 6 || posterPosition == 1) && index < 6);
            isRight.current =
              (posterPosition == 7 && !((index + 1) % 5)) ||
              ((posterPosition == 6 || posterPosition == 1) &&
                !((index + 1) % 6));
            isLeft.current =
              (posterPosition == 7 && !(index % 5)) ||
              ((posterPosition == 6 || posterPosition == 1) && !(index % 6));
          }
          setShowMiniVodDetail(isShow);
        }}
      />
    );
  });

  //item?.posterpath}/${item?.posterfilelist?.split(";")[6]
  return (
    <div className="content-grid-wrapper">
      <div className="content-grid">
        {render}
        {showModal ? (
          <ModalPortal viewClass="vod-modal">
            <ProgramDetail
              prevueCode={selectedRecordItem?.prevuecode}
              seriesHeadId={selectedRecordItem?.seriesheadid}
              closeModal={(val, isRefresh) => {
                if (val) {
                  setSelectedRecordItem(null);
                }
                setShowModal(false);
              }}
              isRecord={true}
              recordItem={selectedRecordItem}
              setSelectedCutvProgram={setSelectedCutvProgram}
              selectedChannel={selectedChannel}
              userFrameConfig={userFrameConfig}
              userInfo={userInfo}
              location={location}
              history={history}
              directlyPlay={selectedRecord?.isPlay}
            />
          </ModalPortal>
        ) : null}
      </div>
      {showMiniVodDetail ? (
        <MiniVodDetail
          vodData={previewData.current}
          closeModal={() => {
            setShowMiniVodDetail(false);
          }}
          isFirstRow={isFirstRow.current}
          isRight={isRight.current}
          isLeft={isLeft.current}
        />
      ) : null}
      {showMiniProgramDetail ? (
        <MiniProgramDetail
          programData={programPreviewData.current}
          closeModal={() => {
            setShowMiniProgramDetail(false);
          }}
          setRecordRefresh={(val) => {
            if (val) {
              let cnt = recordRefreshCounter;
              setRecordRefreshCounter(++cnt);
            }
          }}
          onSelectRecord={(item, isPlay) => {
            let obj = {};
            obj.record = item;
            obj.isPlay = isPlay;
            setSelectedRecord(obj);
          }}
        />
      ) : null}
    </div>
  );
};

export default ContentGrid;
