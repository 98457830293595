import React from "react";

const TvGuideIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      viewBox="0 0 6 10"
    >
      <path
        id="Fill"
        d="M6,5,.737,10,0,9.3,4.526,5,0,.7.737,0Z"
        transform="translate(6 10) rotate(180)"
        fill="currentColor"
      />
    </svg>
  );
};

export default TvGuideIcon;
