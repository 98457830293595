import { marked } from "marked";
import React, { useEffect } from "react";

const CustomChatMessage = ({ message }) => {
  const htmlContent = marked.parse(message);

  return (
    <div className="react-chatbot-kit-chat-bot-message-container">
      <div className="react-chatbot-kit-chat-bot-message">
        {/* Dangerous HTML olarak işlenmiş mesajı gösteriyoruz */}
        <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
        {/* <iframe srcDoc={htmlContent}></iframe> */}
      </div>
    </div>
  );
};

export default CustomChatMessage;
