import React, { useEffect, useRef, useState } from "react";

import TvFavoriteIcon from "../../assets/icons/svg/TvFavoriteIcon";
import TvLockIcon from "../../assets/icons/svg/TvLockIcon";
import { toggleFavById } from "../../api/ltv/ltv";

const ChannelListItem = ({
  item,
  selectedChannel,
  onSelectChannel,
  useFavoriteName,
  onClickLock,
  uKey,
  showList
}) => {
  const [isFav, setIsFav] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  const itemRef = useRef(null);

  useEffect(() => {
    const isFavourite = item?.interactions.find(
      (element) => element === "CHANNEL_FAV"
    );

    const isLock = item?.interactions.find(
      (element) => element === "CHANNEL_LOCK"
    );

    setIsFav(!!isFavourite);
    setIsLocked(!!isLock);
  }, [item?.interactions]);

  useEffect(() => {
    if (item && selectedChannel && selectedChannel?.id === item?.id) {
      document
        .getElementById(`vertical-channel-list-${uKey}`)
        .scrollTo(
          0,
          itemRef.current.offsetTop - itemRef.current.offsetHeight - 120
        );
    }
  }, [item, selectedChannel, showList]);

  return (
    <div
      className={`channel-list-item ${
        selectedChannel?.id === item?.id ? "selected" : ""
      }`}
      ref={itemRef}
      onClick={() => {
        onSelectChannel(item);
      }}
    >
      <div className="channel-logo">
        <img className="logo" alt={item?.name} src={item?.logo} />
      </div>
      <div className="channel-info">
        <div className="channel-name" title={item?.name}>
          {useFavoriteName ? item?.favoritename : item?.name}
        </div>
        <div className="program-name" title={item?.programName}>
          {item?.programName}
        </div>
      </div>
      <div className="icons">
        <div
          className={`icon ${isFav ? "fill-red" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
            setIsFav((prevState) => !prevState);
            toggleFavById(item?.id);
          }}
        >
          <TvFavoriteIcon />
        </div>
        <div
          className={`icon ${isLocked ? "fill-yellow" : ""}`}
          style={{ marginLeft: "0.5vw" }}
          onClick={(e) => {
            e.stopPropagation();
            onClickLock(item);
          }}
        >
          <TvLockIcon />
        </div>
      </div>
    </div>
  );
};

export default ChannelListItem;
