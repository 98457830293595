import React, { useContext, useRef, useState } from "react";
import localizationStrings from "../../statics/localization";
import { useHistory } from "react-router-dom";
import ReturnIcon from "../../assets/icons/svg/ReturnIcon";
import { browserName, fullBrowserVersion } from "react-device-detect";
import { appVersion } from "../../statics/config";
import moment from "moment";
import { SessionContext } from "../../context/SessionContext";
import { sendCustomerMessage } from "../../api/thirdParty/ttSubscriberApi";
import Notification from "../notification";
import useOutsideClick from "../../utils/useOutsideClick";

const SuggestionComplain = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(1);
  const [message, setMessage] = useState("");
  const { userInfo } = useContext(SessionContext);
  const option1Ref = useRef(null);
  const history = useHistory();

  useOutsideClick(option1Ref, () => {
    setShowFilter(false);
  });

  const sendMessage = async () => {
    let body = {
      brand: browserName,
      model: "Web Tarayıcı",
      osversion: fullBrowserVersion,
      appversion: appVersion,
      subscriber: window.EncryptUtils.dencryptStr(
        decodeURIComponent(userInfo.usercode)
      ),
      type: selectedFilter === 1 ? "ÖNERİ" : "ŞİKAYET",
      time: moment().format("DD.MM.yyyy HH:mm"),
      message: message
    };
    let message_ = "messagetext" + moment().format("DMyyyy");
    const { data } = await sendCustomerMessage(body, message_);
    if (data?.state === "OK") {
      setNotificationText(localizationStrings.feedbackResponse);
      setShowNotification(true);
    } else {
      setNotificationText(localizationStrings.feedbackResponseError);
      setShowNotification(true);
    }
  };

  return (
    <div className="settings-container">
      <div
        className="settings-icon"
        onClick={() => {
          history.push(`/dashboard/settings`);
        }}
      >
        <ReturnIcon />
        <label>{localizationStrings.suggestionComplaint}</label>
      </div>
      <div style={{ position: "relative" }}>
        <div
          ref={option1Ref}
          className={`settings-select-box ${showFilter ? "opened" : ""}`}
          onClick={() => setShowFilter(!showFilter)}
        >
          <span>
            {selectedFilter === 1
              ? localizationStrings.suggestion
              : selectedFilter === 2
              ? localizationStrings.complaint
              : null}
          </span>
          <div className="icon arrow-down"></div>
          <div
            className={`primary-select-box-options ${showFilter ? "show" : ""}`}
            style={{ top: "3.5vw" }}
          >
            <div
              className="option"
              onClick={() => {
                setSelectedFilter(1);
              }}
            >
              <div
                className={`option-text ${
                  selectedFilter === 1 ? "selected" : ""
                }`}
              >
                {localizationStrings.suggestion}
              </div>
            </div>
            <div
              className="option"
              onClick={() => {
                setSelectedFilter(2);
              }}
            >
              <div
                className={`option-text ${
                  selectedFilter === 2 ? "selected" : ""
                }`}
              >
                {localizationStrings.complaint}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="list-suggestion">
        <textarea
          className="message"
          type="text"
          placeholder={localizationStrings.typeMessage}
          style={{ color: "#FFFFFF" }}
          value={message}
          onInput={(e) => setMessage(e.target.value)}
        />
        <div
          className="yellow-button"
          style={{ width: "7.8vw" }}
          onClick={() => {
            if (message?.length) {
              sendMessage();
            } else {
              setNotificationText(localizationStrings.emptyMessageText);
              setShowNotification(true);
            }
          }}
        >
          {localizationStrings.send}
        </div>
      </div>
      {showNotification ? (
        <Notification
          text={notificationText}
          confirmText={localizationStrings.login.confirm}
          onClickConfirm={() => {
            setShowNotification(false);
            setMessage("");
          }}
        />
      ) : null}
    </div>
  );
};

export default SuggestionComplain;
