import React from "react";
import LiveIcon from "../../../assets/icons/svg/LiveIcon";
import localizationStrings from "../../../statics/localization";

const BackLiveButton = ({ setIsLiveEdge, isProgram }) => {
  return (
    <button
      className="control-button back-live"
      onClick={() => {
        if (isProgram) {
          setIsLiveEdge("2");
        } else {
          setIsLiveEdge("0");
        }
      }}
    >
      <div style={{ display: "flex", fontSize: "0.47vw" }}>
        <LiveIcon />
      </div>
      <span>{localizationStrings.goLive}</span>
    </button>
  );
};

export default BackLiveButton;
