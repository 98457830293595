import React from "react";

const SinglePlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="12"
      viewBox="0 0 11 12"
    >
      <path
        id="arrow"
        d="M10.074,7.589,2.751,11.756A1.839,1.839,0,0,1,0,10.166V1.833A1.839,1.839,0,0,1,2.751.244L10.074,4.41a1.827,1.827,0,0,1,0,3.179Z"
        fill="#0c1927"
      />
    </svg>
  );
};

export default SinglePlayIcon;
