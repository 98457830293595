import axios from "axios";

const postProductSearchApi = async (formData) => {
  const uninterceptedAxiosInstance = axios.create();
  return await uninterceptedAxiosInstance.post(
    `http://212.175.202.16/api/Product/SearchFile`,
    formData
  );
};

export { postProductSearchApi };
