import React from "react";

import CoveragePackageInstall from "../../assets/icons/svg/CoveragePackageInstall";

import localizationStrings from "../../statics/localization";
import Channellocking from "../../assets/icons/svg/Channellocking";

const ChannelLocking = () => {
  return (
    <div className="coverage">
      <div className="channel-coverage">
        {localizationStrings.channelLocking}
      </div>
      <div className="coverage-locking">
        <div className="locking">
          <Channellocking />
        </div>
      </div>

      <div className="package-install">
        <CoveragePackageInstall />
        &nbsp;
        <div style={{ marginBottom: "0.5vw" }}>
          {localizationStrings.grooving}
        </div>
      </div>
      <div className="package-locking">
        {localizationStrings.formatString(
          localizationStrings.packageInstall.channelLock,
          {
            adjustment: (
              <span style={{ color: "#00deff" }}>
                {localizationStrings.packageInstall.adjustment}
              </span>
            ),
            lockChannel: (
              <span style={{ color: "#00deff" }}>
                {localizationStrings.packageInstall.lockChannel}
              </span>
            )
          }
        )}
      </div>
    </div>
  );
};

export default ChannelLocking;
