import React from "react";

const PlayerRecordIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 30.702 30.702"
    >
      <g id="Group_2" data-name="Group 2" transform="translate(0.903 0.903)">
        <circle
          id="Oval"
          cx="14.448"
          cy="14.448"
          r="14.448"
          transform="translate(0 0)"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
          strokeWidth="1.806"
        />
        <circle
          id="Oval-2"
          data-name="Oval"
          cx="5.418"
          cy="5.418"
          r="5.418"
          transform="translate(9.03 9.03)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default PlayerRecordIcon;
