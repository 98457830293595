import React from "react";

const TvFavoriteIcon = () => {
  return (
    <svg
      className="channel-list-item-icons"
      xmlns="http://www.w3.org/2000/svg"
      //width="100%"
      //height="100%"
      viewBox="0 0 10.2 9"
    >
      <path
        id="icon"
        d="M8.71.366a2.831,2.831,0,0,0-3.608.7A2.856,2.856,0,0,0,2.88,0,2.826,2.826,0,0,0,1.49.366,2.979,2.979,0,0,0,.143,3.858C.637,6.133,5.1,9,5.1,9s4.46-2.867,4.955-5.142A2.978,2.978,0,0,0,8.71.366"
        fill="currentColor"
      />
    </svg>
  );
};

export default TvFavoriteIcon;
