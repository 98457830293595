import React from "react";

const LanguageIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="39.707"
        viewBox="0 0 48 39.707"
      >
        <g
          id="Group_805"
          data-name="Group 805"
          transform="translate(-1385 -706.264)"
        >
          <path
            id="Stroke_1"
            data-name="Stroke 1"
            d="M0,1V28a1,1,0,0,0,1,1H45a1,1,0,0,0,1-1V1a1,1,0,0,0-1-1H1A1,1,0,0,0,0,1Z"
            transform="translate(1386 715.971)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Stroke_3"
            data-name="Stroke 3"
            d="M0,0,6,6l6-6"
            transform="translate(1403 706.971)"
            fill="none"
            stroke="#fff"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            id="Fill_5"
            data-name="Fill 5"
            d="M1.869,14H0L4.756,0H6.937l4.779,14H9.782L8.287,9.6H3.323L1.869,14ZM5.774,1.6C5.517,2.623,5.3,3.4,5.068,4.134L3.7,8.184H7.913L6.544,4.156c-.292-.85-.491-1.628-.683-2.381L5.816,1.6Z"
            transform="translate(1403.934 723.884)"
            fill="#fff"
          />
        </g>
      </svg>
    </div>
  );
};

export default LanguageIcon;
