import React, { useEffect, useState } from "react";
import "./ChatBotComponent.scss";

const UserChatMessageComponent = ({ message }) => {
  const [messageSent, setMessageSent] = useState(false);
  const [messageReceived, setMessageReceived] = useState(false);

  console.log("Message", message);
  useEffect(() => {
    setMessageSent(true);
    setTimeout(() => {
      setMessageReceived(true);
    }, 2000);
  }, []);

  return (
    <div className="react-chatbot-kit-user-chat-message-container">
      <div className="react-chatbot-kit-user-chat-message">{message}</div>
      <div className="message-status">
        {messageSent && (
          <span
            className={`tick ${
              messageReceived ? "double-tick" : "single-tick"
            }`}
          >
            {messageReceived ? "✔✔" : "✔"}
          </span>
        )}
      </div>
    </div>
  );
};

export default UserChatMessageComponent;
