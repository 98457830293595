import { reportingEvent } from "../statics/reportEventId";
import {
  fullBrowserVersion,
  isChrome,
  isEdge,
  isFirefox,
  isOpera,
  isSafari
} from "react-device-detect";
import { setCollectAgent } from "../statics/config";
import moment from "moment";
import { sendAliveReport } from "./reporting/customReporting";

export const initPakoScript = (
  frameConfig,
  userInfo,
  profileCode,
  callback
) => {
  const script = document.createElement("script");
  script.src = `${localStorage.getItem(
    "interfaceurl"
  )}/jssdk/pako_deflate.min.js`;
  script.setAttribute("type", "text/javascript");
  document.getElementsByTagName("head")[0].appendChild(script);
  script.onload = () => {
    initCollectAgentScript(frameConfig, userInfo, profileCode, callback);
  };
};

const initCollectAgentScript = (
  frameConfig,
  userInfo,
  profileCode,
  callback
) => {
  const script = document.createElement("script");
  script.src = `${localStorage.getItem("interfaceurl")}/jssdk/collectAgent.js`;
  script.setAttribute("type", "text/javascript");
  document.getElementsByTagName("head")[0].appendChild(script);
  script.onload = () => {
    // var collectAgent = new CollectAgent();
    let collectAgent = new window.CollectAgent();
    setCollectAgent(collectAgent);
    collectAgent.setReportUrl(frameConfig.Collect_Agent_Url);
    collectAgent.setAnalyticsCollectionEnabled(
      frameConfig.Collect_Agent_Enable
    );
    collectAgent.setSessionTimeoutInterval(1);
    collectAgent.setSessionReportInternal(
      frameConfig.Collect_Agent_ReportingPeriod
    );
    collectAgent.setAliveReportInternal(
      frameConfig.Collect_Agent_ReportingAlivePeriod
    );
    collectAgent.setmediaRepTimeLimit(
      frameConfig.Collect_Agent_MediaRepTimeLimit
    );

    collectAgent.setmediaOnplayingReportingPeriod(15);

    let index = window.location.href.indexOf("frame");
    let frameCode;
    if (process.env.NODE_ENV === "production") {
      frameCode = window.location.href.slice(index, index + 9);
    } else {
      frameCode = "frame1102";
    }

    let _userInfo = {
      groupid: userInfo.teamid,
      userid: window.EncryptUtils.dencryptStr(
        decodeURIComponent(userInfo.usercode)
      ),
      profileid: profileCode,
      langtype: localStorage.getItem("language"),
      usertoken: userInfo.usertoken,
      framecode: frameCode,
      usertoken: userInfo.usertoken
    };

    collectAgent.setUserInfo(_userInfo);

    let terminalOsType = null;
    switch (true) {
      case isChrome:
        terminalOsType = "9";
        break;
      case isSafari:
        terminalOsType = "10";
        break;
      case isEdge:
        terminalOsType = "11";
        break;
      case isFirefox:
        terminalOsType = "12";
        break;
      case isOpera:
        terminalOsType = "13";
        break;
      default:
        terminalOsType = "0";

        break;
    }

    let deviceInfo = {
      terminaltype: 4,
      systemtype: terminalOsType,
      deviceid: localStorage.getItem("deviceid"),
      systemversion: fullBrowserVersion,
      modeltype: "Browser",
      ipaddr: userInfo.userip
    };

    let interfaceUrl = localStorage.getItem("interfaceurl");

    if (!!interfaceUrl) {
      let index = interfaceUrl.lastIndexOf("/");
      let interfaceFrameCode = interfaceUrl.slice(
        index + 1,
        interfaceUrl.length
      );
      collectAgent.setControlInterfaceCode(interfaceFrameCode);
    }

    collectAgent.setAddr(userInfo.postaddress, window.location.host);

    collectAgent.setDeviceInfo(deviceInfo);
    collectAgent.keyer();
    // collectAgent.onPlayingKeyer(); eureka said no need to call this.

    let eventtime = moment(
      moment().valueOf() - parseInt(localStorage.getItem("timeDiff"))
    )
      .utc()
      .format("yyyyMMDDHHmmss");

    collectAgent.logLogin({
      eventid: reportingEvent.E_LOGIN_IN,
      eventtime: eventtime, //login triggering time (String UTC time, eg: "20210326101813"
      actiontime: eventtime,
      result: "0",
      logintype: 0 //login type 0: Account login, 1: Mobile login, 2: Mailbox login, 3: Loginname login (String, eg: "3."
    });

    callback(true);
  };
};
