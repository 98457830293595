import React, { useState,  } from "react";
import localizationStrings from "../../statics/localization";
import Notification from "../notification";
import { setUserLanguage } from "../../api/user/user";
import { useHistory } from "react-router-dom";
import {
  setCookieApiCall,
  setUserLanguagePreferences
} from "../../api/config/configApi";
import CheckIcon from "../../assets/icons/svg/CheckIcon";
import ReturnIcon from "../../assets/icons/svg/ReturnIcon";

const Language = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [selectedLang, setSelectedLang] = useState({});

  let languages = [
    { code: "arb", title: "Arabic" },
    { code: "eng", title: "English" },
    { code: "tr", title: "Turkish" }
  ];
  let history = useHistory();

  const clickConfirm = async () => {
    const data = await setUserLanguage(null, null, selectedLang?.title);
    localizationStrings.setLanguage(selectedLang?.code);
    if (data) {
      setLanguagePreferences();
    }
    window.location.reload();
  };

  const setLanguagePreferences = async () => {
    // let obj = `{"lng":"${selectedLang?.code}|${localStorage.getItem(
    //   "audioLang"
    // )}|${localStorage.getItem("subtitleLang")}"}`;
    // const { data } = await setUserLanguagePreferences(obj);
    // if (data.returncode === "0") {
    //   localStorage.setItem("language", selectedLang?.code);
    //   setShowNotification(false);
    //   setCookieApiCall("language", selectedLang?.code, true);
    // }
    localStorage.setItem("language", selectedLang?.code);
    setShowNotification(false);
    setCookieApiCall("language", selectedLang?.code, true);
  };

  const clickNotConfirm = () => {
    setShowNotification(false);
    setSelectedLang({});
  };

  const onChangeLanguage = (item) => {
    setShowNotification(true);
    setSelectedLang(item);
  };

  const renderedItems = languages.map((item) => {
    return (
      <div
        key={item.code}
        className="parentel-settings-item"
        onClick={() =>
          localizationStrings.getLanguage() !== item?.code
            ? onChangeLanguage(item)
            : null
        }
        style={{
          borderColor:
            localizationStrings.getLanguage() === item?.code ? "#81E5EA" : ""
        }}
      >
        <p className="description">{item?.title}</p>

        <div
          className={`icon ${
            localizationStrings.getLanguage() === item?.code ? "show" : ""
          }`}
        >
          <CheckIcon />
        </div>
      </div>
    );
  });

  return (
    <div className="settings-container">
      <div>
        <div
          className="settings-icon"
          onClick={() => {
            history.push(`/dashboard/settings`);
          }}
        >
          <ReturnIcon />
          <label>{localizationStrings.menuLanguage}</label>
        </div>
      </div>
      <ul className="language-list">{renderedItems}</ul>
      {showNotification ? (
        <Notification
          text={localizationStrings.changeLangText}
          confirmText={localizationStrings.yes}
          notConfirmText={localizationStrings.no}
          onClickConfirm={clickConfirm}
          onClickNotConfirm={clickNotConfirm}
        />
      ) : null}
    </div>
  );
};

export default Language;
