import React from "react";

const LiveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1.1em"
      viewBox="0 0 10.14 11.451"
    >
      <path
        id="Fill_9"
        data-name="Fill 9"
        d="M9.72,6.452,1.258,11.338A.839.839,0,0,1,0,10.611V.84A.839.839,0,0,1,1.258.114L9.72,5a.839.839,0,0,1,0,1.453"
        fill="#fff"
      />
    </svg>
  );
};

export default LiveIcon;
