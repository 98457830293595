import React from "react";

const QualityIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 30.702 30.702"
    >
      <path
        id="Settings"
        d="M17.653,30.7H13.048V26.626a11.248,11.248,0,0,1-4.042-1.671L6.124,27.836,2.866,24.579,5.748,21.7A11.6,11.6,0,0,1,4.06,17.653H0V13.048H4.06A11.233,11.233,0,0,1,5.731,9.006L2.866,6.141,6.124,2.883,8.989,5.748A11.554,11.554,0,0,1,13.048,4.06V0h4.605V4.06A11.6,11.6,0,0,1,21.7,5.748l2.866-2.866,3.257,3.258L24.954,9.006a11.248,11.248,0,0,1,1.671,4.042H30.7v4.605H26.626A11.612,11.612,0,0,1,24.937,21.7l2.882,2.883-3.257,3.257-2.883-2.882a11.222,11.222,0,0,1-4.026,1.671V30.7Zm-2.3-22.259a6.908,6.908,0,1,0,6.908,6.908A6.865,6.865,0,0,0,15.351,8.443Z"
        fill="#fff"
      />
    </svg>
  );
};

export default QualityIcon;
