import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "../modal/Modal";
import { useHistory } from "react-router-dom";
import localizationStrings from "../../statics/localization";
import InvoiceIcon from "../../assets/icons/svg/InvoiceIcon";
import MobilePhoneIcon from "../../assets/icons/svg/MobilePhoneIcon";
import CreditCardIcon from "../../assets/icons/svg/CreditCardIcon";
import PromoIcon from "../../assets/icons/svg/PromoIcon";
import { SessionContext } from "../../context/SessionContext";
import {
  payWithUserPromoCode,
  doPaymentTvod,
  checkProfilePassword
} from "../../api/user/user";
import WireCard from "../wireCard";
import { getWirecardPayResult } from "../../api/user/user";
import ImageView from "../imageView";
import defaultPoster from "../../assets/images/no-portrait-poster.png";
import Notification from "../notification";
import ConfirmPayment from "../confirmPayment";
import { sendCustomSubscribeReport } from "../../utils/reporting/customReporting";
import PopupCloseIcon from "../../assets/icons/svg/PopupCloseIcon";
import { isFirefox } from "react-device-detect";
import firefoxPinSecret from "../../utils/firefoxPinSecret";
import { findPoster } from "../../utils/findPoster";
import { sendPayment } from "../../api/vod/vod";

const Payment = ({ vod, product, closePayment }) => {
  const [showConfirmPage, setShowConfirmPage] = useState(false);
  const [selectedPayOption, setSelectedPayOption] = useState(null);
  const [wirecardUrl, setWirecardUrl] = useState("");
  const [mPayGuid, setmPayGuid] = useState("");
  const [showWirecard, setShowWirecard] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [showBillPayment, setShowBillPayment] = useState(false);
  const [showPromoBox, setShowPromoBox] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const paymentContainerRef = useRef(null);
  const showWirecardRef = useRef(false);

  // useOutsideClick(paymentContainerRef, () => {
  //   if (!showWirecardRef.current) {
  //     closePayment();
  //   }
  // });

  useEffect(() => {
    setShowConfirmPage(true);
  }, []);

  const goVodDetail = (showWatchNow) => {
    closePayment(showWatchNow);
  };

  const selectPayOption = (option) => {
    setSelectedPayOption(option);
    setShowConfirmPage(true);
  };

  const getPayResult = async (mPay, id) => {
    const data = await getWirecardPayResult(mPay);
    if (data.data.returncode === "0") {
      let status = data.data.status;
      if (status === "1") {
        clearInterval(id);
        setShowWirecard(false);
        showWirecardRef.current = false;
        goVodDetail(true);
      } else if (status === "3" || status === "4" || status === "5") {
        clearInterval(id);
        setShowWirecard(false);
        showWirecardRef.current = false;
      }
      // setShowWirecard(false);
      // showWirecardRef.current = false;
    }
  };

  const doPayment = async () => {
    if (product) {
      const data = await sendPayment(vod?.id, product?.id);

      if (data.status === 200) {
        goVodDetail(true);
      }
    }
  };

  const payWithPromoCode = async () => {
    const { data } = await payWithUserPromoCode(
      vod?.programtype,
      vod?.programcode,
      product.productcode,
      promoCode,
      localStorage.getItem("deviceid")
    );
    if (data.returncode === "0") {
      goVodDetail(true);
    }
    sendCustomSubscribeReport(
      product.productcode,
      product.fee,
      vod?.programcode,
      data.returncode,
      data.errormsg
    );
  };

  const notConfirm = () => {
    //setShowConfirmPage(false);
    closePayment();
  };

  return (
    <Modal viewClass="payment-modal">
      <div ref={paymentContainerRef} className="payment-container">
        <div className="close-row">
          <div className="close" onClick={() => closePayment()}>
            <PopupCloseIcon />
          </div>
        </div>
        <div className="box">
          <div className="vod-poster">
            <ImageView
              src={findPoster(vod?.posters, "vertical")}
              defaultPoster={defaultPoster}
            />
            <div className="gradient"></div>
          </div>
          <div className="wrapper">
            {showConfirmPage ? (
              <ConfirmPayment
                vod={vod}
                product={product}
                confirm={() => doPayment()}
                notConfirm={() => notConfirm()}
              />
            ) : !showBillPayment && !showPromoBox ? (
              <div className="payment-options-row">
                <div className="payment-text">
                  {localizationStrings.payment.selectPayment}
                </div>
                <div className="payment-options">
                  <div
                    className="payment-option-box"
                    onClick={() => selectPayOption(11)}
                  >
                    <div className="payment-option">
                      <div className="icon">
                        <MobilePhoneIcon />
                      </div>
                    </div>
                    <div className="text">
                      {localizationStrings.payment.option2}
                    </div>
                  </div>
                  <div
                    className="payment-option-box"
                    onClick={() => selectPayOption(16)}
                  >
                    <div className="payment-option">
                      <div className="icon" style={{ fontSize: "50px" }}>
                        <CreditCardIcon />
                      </div>
                    </div>
                    <div className="text">
                      {localizationStrings.payment.option3}
                    </div>
                  </div>
                  <div
                    className="payment-option-box"
                    onClick={() => setShowBillPayment(true)}
                  >
                    <div className="payment-option">
                      <div className="icon">
                        <InvoiceIcon />
                      </div>
                    </div>
                    <div className="text">
                      {localizationStrings.payment.option1}
                    </div>
                  </div>
                </div>
                <div
                  className="promotion-option"
                  onClick={() => setShowPromoBox(true)}
                >
                  <div className="icon">
                    <PromoIcon />
                  </div>
                  <div className="text">
                    {localizationStrings.payment.option4}
                  </div>
                </div>
              </div>
            ) : !showPromoBox ? (
              <ReflectToBill
                vod={vod}
                product={product}
                closeBillPayment={() => setShowBillPayment(false)}
                goVodDetail={(val) => {
                  setShowBillPayment(false);
                  goVodDetail(val);
                }}
              />
            ) : (
              <div className="payment-container-2">
                <div className="payment-text">
                  {localizationStrings.payment.enterPromoCode}
                </div>
                <div className="payment-input-row">
                  <form id="paymentForm">
                    <input
                      type="text"
                      className="profile-input"
                      name="profilePin"
                      autoComplete="off"
                      autoFocus={true}
                      id="paymentPin2"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                    />
                  </form>
                  <div style={{ display: "flex", marginTop: "50px" }}>
                    <button
                      type="button"
                      className="tr-button"
                      style={{ marginRight: "30px" }}
                      onClick={() => setShowPromoBox(false)}
                    >
                      {localizationStrings.cancel}
                    </button>
                    <button
                      type="button"
                      className="yellow-button"
                      onClick={() =>
                        promoCode?.length ? payWithPromoCode() : null
                      }
                    >
                      {localizationStrings.continue}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showWirecard ? (
        <WireCard
          url={wirecardUrl}
          closeFrame={() => {
            getPayResult(mPayGuid, intervalId);
            clearInterval(intervalId);
            setShowWirecard(false);
            showWirecardRef.current = false;
            setShowConfirmPage(false);
          }}
        />
      ) : null}
    </Modal>
  );
};

const ReflectToBill = ({ vod, product, closeBillPayment, goVodDetail }) => {
  const [purchasePassword, setPurchasePassword] = useState(null);
  const [showConfirmPage, setShowConfirmPage] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [i, setI] = useState(0);
  const [pinText, setPinText] = useState("");
  const { adminProfile } = useContext(SessionContext);
  let history = useHistory();

  const doPayment = async () => {
    const data = await doPaymentTvod(
      vod.programtype,
      vod.programcode,
      product.productcode,
      0,
      localStorage.getItem("deviceid")
    );
    if (data.data.returncode === "0") {
      //close payment modal
      goVodDetail(true);
    }

    sendCustomSubscribeReport(
      product.productcode,
      product.fee,
      vod.programcode,
      data.data.returncode,
      data.data.errormsg
    );
  };

  const checkPinCode = async () => {
    let encodedPassword = window.EncryptUtils.encryptStr(purchasePassword);

    const data = await checkProfilePassword(
      adminProfile.profilecode,
      encodedPassword
    );

    if (data.data.returncode == "0") {
      setShowConfirmPage(true);
    }
  };

  const clickConfirm = () => {
    setShowNotification(false);
    history.push(`/profile/${adminProfile?.profilecode}?page=1`);
  };

  const clickNotConfirm = () => {
    setShowNotification(false);
    closeBillPayment();
  };

  useEffect(() => {
    if (adminProfile) {
      if (adminProfile.havepwd === "1") {
        setShowNotification(false);
      } else {
        setShowNotification(true);
      }
    }
  }, [adminProfile]);

  return (
    <div className="flex-container">
      {!showNotification ? (
        !showConfirmPage ? (
          <div className="payment-container-2">
            <div className="payment-text">
              {localizationStrings.payment.askAdminPassword}
            </div>
            <div className="payment-input-row">
              <form
                id="paymentForm"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    if (purchasePassword?.length) {
                      checkPinCode();
                    }
                  }
                }}
              >
                {!isFirefox ? (
                  <input
                    type="number"
                    className="profile-input pin"
                    name="profilePin"
                    autoComplete="off"
                    autoFocus={true}
                    id="paymentPin"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-", ".", ","].includes(e.key) &&
                      e.preventDefault()
                    }
                    onChange={(e) => {
                      if (e.target.value.length > 4) {
                        e.target.value = e.target.value.slice(0, 4);
                      }
                      setPurchasePassword(e.target.value);
                    }}
                  />
                ) : (
                  <input
                    type="text"
                    className="profile-input"
                    name="profilePin"
                    autoComplete="off"
                    autoFocus={true}
                    id="paymentPin"
                    onMouseUp={(e) => {
                      let [pin, i] = firefoxPinSecret(null, e);
                      setI(i);
                      setPinText(pin.replace(/[0-9,•]/g, "•"));
                      setPurchasePassword(pin);
                    }}
                    onKeyDown={(e) => {
                      let [pin, i] = firefoxPinSecret(e, null);
                      setI(i);
                      setPinText(pin.replace(/[0-9,•]/g, "•"));
                      setPurchasePassword(pin);
                    }}
                    onChange={(e) => {
                      e.target.value = pinText;
                      e.target.selectionEnd = i;
                      e.target.selectionStart = i;
                    }}
                  />
                )}
              </form>
              <div style={{ display: "flex", marginTop: "50px" }}>
                <button
                  type="button"
                  className="tr-button"
                  style={{ marginRight: "30px" }}
                  onClick={() => closeBillPayment()}
                >
                  {localizationStrings.cancel}
                </button>
                <button
                  type="button"
                  className="yellow-button"
                  onClick={() =>
                    purchasePassword?.length ? checkPinCode() : null
                  }
                >
                  {localizationStrings.continue}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <ConfirmPayment
            vod={vod}
            product={product}
            confirm={doPayment}
            notConfirm={() => {
              setShowConfirmPage(false);
            }}
          />
        )
      ) : null}
      {showNotification ? (
        <Notification
          text={localizationStrings.createAdminProfilePinCodeText}
          confirmText={localizationStrings.createProfilePinCode}
          notConfirmText={localizationStrings.cancel}
          onClickConfirm={clickConfirm}
          onClickNotConfirm={clickNotConfirm}
        />
      ) : null}
    </div>
  );
};

export default Payment;
