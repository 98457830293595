import React from "react";

const WireCard = ({ url, closeFrame }) => {
  return (
    <div className="wirecard-container">
      <div className="close-button black" onClick={() => closeFrame()}></div>
      <iframe className="wirecard-frame" src={url}></iframe>
    </div>
  );
};

export default WireCard;
