import React from "react";

const PlayerInfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 30.702 30.702"
    >
      <g
        id="Group_503"
        data-name="Group 503"
        transform="translate(6189.903 -8204.097)"
      >
        <g
          id="Group_329"
          data-name="Group 329"
          transform="translate(2911.622 2235.149)"
        >
          <path
            id="Path_16"
            data-name="Path 16"
            d="M18.551,10.04a.99.99,0,1,1-.99-.99A.99.99,0,0,1,18.551,10.04Z"
            transform="translate(-9103.373 5968.801)"
            fill="#fff"
          />
          <path
            id="Path_16_-_Outline"
            data-name="Path 16 - Outline"
            d="M17.56,8.8a1.24,1.24,0,1,1-1.24,1.24A1.242,1.242,0,0,1,17.56,8.8Zm0,1.981a.74.74,0,1,0-.74-.74A.741.741,0,0,0,17.56,10.781Z"
            transform="translate(-9103.373 5968.801)"
            fill="#fff"
          />
          <path
            id="Path_17"
            data-name="Path 17"
            d="M18.952,21.811H17.537V14.1H15.415a.707.707,0,0,0,0,1.415h.707v6.3H14.707a.707.707,0,1,0,0,1.415h4.244a.707.707,0,0,0,0-1.415Z"
            transform="translate(-9102.622 5967.322)"
            fill="#fff"
          />
          <path
            id="Path_17_-_Outline"
            data-name="Path 17 - Outline"
            d="M18.952,23.475H14.707a.957.957,0,1,1,0-1.915h1.165v-5.8h-.457a.957.957,0,1,1,0-1.915h2.372v7.711h1.165a.957.957,0,1,1,0,1.915Zm-4.244-1.415a.457.457,0,1,0,0,.915h4.244a.457.457,0,1,0,0-.915H17.287V14.35H15.415a.457.457,0,1,0,0,.915h.957v6.8Z"
            transform="translate(-9102.622 5967.322)"
            fill="#fff"
          />
        </g>
        <g id="Group_2" data-name="Group 2" transform="translate(-6137 8205)">
          <circle
            id="Oval"
            cx="14.448"
            cy="14.448"
            r="14.448"
            transform="translate(-52 0)"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="1.806"
          />
        </g>
      </g>
    </svg>
  );
};

export default PlayerInfoIcon;
