import React from "react";

const TwitterFooterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 53.3 53.301"
    >
      <g id="twitter" transform="translate(0.65 0.65)" opacity="0.5">
        <circle
          id="base"
          cx="26"
          cy="26"
          r="26"
          transform="translate(0 0)"
          fill="none"
          stroke="#6e8496"
          strokeWidth="1.3"
        />
        <path
          id="Twitter-2"
          data-name="Twitter"
          d="M19.5,2.878A4.6,4.6,0,0,0,21.49.336,9.02,9.02,0,0,1,18.61,1.451,4.5,4.5,0,0,0,15.3,0a4.565,4.565,0,0,0-4.534,4.594,4.667,4.667,0,0,0,.117,1.047A12.814,12.814,0,0,1,1.539.841a4.639,4.639,0,0,0,1.4,6.134A4.471,4.471,0,0,1,.888,6.4c0,.019,0,.038,0,.057a4.583,4.583,0,0,0,3.637,4.505,4.48,4.48,0,0,1-2.048.079,4.547,4.547,0,0,0,4.236,3.191A9.021,9.021,0,0,1,1.081,16.2,8.967,8.967,0,0,1,0,16.135,12.706,12.706,0,0,0,6.95,18.2c8.34,0,12.9-7,12.9-13.073q0-.3-.013-.594A9.271,9.271,0,0,0,22.1,2.154,8.976,8.976,0,0,1,19.5,2.878Z"
          transform="translate(15.6 18.2)"
          fill="#6e8496"
        />
      </g>
    </svg>
  );
};

export default TwitterFooterIcon;
