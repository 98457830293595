import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { isSafari } from "react-device-detect";
import { useHistory, useLocation } from "react-router-dom";
import PictureInPictureIcon from "../../../assets/icons/svg/PictureInPictureIcon";

import {
  setPltvPaused,
  setPipPlayUrl,
  setPipVolumeLevel,
  setPipCurrentTime,
  setPipPoster,
  setPipVod,
  setPipLiveui,
  setPipIsRecord,
  setPipIsPreview,
  setPipSelectedSeason,
  setPipSelectedEpisode,
  setWhereFrom,
  setIsPltv
  //setPipSubtitles
} from "../../../statics/config";
import { reportingEvent } from "../../../statics/reportEventId";

const PictureInPictureButton = ({
  player,
  poster,
  vod,
  selectedEpisode,
  liveui,
  isRecord,
  isPreview,
  selectedSeason,
  setPictureInPicture,
  location,
  setIsPaused,
  goBack,
  isFullscreen,
  isPltv,
  cuTvProgram,
  myTvChannelProgram,
  sendMediaReport,
  setIsLiveEdge
  //textTracks
}) => {
  const isPltvRef = useRef(null);
  const cuTvRef = useRef(null);
  const myTvRef = useRef(null);

  // console.log("ispltv", isPltv);
  // console.log("cuTvProgram", cuTvProgram);
  // console.log("myTvChannelProgram", myTvChannelProgram);

  useEffect(() => {
    isPltvRef.current = isPltv;
  }, [isPltv]);

  useEffect(() => {
    cuTvRef.current = cuTvProgram;
  }, [cuTvProgram]);

  useEffect(() => {
    myTvRef.current = myTvChannelProgram;
  }, [myTvChannelProgram]);
  return (
    <button
      className="control-button"
      style={{ marginLeft: isFullscreen ? "1.2vw" : null }}
      onClick={(e) => {
        if (isSafari) {
          player.children_[0].webkitSetPresentationMode(
            player.children_[0].webkitPresentationMode === "picture-in-picture"
              ? "inline"
              : "picture-in-picture"
          );
        } else {
          if (!document.pictureInPictureElement) {
            setPltvPaused(player.paused());
            setPipPlayUrl(player);
            setPipVolumeLevel(player.volume());
            setPipCurrentTime(player.currentTime());
            setPipPoster(poster);
            setPipVod(vod);
            setPipSelectedEpisode(selectedEpisode);
            setPipLiveui(liveui);
            setPipIsRecord(isRecord);
            setPipIsPreview(isPreview);
            setPipSelectedSeason(selectedSeason);
            setIsPltv(isPltv);
            setWhereFrom(location.pathname + location.search);
            //setPipSubtitles(textTracks);   //Technology does not support subtitle at pip 03.01.2023
            // main player paused
            sendMediaReport(reportingEvent.E_MEDIA_PAUSE);
            setIsPaused(true);
            player.pause();
            if (
              liveui &&
              !isPltvRef.current &&
              !cuTvRef.current?.prevuecode &&
              !myTvRef.current?.programcode
            ) {
              setIsLiveEdge("1");
              setPltvPaused(true);
            }
            goBack(player.currentTime());
            setPictureInPicture(true);
          } else {
            setPictureInPicture(false);
            if (document.pictureInPictureElement) {
              document.exitPictureInPicture();
            }
            player.play();
            setIsPaused(false);
          }
        }
      }}
    >
      <PictureInPictureIcon />
    </button>
  );
};

export default PictureInPictureButton;
