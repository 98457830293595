import React, { useState } from "react";
import FacebookFooterIcon from "../../assets/icons/svg/FacebookFooterIcon";
import TwitterFooterIcon from "../../assets/icons/svg/TwitterFooterIcon";
import YoutubeFooterIcon from "../../assets/icons/svg/YoutubeFooterIcon";
import localizationStrings from "../../statics/localization";
import moment from "moment";
import Notification from "../notification";

const Footer = () => {
  const [showNotification, setShowNotification] = useState(false);
  let year = moment().format("YYYY");

  return (
    <div className="footer-container">
      <div className="footer-column">
        <div className="footer-top-line">
          <div className="footer-top">
            <a
              // href="https://www.tivibu.com.tr/destek"
              className="label"
              target="_blank"
            >
              {localizationStrings.support}
            </a>
            <a
              // href="https://www.du.ae/support/du-contactus"
              className="label"
              target="_blank"
            >
              {localizationStrings.communication}
            </a>
            {/* <a
              href="https://teksifre.turktelekom.com.tr/kullanici-islemleri/"
              className="label"
              target="_blank"
            >
              {localizationStrings.onePassword}
            </a> */}
            <a
              // href="https://www.tivibu.com.tr/ticari/Anasayfa"
              className="label"
              target="_blank"
            >
              {localizationStrings.commetcial}
            </a>
          </div>
          <div className="footer-icon">
            <a
              // href="https://www.facebook.com/du"
              className="span"
              target="_blank"
            >
              <FacebookFooterIcon />
            </a>
            <a
              // href="https://x.com/dutweets"
              className="span"
              target="_blank"
            >
              <TwitterFooterIcon />
            </a>
            <a
              // href="https://www.youtube.com/user/theduchannel"
              className="span"
              target="_blank"
            >
              <YoutubeFooterIcon />
            </a>
          </div>
        </div>
      </div>

      <div className="footers-column">
        <div className="footer-bottom-line">
          <div className="footer-bottom">
            <a
              // href="https://www.tivibu.com.tr/bilgilendirme/aydinlatma-metni"
              className="label"
              target="_blank"
            >
              {localizationStrings.illuminationText}
            </a>
            <a
              onClick={() => {
                setShowNotification(true);
              }}
              className="label"
            >
              {localizationStrings.inform}
            </a>
          </div>
          <div className="footers">
            <div className="label">©&nbsp;{year}&nbsp;Atlas</div>
          </div>
        </div>
      </div>
      {/* {showNotification ? (
          <Notification
            text={localizationStrings.informText}
            confirmText={localizationStrings.login.confirm}
            onClickConfirm={() => {
              setShowNotification(false);
            }}
            onClickNotConfirm={() => {
              setShowNotification(false);
            }}
            showCloseButton={true}
          />
        ) : null} */}
    </div>
  );
};

export default Footer;
