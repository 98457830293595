import React, { useContext, useEffect, useState } from "react";
import { SessionContext } from "../../context/SessionContext";
import { useHistory } from "react-router-dom";
import useQuery from "../../utils/useQuery";
import { useCookies } from "react-cookie";

const LoginRedirect = () => {
  const history = useHistory();

  let query = useQuery();
  let sessionId = query.get("id");
  let interfaceUrl = query.get("url");
  let loginUrl = query.get("lurl");
  let userToken = query.get("token");
  let loginType = query.get("logintype");
  let userUrl = interfaceUrl;

  userUrl = userUrl.substring(0, userUrl.lastIndexOf("/"));
  localStorage.setItem("sessionid", sessionId);
  localStorage.setItem("interfaceurl", interfaceUrl);
  localStorage.setItem("url", userUrl);
  localStorage.setItem("usertoken", userToken);
  localStorage.setItem("loginurl", loginUrl ? loginUrl : "");
  localStorage.setItem("logintype", loginType);

  const initEncriptionScript = () => {
    const script = document.createElement("script");
    script.src = `${interfaceUrl}/jssdk/custom.js`;
    script.setAttribute("type", "text/javascript");
    document.getElementsByTagName("head")[0].appendChild(script);
    script.onload = () => {
      history.push("/landingpage");
    };
  };

  useEffect(() => {
    initEncriptionScript();
  }, []);

  return null;
};

export default LoginRedirect;
