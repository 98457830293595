import React from "react";

const TvSearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="19.2"
      height="19.199"
      viewBox="0 0 19.2 19.199"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="Clip_2"
            data-name="Clip 2"
            d="M0,0H19.2V19.2H0Z"
            transform="translate(0 0)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_3" data-name="Group 3" transform="translate(0 0)">
        <path
          id="Clip_2-2"
          data-name="Clip 2"
          d="M0,0H19.2V19.2H0Z"
          transform="translate(0 0)"
          fill="none"
        />
        <g id="Group_3-2" data-name="Group 3" clipPath="url(#clip-path)">
          <path
            id="Fill_1"
            data-name="Fill 1"
            d="M17.422,19.2a1.769,1.769,0,0,1-1.259-.521l-4.145-4.142a7.9,7.9,0,1,1,2.516-2.517l4.144,4.143A1.779,1.779,0,0,1,17.422,19.2ZM7.866,2.225a5.642,5.642,0,1,0,5.642,5.639A5.646,5.646,0,0,0,7.866,2.225Z"
            transform="translate(0 0)"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};

export default TvSearchIcon;
