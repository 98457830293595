import React from "react";

const UnselectIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 26 26"
      >
        <rect
          id="base"
          width="25"
          height="25"
          rx="12.5"
          transform="translate(0.5 0.5)"
          fill="none"
          stroke="#778da3"
          strokeWidth="1"
        />
      </svg>
    </div>
  );
};

export default UnselectIcon;
